import React from 'react'
import { Tooltip } from '../../FormElements'
import { useController } from 'react-hook-form'

import { RequiredMark, Label, Select, ErrorMessage, Wrapper } from './style'

type StyledInputProps = {
  control: any
  isRequired?: boolean
  label: string
  name: string
  error?: any
  help?: string
  rules?: object
  options: any
}

const StyledSelect = ({
  control,
  name,
  label,
  error,
  isRequired = false,
  help,
  rules,
  options,
  ...rest
}: StyledInputProps) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: '',
  })

  return (
    <Wrapper>
      <Label>
        {help && <Tooltip message={help} />}
        {label && <span>{label}</span>}
        {isRequired && <RequiredMark>*</RequiredMark>}
      </Label>

      <Select
        hasError={error ? true : false}
        required={isRequired}
        {...inputProps}
        inputRef={ref}
        {...rest}
      >
        <option value={''}>Seleccione {label} ...</option>
        {options.map((option: any) => (
          <option key={`option-${option.label}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Wrapper>
  )
}

export default StyledSelect
