import { countDecimals } from './utils'
import { isUndefined } from 'util'

interface validationResponse {
  value: boolean
  text: string
}

export interface Field {
  value: any
  error: boolean
  errorText: string
}

export const initialField: Field = {
  value: '',
  error: false,
  errorText: '',
}
export interface InputElements {
  element: HTMLElement
}

interface validation {
  name:
    | 'decimalCount'
    | 'greaterThan'
    | 'greaterThanEqual'
    | 'lessThan'
    | 'shorterThan'
  value?: any
}

const noError: validationResponse = {
  value: false,
  text: '',
}

let response = {
  value: false,
  text: '',
}

//all validation names
let allValidations = [
  'decimalCount',
  'greaterThan',
  'greaterThanEqual',
  'lessThan',
  'shorterThan',
]

// seek if one validation fails
export function validateField(input: string, validations: Array<validation>) {
  let negativeValidations: Array<validation> = []

  validations.map(validation => {
    allValidations.map(valid => {
      if (validation.name == valid) {
        if (eval(valid)(validation.value, input).value) {
          negativeValidations.push(validation)
        }
      }
    })
  })

  // if one validation fails it returns the error text
  if (!isUndefined(negativeValidations[0])) {
    return eval(negativeValidations[0].name)(
      negativeValidations[0].value,
      input
    )
  }
  return noError
}

// validation functions
export function decimalCount(decimals: number, input: string) {
  if (countDecimals(input) > decimals) {
    let text = ''
    decimals == 0
      ? (text = 'Ingresa un número entero')
      : (text = 'Ingresa hasta ' + decimals + ' decimales')
    response = {
      value: true,
      text: text,
    }
  } else {
    response = { value: false, text: '' }
  }
  return response
}

export function greaterThanEqual(minimum: number, input: string) {
  Number(input) < minimum
    ? (response = {
        value: true,
        text: 'Ingresa un valor mayor o igual a ' + minimum,
      })
    : (response = { value: false, text: '' })
  return response
}

export function greaterThan(minimum: number, input: string) {
  Number(input) <= minimum
    ? (response = { value: true, text: 'Ingresa un valor mayor a ' + minimum })
    : (response = { value: false, text: '' })
  return response
}

export function lessThan(maximum: number, input: string) {
  Number(input) > maximum
    ? (response = { value: true, text: 'Ingresa un valor menor a ' + maximum })
    : (response = { value: false, text: '' })
  return response
}

export function shorterThan(maximum: number, input: string) {
  input.length > maximum
    ? (response = {
        value: true,
        text: 'Ingresa un texto de hasta ' + maximum + ' caracteres',
      })
    : (response = { value: false, text: '' })
  return response
}

export function realNumber(input: string) {
  let expression = new RegExp(/^-?\d*\.?\d*$/)
  let response = expression.test(input)
  return response
}

export function scrollTo(element: HTMLElement) {
  element.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'nearest',
  })
}

export function getElementsSelector(inputIds: string[]) {}

export function getElements(inputIds: string[]) {
  const items = inputIds
    .map(id => {
      const element = document.getElementById(id)
      if (element) {
        return { element } as InputElements
      } else {
        return
      }
    })
    .filter(item => item)
  let update: InputElements[] = []
  items.forEach(item => {
    if (item) {
      update[item.element.id] = item
    }
  })
  return update
}
interface Errors {
  error: boolean
  errorsMap: Field[]
}
export const validateForm = (inputs: InputElements[], inputIds: string[]) => {
  let error = true
  let errorsMap: Field[] = [
    {
      value: '',
      error: false,
      errorText: '',
    },
  ]
  inputIds.map(id => {
    if (
      inputs[id] &&
      inputs[id].element &&
      inputs[id].element.required &&
      inputs[id].element.value.length === 0
    ) {
      error = false
      errorsMap[id] = {
        value: '',
        error: true,
        errorText: 'Campo requerido',
      }
      scrollTo(inputs[id].element)
      return
    }
  })
  let getErrors: Errors = { error, errorsMap }
  return getErrors
}

export function validateIdentificationNumber(
  identificationType: string,
  identification: string
) {
  if (identificationType === 'CI' && identification.length > 0) {
    if (identification.length === 10) {
      response = {
        value: false,
        text: '',
      }

      if (!validateCI(identification)) {
        response = {
          value: true,
          text: 'Cédula incorrecta',
        }
      }
    } else {
      response = {
        value: true,
        text: 'La cédula debe tener 10 caracteres',
      }
    }
  } else if (identificationType === 'RUC' && identification.length > 0) {
    if (identification.length === 13) {
      response = {
        value: false,
        text: '',
      }

      if (!validateRUC(identification)) {
        response = {
          value: true,
          text: 'RUC incorrecta',
        }
      }
    } else {
      response = {
        value: true,
        text: 'El RUC debe tener 13 caracteres',
      }
    }
  } else if (identificationType === 'PPN' && identification.length < 5) {
    response = {
      value: true,
      text: 'Pasaporte debe tener al menos más de 5 caracteres',
    }
  } else {
    response = {
      value: false,
      text: '',
    }
  }

  const hasSpecialCharacters = notSpecialCharacters(identification)
  if (hasSpecialCharacters) {
    response = {
      value: true,
      text: 'Caracteres especiales no permitidos',
    }
  }
  return response
}
export function notSpecialCharacters(input: string) {
  const expression = new RegExp(/[^a-zA-Z0-9_]/)
  const response = expression.test(input)
  return response
}

function validateCI(CI: string) {
  let numImpar = 0
  let numPar = 0
  let lastnumber = ''
  let cad = ''
  let digitType = CI.substring(2, 3)

  lastnumber = CI.substring(CI.length - 1)
  cad = CI.substring(0, CI.length - 1)
  cad
    .toString()
    .split('')
    .map((i: any, index) =>
      index % 2
        ? (numPar += parseInt(i))
        : i * 2 < 9
        ? (numImpar += parseInt(i) * 2)
        : (numImpar += parseInt(i) * 2 - 9)
    )
  let sum = numPar + numImpar
  let residue = sum % 10

  if (
    parseInt(digitType) >= 6 ||
    (residue !== parseInt(lastnumber) && 10 - residue !== parseInt(lastnumber))
  ) {
    return false
  }
  return true
}

function validateRUC(RUC: string) {
  let substring = RUC.substring(0, RUC.length - 3)
  let digitType = RUC.substring(2, 3)
  let sum = 0
  let residue = 0

  if (!validateCI(substring)) {
    let subLastNumber = substring.substring(substring.length - 1)
    let subCad = substring.substring(0, substring.length - 1)
    let array = [4, 3, 2, 7, 6, 5, 4, 3, 2]

    if (parseInt(digitType) === 6) {
      subCad = substring.substring(0, substring.length - 2)
      subLastNumber = substring.substring(8, substring.length - 1)
      array = [3, 2, 7, 6, 5, 4, 3, 2, 1]
    }

    subCad
      .toString()
      .split('')
      .map((i: any, index) => (sum += parseInt(i) * array[index]))
    residue = sum % 11
    residue = Math.floor(residue)

    if (
      residue !== parseInt(subLastNumber) &&
      11 - residue !== parseInt(subLastNumber)
    ) {
      return false
    }
  }
  return true
}
