import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'

import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import {
  Input,
  Error,
  TextArea,
  CheckBox,
} from '../../../../components/FormElements'
import { Spacer, ActionWrapper } from '../../../../components/Globals'

import Modal from '../../../../components/Modals/ModalRoot'
import CategorySelector, {
  CategoryOption,
} from '../../../../components/Selectors/CategorySelector'
import { CancelButton } from '../../../../components/Buttons'

import { SaveButton } from '../style'

import ThemeContext from '../../../../context/ThemeContext'

const EDIT_CATEGORY_MUTATION = gql`
  mutation editCategoryMutation($input: EditCategoryInput!) {
    editCategory(input: $input)
  }
`

interface State {
  name: string
  nameError: boolean
  description: string
  descriptionError: boolean
  category: CategoryOption | null
  categoryError: boolean
  hasParent: boolean
  priority: string
  priorityError: boolean
  status: string
  statusError: boolean
  editError: boolean
}

interface Props {
  onUpdated: () => void
  category: any
}

class EditCategoryForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const {
      category: { name, description, parent, status, priority },
    } = this.props

    this.state = {
      name,
      nameError: false,
      description,
      descriptionError: false,
      category: parent,
      categoryError: false,
      hasParent: parent ? true : false,
      status,
      priority,
      priorityError: false,
      statusError: false,
      editError: false,
    }
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    if (name.length >= 40) {
      this.setState({
        name,
        nameError: true,
      })

      return
    }

    this.setState({
      name,
      nameError: false,
    })
  }

  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = value

    if (description.length >= 400) {
      this.setState({
        description,
        descriptionError: true,
      })

      return
    }

    this.setState({
      description,
      descriptionError: false,
    })
  }

  handleCategoryChange = (category: CategoryOption) => {
    this.setState({ category, categoryError: false })
  }

  handleHasParentChange = () => {
    const { hasParent } = this.state
    this.setState({ hasParent: !hasParent })
  }

  handlePriorityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const priority = value

    if (Number(priority) < 0) {
      this.setState({
        priority,
        priorityError: true,
      })

      return
    }

    this.setState({
      priority,
      priorityError: false,
    })
  }

  render() {
    const {
      name,
      nameError,
      description,
      descriptionError,
      category,
      categoryError,
      hasParent,
      priorityError,
      priority,
    } = this.state
    const id = this.props.category.id
    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <Paper>
                <Grid.Row>
                  <Grid.Column md={4}>
                    <Input
                      id="name"
                      value={name}
                      type="text"
                      label="Nombre de la categoría"
                      onChange={this.handleNameChange}
                      required
                      error={nameError}
                    />
                    {nameError ? (
                      <Error>
                        La longitud máxima debe ser de 40 caracteres.
                      </Error>
                    ) : (
                      <Spacer />
                    )}
                    {
                      <CheckBox
                        id="hasParent"
                        checked={hasParent}
                        label="¿Tiene padre?"
                        onChange={this.handleHasParentChange}
                      />
                    }
                  </Grid.Column>
                  {hasParent && (
                    <Grid.Column md={4}>
                      <CategorySelector
                        value={category}
                        onChange={this.handleCategoryChange}
                        disabled={!hasParent}
                        isMulti={false}
                        isRequired={true}
                        error={categoryError}
                      />
                      {categoryError ? (
                        <Error>Seleccione una categoría</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  )}
                  <Grid.Column md={4}>
                    <TextArea
                      id="description"
                      value={description}
                      type="text"
                      rows={7}
                      label="Descripción larga"
                      onChange={this.handleDescriptionChange}
                      error={descriptionError}
                    />
                    {descriptionError ? (
                      <Error>
                        La longitud máxima debe ser de 400 caracteres.
                      </Error>
                    ) : (
                      <Spacer />
                    )}
                  </Grid.Column>
                  <Grid.Column md={3}>
                    <Input
                      id="priority"
                      value={priority}
                      type="number"
                      label="Prioridad"
                      onChange={this.handlePriorityChange}
                      error={priorityError}
                    />
                    {priorityError ? (
                      <Error>El numero ingresado no es válido.</Error>
                    ) : (
                      <Spacer />
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Paper>
              <ActionWrapper>
                <CancelButton color="secondary" to="categories" alone={true}>
                  Cancelar
                </CancelButton>
                <Spacer />
                {
                  <ThemeContext.Consumer>
                    {notify => (
                      <Mutation
                        mutation={EDIT_CATEGORY_MUTATION}
                        onCompleted={() => {
                          this.props.onUpdated()
                          notify &&
                            notify.onSetNotification &&
                            notify.onSetNotification({
                              type: 'ok',
                              message: 'Categoría guardada correctamente',
                            })
                        }}
                        onError={() => {
                          openModal('ALERT', {
                            header: {
                              title: 'ALERTA',
                            },
                            description:
                              'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                            type: 'fail',
                          })
                        }}
                      >
                        {(editCategory, { loading, error }) => (
                          <SaveButton
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              let error = false
                              if (!name || nameError) {
                                error = true
                                this.setState({
                                  nameError: true,
                                })
                              }
                              if ((!category && hasParent) || categoryError) {
                                error = true
                                this.setState({
                                  categoryError: true,
                                })
                              }

                              if (error || descriptionError) {
                                return
                              }

                              editCategory({
                                variables: {
                                  input: {
                                    name,
                                    description,
                                    parentId:
                                      hasParent && category
                                        ? category['id']
                                        : '',
                                    id,
                                    priority: Number(priority),
                                  },
                                },
                              })
                            }}
                          >
                            {loading
                              ? 'Guardando cambios...'
                              : 'Guardar cambios'}
                          </SaveButton>
                        )}
                      </Mutation>
                    )}
                  </ThemeContext.Consumer>
                }
              </ActionWrapper>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default EditCategoryForm
