import React from 'react'
import gql from 'graphql-tag'

import PageWrapper from '../../components/PageWrapper'
import Paper from '../../components/Paper'
import { Button } from '../../components/Buttons'
import AssetSelector from '../../components/AssetSelector'
import { Label } from '../../components/Buttons/style'
import * as Grid from '../../components/Grid'
import { Mutation } from 'react-apollo'
import Modal from '../../components/Modals/ModalRoot'

import { Instruction, Title, Feedback, FeedbackError } from './style'

interface State {
  asset: Asset
  uploading: boolean
}

interface Asset {
  type: string
  url: string
  file?: File
}

const UPLOAD_IMPORT_FILE = gql`
  mutation uploadImportMembershipFile($file: Upload!) {
    uploadImportMembershipFile(file: $file) {
      url
      fileName
    }
  }
`
class MemershipImport extends React.Component<{}, State> {
  state: State = {
    asset: { url: '', type: '' },
    uploading: false,
  }

  handleAssetsChange = (asset: any) => {
    this.setState({ asset: asset[1] })
  }
  render() {
    const { asset } = this.state
    const assetArray = [asset]
    const formatURL =
      'https://storage.cloud.google.com/gaia-files-bucket/CargaMasivaSocios%20-%20Formato%20.csv'
    return (
      <PageWrapper title="Carga masiva de nuevos socios">
        <Paper>
          <Instruction>
            Para empezar descargue el excel para llenar la información de los
            socios
          </Instruction>
          <a type="_self" href={formatURL ? formatURL : ''}>
            <Button color="secondary">Descargar formato</Button>
          </a>
          <Title>Carga masiva de nuevos socios</Title>
          <Instruction>
            Cargue el archivo que se descargó anteriormente
          </Instruction>
          <Grid.Row>
            <Grid.Column md={4}>
              <AssetSelector
                colorButton="primary"
                textButton="Cargar archivo"
                disabled={this.state.uploading}
                accept=".csv"
                assets={assetArray}
                onChange={this.handleAssetsChange}
              />
            </Grid.Column>
            <Grid.Column md={3}>
              {this.state.asset.file && (
                <Modal>
                  {({ openModal }) => {
                    return (
                      <Mutation
                        mutation={UPLOAD_IMPORT_FILE}
                        onCompleted={() => {
                          this.setState({ uploading: false })
                        }}
                        onError={() => {
                          openModal('ALERT', {
                            header: {
                              title: 'ALERTA',
                            },
                            description:
                              'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                            type: 'fail',
                          })
                        }}
                      >
                        {(
                          uploadImportMembershipFile,
                          { loading, error, called }
                        ) => (
                          <React.Fragment>
                            <Button
                              color="secondary"
                              disabled={loading}
                              onClick={async () => {
                                if (asset.file) {
                                  this.setState({ uploading: true })
                                  try {
                                    await uploadImportMembershipFile({
                                      variables: { file: asset.file },
                                    })
                                  } catch (error) {
                                    throw new Error(error)
                                  }
                                }
                              }}
                            >
                              {loading
                                ? 'Subiendo archivo...'
                                : 'Subir Archivo'}
                            </Button>
                            {called &&
                              !loading &&
                              (!error ? (
                                <Feedback>
                                  Archivo importado correctamente. Llegará un
                                  correo con detalles más específicos
                                </Feedback>
                              ) : (
                                <FeedbackError>
                                  La acción solicitada no se pudo completar.
                                  Intenténtelo mas tarde por favor.
                                </FeedbackError>
                              ))}
                          </React.Fragment>
                        )}
                      </Mutation>
                    )
                  }}
                </Modal>
              )}
            </Grid.Column>
            <Grid.Column md={4}>
              {this.state.asset.file && (
                <Label>{this.state.asset.file.name}</Label>
              )}
            </Grid.Column>
          </Grid.Row>
        </Paper>
      </PageWrapper>
    )
  }
}

export default MemershipImport
