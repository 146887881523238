import gql from 'graphql-tag'
import React from 'react'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import AssetSelector from '../../../../components/AssetSelector'
import { CancelButton } from '../../../../components/Buttons'
import Editor from '../../../../components/Editor'
import {
  CheckBox,
  Error,
  Input,
  Select,
} from '../../../../components/FormElements'
import FormSection from '../../../../components/FormSection'
import { ActionWrapper, Spacer } from '../../../../components/Globals'
import * as Grid from '../../../../components/Grid'
import IconSelector from '../../../../components/IconSelector'
import Modal from '../../../../components/Modals/ModalRoot'
import Paper from '../../../../components/Paper'
import SupplierSelector, {
  SupplierOption,
} from '../../../../components/Selectors/SupplierSelector'
import {
  CATEGORY_ICONS_OPTIONS,
  STATUS_OPTIONS_SUPPLIERS,
} from '../../../../config'
import ThemeContext from '../../../../context/ThemeContext'
import {
  Field,
  getElements,
  initialField,
  InputElements,
  scrollTo,
  validateField,
  validateForm,
} from '../../../../helpers/validations'
import {
  CheckContainer,
  SaveButton,
  SpecialtyTypeContainer,
  SpecialtyTypeLabel,
} from '../style'

const UPLOAD_SPECIALTY_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

const CREATE_SPECIALTY_MUTATION = gql`
  mutation createSpecialtyMutation($input: CreateSpecialtyInput!) {
    createSpecialty(input: $input) {
      id
    }
  }
`

interface Props {
  onCreated: () => void
}

interface AssetData {
  url: string
  type: string
  platform_type: string
  file: any
}
interface State {
  name: Field
  city: Field
  order: Field
  description: Field
  longDescription: Field
  slug: Field
  url: Field
  status: ValueType<string | number | { [key: string]: any }>
  assetsDesktop: AssetData[]
  assetsMobile: AssetData[]
  createError: boolean
  inputs: InputElements[]
  assetDesktopError: boolean
  assetMobileError: boolean
  specialtyType: string
  isInternal: boolean
  isExternal: boolean
  iconName: string
  iconError: boolean
  typeError: boolean
  supplier: Field
  hasCta: boolean
  ctaMainText: Field
  ctaSecondText: Field
  ctaUrl: Field
}

type AssetType = {
  url: string
  type: string
  order?: number
  platformType: string
}

const inputIdsInternal = [
  'slug',
  'city',
  'order',
  'name',
  'description',
  'longDescription',
]
const inputIdsExternal = ['url', 'city', 'order', 'name', 'description']
const selectsRequiredInternal = ['status', 'supplier', 'longDescription']
const selectsRequiredExternal = ['status']

class Specialty extends React.Component<Props, State> {
  state: State = {
    name: initialField,
    city: initialField,
    order: initialField,
    description: initialField,
    longDescription: initialField,
    slug: initialField,
    url: initialField,
    status: { value: 'active', label: 'Activo' },
    assetsDesktop: [],
    assetsMobile: [],
    createError: false,
    inputs: [],
    assetDesktopError: false,
    assetMobileError: false,
    specialtyType: '',
    isExternal: false,
    isInternal: false,
    iconName: '',
    iconError: false,
    typeError: false,
    supplier: initialField,
    hasCta: false,
    ctaMainText: initialField,
    ctaSecondText: initialField,
    ctaUrl: initialField,
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }
  handleHasCTAChange = () => {
    const { hasCta } = this.state
    this.setState({ hasCta: !hasCta })
  }

  handleCTAMainTxtChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ctaMainText = value
    const validations = validateField(ctaMainText, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])
    if (validations.value) {
      this.setState({
        ctaMainText: {
          value: ctaMainText,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ctaMainText: {
        value: ctaMainText,
        error: false,
        errorText: '',
      },
    })
  }

  handleCTASecondTextChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ctaSecondText = value
    const validations = validateField(ctaSecondText, [
      {
        name: 'shorterThan',
        value: 80,
      },
    ])
    if (validations.value) {
      this.setState({
        ctaSecondText: {
          value: ctaSecondText,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ctaSecondText: {
        value: ctaSecondText,
        error: false,
        errorText: '',
      },
    })
  }

  handleCTAUrlChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ctaURL = value
    const validations = validateField(ctaURL, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])
    if (validations.value) {
      this.setState({
        ctaUrl: {
          value: ctaURL,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ctaUrl: {
        value: ctaURL,
        error: false,
        errorText: '',
      },
    })
  }

  handleCityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const city = value

    const validations = validateField(city, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        city: {
          value: city,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      city: {
        value: city,
        error: false,
        errorText: '',
      },
    })
  }

  handleSlugChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const slug = value

    const validations = validateField(slug, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])

    if (validations.value) {
      this.setState({
        slug: {
          value: slug,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      slug: {
        value: slug,
        error: false,
        errorText: '',
      },
    })
  }

  handleUrlChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const url = value

    const validations = validateField(url, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])

    if (validations.value) {
      this.setState({
        url: {
          value: url,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      url: {
        value: url,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleIconChange = (selectedIcon: string) => {
    const currentIconSelected = this.state.iconName

    if (currentIconSelected === selectedIcon) {
      this.setState({ iconName: '' })
    } else {
      this.setState({ iconName: selectedIcon, iconError: false })
    }
  }

  handleSupplierChange = (supplier: SupplierOption) => {
    this.setState({
      supplier: {
        value: supplier,
        error: false,
        errorText: '',
      },
    })
  }

  handleAssetsChange = (assets: any[]) => {
    let mobileAssets: any = []
    let desktopAssets: any = []

    assets.forEach(asset => {
      if (asset.platform_type === 'desktop') {
        desktopAssets.push(asset)
      } else if (asset.platform_type === 'mobile') {
        mobileAssets.push(asset)
      }
    })

    if (desktopAssets.length > 0) {
      this.setState({ assetsDesktop: desktopAssets, assetDesktopError: false })
    }

    if (mobileAssets.length > 0) {
      this.setState({ assetsMobile: mobileAssets, assetMobileError: false })
    }
  }

  handleOrderChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const order = value

    this.setState({
      order: {
        value: order,
        error: false,
        errorText: '',
      },
    })
  }

  handleLongDescriptionChange = (description: string) => {
    this.setState({
      longDescription: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const description = value
    this.setState({
      description: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  handleTypeChange = (type: string) => {
    let internalValue = false
    let externalValue = false

    if (type === 'internal') {
      internalValue = true
      externalValue = false
    } else if (type === 'external') {
      internalValue = false
      externalValue = true
    }

    this.setState({
      isExternal: externalValue,
      isInternal: internalValue,
      specialtyType: type,
      typeError: false,
    })
  }

  scrollToError = () => {
    const { specialtyType } = this.state
    const inputIds =
      specialtyType === 'internal' ? inputIdsInternal : inputIdsExternal
    const selectsRequired =
      specialtyType === 'internal'
        ? selectsRequiredInternal
        : selectsRequiredExternal

    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    selectsRequired.forEach(id => {
      if (this.state[id] && !this.state[id].value) {
        error = false
        errorsMapSelects[id] = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    errorsMapSelects['features'] = []

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })
    return error
  }

  validateAssets = () => {
    const { assetsDesktop, assetsMobile } = this.state

    let desktopValidation = false
    let mobileValidation = false

    if (assetsDesktop.length === 0) {
      desktopValidation = true
    }
    if (assetsMobile.length === 0) {
      mobileValidation = true
    }

    this.setState({
      assetDesktopError: desktopValidation,
      assetMobileError: mobileValidation,
    })

    return desktopValidation || mobileValidation
  }

  validateIcon = () => {
    const { iconName } = this.state

    let iconValidation = false

    if (iconName === '') {
      iconValidation = true
    }

    this.setState({ iconError: iconValidation })

    return iconValidation
  }

  validateType = () => {
    const { specialtyType } = this.state

    let typeValidation = false

    if (specialtyType === '') {
      typeValidation = true
    }

    debugger
    this.setState({ typeError: typeValidation })
  }

  async mapElements() {
    const { specialtyType } = this.state
    const inputIds =
      specialtyType === 'internal' ? inputIdsInternal : inputIdsExternal
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      name,
      city,
      order,
      status,
      description,
      assetsDesktop,
      assetsMobile,
      assetDesktopError,
      assetMobileError,
      isInternal,
      isExternal,
      specialtyType,
      slug,
      url,
      iconName,
      supplier,
      hasCta,
      ctaMainText,
      ctaSecondText,
      ctaUrl,
      longDescription,
      iconError,
      typeError,
    } = this.state

    const { onCreated } = this.props
    const iconList = CATEGORY_ICONS_OPTIONS

    return (
      <ThemeContext.Consumer>
        {notify => (
          <Modal>
            {({ openModal }) => {
              return (
                <React.Fragment>
                  <FormSection
                    title="Información General"
                    openedByDefault={true}
                  >
                    <Paper>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Input
                            id="name"
                            value={name.value}
                            onChange={this.handleNameChange}
                            type="text"
                            error={name.error}
                            label="Nombre de especialidad"
                            required
                          />
                          {name.error ? (
                            <Error>{name.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={8}>
                          <Input
                            id="description"
                            value={description.value}
                            error={description.error}
                            onChange={this.handleDescriptionChange}
                            type="text"
                            label="Descripción corta"
                            required
                          />
                          {description.error ? (
                            <Error>{description.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Select
                            id="status"
                            label="Estado"
                            placeholder="Seleccione un estado"
                            required
                            options={STATUS_OPTIONS_SUPPLIERS}
                            value={status}
                            onChange={this.handleStatusChange}
                            backspaceRemovesValue={false}
                          />
                        </Grid.Column>
                        <Grid.Column md={2}>
                          <Input
                            id="order"
                            value={order.value}
                            onChange={this.handleOrderChange}
                            type="number"
                            label="Prioridad"
                          />
                        </Grid.Column>
                        <Grid.Column md={2}>
                          <Input
                            id="city"
                            value={city.value}
                            onChange={this.handleCityChange}
                            type="text"
                            error={city.error}
                            label="Ciudad"
                            required
                          />
                          {city.error ? (
                            <Error>{city.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <SpecialtyTypeLabel>
                            Tipo de especialidad <span>*</span>
                          </SpecialtyTypeLabel>
                          <SpecialtyTypeContainer>
                            <CheckContainer>
                              <CheckBox
                                icon={'circle'}
                                round={true}
                                label="Página interna"
                                checked={isInternal}
                                onChange={() =>
                                  this.handleTypeChange('internal')
                                }
                              />
                              <CheckBox
                                icon={'circle'}
                                round={true}
                                label="Redirección"
                                checked={isExternal}
                                onChange={() =>
                                  this.handleTypeChange('external')
                                }
                              />
                            </CheckContainer>
                            {typeError ? (
                              <Error>Debe seleccionar un tipo.</Error>
                            ) : (
                              <Spacer />
                            )}
                          </SpecialtyTypeContainer>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <CheckBox
                            id="hasCTA"
                            checked={hasCta}
                            label="CTA"
                            onChange={this.handleHasCTAChange}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      {hasCta && (
                        <>
                          <Spacer />
                          <Grid.Row>
                            <Grid.Column md={6}>
                              <Input
                                id="cta_main_text"
                                value={ctaMainText ? ctaMainText.value : ''}
                                required
                                type="text"
                                error={ctaMainText ? ctaMainText.error : false}
                                label="Texto Principal"
                                onChange={this.handleCTAMainTxtChange}
                              />
                              {ctaMainText && ctaMainText.error ? (
                                <Error>{ctaMainText.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                            <Grid.Column md={6}>
                              <Input
                                id="cta_second_text"
                                value={ctaSecondText ? ctaSecondText.value : ''}
                                required
                                type="text"
                                error={
                                  ctaSecondText ? ctaSecondText.error : false
                                }
                                label="Texto Secundiario"
                                onChange={this.handleCTASecondTextChange}
                              />
                              {ctaSecondText && ctaSecondText.error ? (
                                <Error>{ctaSecondText.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                            <Grid.Column md={12}>
                              <Input
                                id="cta_url"
                                value={ctaUrl ? ctaUrl.value : ''}
                                required
                                type="text"
                                error={ctaUrl ? ctaUrl.error : false}
                                label="URL Redirección"
                                onChange={this.handleCTAUrlChange}
                              />
                              {ctaUrl && ctaUrl.error ? (
                                <Error>{ctaUrl.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </>
                      )}
                      {specialtyType === 'internal' ? (
                        <>
                          <Spacer />
                          <Grid.Row>
                            <Grid.Column md={12}>
                              <Editor
                                id={'longDescription'}
                                label="Descripción Larga"
                                value={longDescription.value}
                                onChange={this.handleLongDescriptionChange}
                                required
                              />
                              {longDescription.error ? (
                                <Error>{longDescription.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column md={12}>
                              <Input
                                id="slug"
                                value={slug.value}
                                error={slug.error}
                                onChange={this.handleSlugChange}
                                type="text"
                                label="Slug"
                                required
                              />
                              {slug.error ? (
                                <Error>{slug.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column md={12}>
                              <SupplierSelector
                                value={supplier.value}
                                onChange={this.handleSupplierChange}
                                isRequired={true}
                                error={supplier.error}
                                isMulti={true}
                              />
                              {supplier.error ? (
                                <Error>{supplier.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column md={4}>
                              <IconSelector
                                iconSelected={iconName}
                                iconList={iconList}
                                onChangeIcon={this.handleIconChange}
                                hasParent={true}
                              />
                              {iconError ? (
                                <Error>Debe seleccionar un icono.</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </>
                      ) : specialtyType === 'external' ? (
                        <>
                          <Spacer />
                          <Grid.Row>
                            <Grid.Column md={12}>
                              <Input
                                id="url"
                                value={url.value}
                                error={url.error}
                                onChange={this.handleUrlChange}
                                type="text"
                                label="URL"
                                required
                              />
                              {url.error ? (
                                <Error>{url.errorText}</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column md={4}>
                              <IconSelector
                                iconSelected={iconName}
                                iconList={iconList}
                                onChangeIcon={this.handleIconChange}
                                hasParent={true}
                              />
                              {iconError ? (
                                <Error>Debe seleccionar un icono.</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </>
                      ) : (
                        ''
                      )}
                    </Paper>
                  </FormSection>
                  {specialtyType === 'internal' ? (
                    <>
                      <FormSection
                        title={'Carga de imagen para versión desktop'}
                      >
                        <Grid.Row>
                          <Grid.Column>
                            <Paper>
                              <AssetSelector
                                dropZoneSupport
                                colorButton="secondary"
                                textButton="Seleccionar archivos"
                                accept="image/gif,image/jpeg,image/png"
                                assets={assetsDesktop}
                                onChange={this.handleAssetsChange}
                                platformType="desktop"
                              />
                              {assetDesktopError ? (
                                <Error>Debe seleccionar un archivo.</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Paper>
                          </Grid.Column>
                        </Grid.Row>
                      </FormSection>

                      <FormSection
                        title={'Carga de imagen para versión mobile'}
                      >
                        <Grid.Row>
                          <Grid.Column>
                            <Paper>
                              <AssetSelector
                                dropZoneSupport
                                colorButton="secondary"
                                textButton="Seleccionar archivos"
                                accept="image/gif,image/jpeg,image/png"
                                assets={assetsMobile}
                                onChange={this.handleAssetsChange}
                                platformType="mobile"
                              />
                              {assetMobileError ? (
                                <Error>Debe seleccionar un archivo.</Error>
                              ) : (
                                <Spacer />
                              )}
                            </Paper>
                          </Grid.Column>
                        </Grid.Row>
                      </FormSection>
                    </>
                  ) : specialtyType === 'external' ? (
                    ''
                  ) : (
                    ''
                  )}

                  <Mutation
                    mutation={CREATE_SPECIALTY_MUTATION}
                    onCompleted={() => {
                      onCreated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Esapecialidad creada correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(createSpecialty, { loading, error }) => (
                      <Mutation mutation={UPLOAD_SPECIALTY_FILE}>
                        {(uploadSupplierFile, {}) => (
                          <ActionWrapper>
                            <CancelButton color="secondary" to="specialties">
                              Cancelar
                            </CancelButton>
                            <SaveButton
                              color="primary"
                              disabled={loading}
                              onClick={async () => {
                                let nextError = this.scrollToError()
                                let assetsError = this.validateAssets()
                                let errorIcon = this.validateIcon()
                                this.validateType()
                                const assets = assetsMobile.concat(
                                  assetsDesktop
                                )

                                specialtyType == 'external'
                                  ? (assetsError = false)
                                  : ''

                                if (!assetsError && !errorIcon) {
                                  if (nextError) {
                                    const uploadedImages: AssetType[] = []
                                    const videos: AssetType[] = []

                                    try {
                                      for (const asset of assets) {
                                        if (asset.type === 'video') {
                                          videos.push({
                                            url: asset.url,
                                            type: 'video',
                                            platformType: '',
                                          })
                                        } else {
                                          const response = await uploadSupplierFile(
                                            {
                                              variables: { file: asset.file },
                                            }
                                          )

                                          if (response) {
                                            const {
                                              url,
                                            } = response.data.uploadSupplierFile

                                            uploadedImages.push({
                                              url,
                                              type: 'image',
                                              platformType: asset.platform_type,
                                            })
                                          }
                                        }
                                      }
                                    } catch (error) {
                                      console.log('error uploading file', error)
                                      return
                                    }

                                    let suppliersIDs = []
                                    let summary = longDescription.value

                                    if (specialtyType === 'internal') {
                                      suppliersIDs = supplier.value.map(
                                        (sp: any) => {
                                          return sp.id
                                        }
                                      )
                                    } else {
                                      summary = description.value
                                    }

                                    createSpecialty({
                                      variables: {
                                        input: {
                                          name: name.value,
                                          city: city.value,
                                          order: Number(order.value),
                                          status: status && status['value'],
                                          type: specialtyType,
                                          summary: summary,
                                          description: description.value,
                                          slug: slug.value,
                                          icon: iconName,
                                          url: url.value,
                                          suppliers: suppliersIDs,
                                          assets: [
                                            ...uploadedImages,
                                            ...videos,
                                          ].map(asset => ({
                                            url: asset.url,
                                            type: asset.type,
                                            platform_type: asset.platformType,
                                          })),
                                          metadata: hasCta
                                            ? {
                                                cta: {
                                                  cta_main_text:
                                                    ctaMainText.value,
                                                  cta_second_text:
                                                    ctaSecondText.value,
                                                  cta_url: ctaUrl.value,
                                                },
                                              }
                                            : undefined,
                                        },
                                      },
                                    })
                                  }
                                }
                              }}
                            >
                              {loading
                                ? 'Creando especialidad...'
                                : 'Crear especialidad'}
                            </SaveButton>
                          </ActionWrapper>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                </React.Fragment>
              )
            }}
          </Modal>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Specialty
