import React from 'react'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import MembershipTable from './components/MembershipTable'

const CategoryList = () => (
  <PageWrapper title="Socios">
    <Paper>
      <MembershipTable />
    </Paper>
  </PageWrapper>
)

export default CategoryList
