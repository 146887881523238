import React from 'react'
import { Message } from '../style'

interface PaginationInfoProps {
  totalItemsCount: number
  itemsCountPerPage: number
  activePage: number
  elementType?: string
}

const PaginationInfo = ({
  totalItemsCount,
  itemsCountPerPage,
  activePage,
  elementType,
}: PaginationInfoProps) => {
  const activePageRange = activePage * itemsCountPerPage

  return (
    <Message>
      Mostrando <b>{activePageRange - (itemsCountPerPage - 1)}</b> al{' '}
      <b>
        {activePageRange > totalItemsCount ? totalItemsCount : activePageRange}
      </b>{' '}
      de <b>{totalItemsCount}</b> {elementType ? elementType : 'elementos'}
    </Message>
  )
}

export default PaginationInfo
