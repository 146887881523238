import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import CampaignTable from './components/CampaignTable'

import { PageTopSection, CreateProductButton } from './style'

const CampaignList = () => (
  <PageWrapper title="Campañas">
    <PageTopSection>
      <Link to="/campaigns/create">
        <CreateProductButton icon="plus" color="success">
          Crear Campaña
        </CreateProductButton>
      </Link>
    </PageTopSection>
    <Paper>
      <CampaignTable />
    </Paper>
  </PageWrapper>
)

export default CampaignList
