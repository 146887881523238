import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter, Link } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parseCursorPageParams } from '../../../../helpers/params'
import { Tooltip } from '../../../../components/FormElements'

import { ActionContainer } from '../../../../components/Globals'
import ThemeContext from '../../../../context/ThemeContext'
import { EditIcon } from '../../../Category/List/style'

const GET_COUPONS_QUERY = gql`
  query getCouponsQuery($options: CouponOptions!, $filters: CouponFilters!) {
    coupons(options: $options, filters: $filters) {
      nodes {
        id
        code
        identification
        redeem
        value
        expires
        expiresFrom
        expiresTo
        firstName
        lastName
        createdAt
        updatedAt
        is_general
      }
      pagination {
        limit
        cursors {
          before
          after
        }
      }
    }
  }
`

type CouponNode = Record<string, any>

interface Data {
  coupons: {
    nodes: CouponNode[]
    pagination: {
      limit: number
      cursors: {
        before: string
        after: string
      }
    }
  }
}

type FilterOptionType = Record<string, any>

interface couponTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}

class couponTable extends React.Component<
  RouteComponentProps<{}>,
  couponTableState
> {
  state: couponTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }

  componentDidMount() {
    const { supplier } = parseCursorPageParams(
      this.props.location.search.slice(1)
    )

    if (supplier) {
      this.setState({ filtersOpened: true, supplierFromUrl: supplier })
    }
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  extractPageParams = () => {
    const {
      page_size,
      sort_by,
      after,
      before,
      status,
      s,
    } = parseCursorPageParams(this.props.location.search.slice(1))
    return {
      page_size,
      sort_by,
      after,
      before,
      status,
      s,
    }
  }

  onSearch = (searchText: string | undefined) => {
    const params = this.extractPageParams()

    params.s = searchText
    params.after = ''
    params.before = ''
    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  onAfter = (after: string | undefined) => {
    const params = this.extractPageParams()
    params.after = after

    if (params.before) {
      delete params.before
    }

    this.updatePageParams(params)
  }

  onBefore = (before: string | undefined) => {
    const params = this.extractPageParams()
    params.before = before

    if (params.after) {
      delete params.after
    }

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (params.before) {
      delete params.before
    }

    if (params.after) {
      delete params.after
    }
    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  render() {
    const { filtersOpened, filterSelected, supplierFromUrl } = this.state

    const { page_size, after, before, status, s } = this.extractPageParams()

    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

    return (
      <ThemeContext.Consumer>
        {context => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                after,
                before,
              },
              filters: { status, s },
            }}
            query={GET_COUPONS_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }

              const { nodes, pagination } = data.coupons

              const statusOptions: FilterOptionType[] = []
              statusOptions.push(
                {
                  label: 'Todos los cupones',
                  value: 'all',
                },

                { label: 'Por redimir', value: false },
                { label: 'Redimido', value: true }
              )

              const expiresOptions: FilterOptionType[] = []
              expiresOptions.push(
                { label: 'No', value: false },
                { label: 'Si', value: true }
              )

              const columns = [
                {
                  header: '# Identificación',
                  key: 'identification',
                  sortable: false,
                  Cell: ({ identification, is_general }: CouponNode) => {
                    if (is_general) {
                      return `General`
                    }
                    return `${identification}`
                  },
                },
                {
                  header: 'Cúpon',
                  key: 'code',
                  sortable: false,
                },
                {
                  header: 'Cliente',
                  key: 'firsName',
                  sortable: false,
                  Cell: ({ firstName, lastName, is_general }: CouponNode) => {
                    if (is_general) {
                      return `General`
                    }
                    return `${firstName} ${lastName}`
                  },
                },
                {
                  header: 'Estado',
                  key: 'redeem',
                  sortable: false,
                  Cell: ({ redeem }: CouponNode) => {
                    let statusStr = ''
                    statusOptions.filter(status => {
                      if (status.value === redeem) {
                        statusStr = status.label
                      }
                      return status.value === redeem
                    })
                    return statusStr
                  },
                },
                {
                  header: 'Valor',
                  key: 'value',
                  sortable: false,
                },
                {
                  header: 'Expira',
                  key: 'expires',
                  sortable: false,
                  Cell: ({ expires }: CouponNode) => {
                    let statusStr = ''
                    expiresOptions.filter(status => {
                      if (status.value === expires) {
                        statusStr = status.label
                      }
                      return status.value === expires
                    })

                    return statusStr
                  },
                },
                {
                  header: 'Fecha Expiración desde',
                  key: 'expiresFrom:',
                  sortable: false,
                  Cell: (coupon: CouponNode) => {
                    if (coupon.expiresFrom) {
                      const d = new Date(coupon.expiresFrom)
                      const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                      const mm =
                        (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                      const yyyy = d.getFullYear()
                      const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                      const min =
                        (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                      const sec =
                        (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                      if (yyyy == 0) {
                        return ''
                      }
                      const date =
                        yyyy +
                        '-' +
                        mm +
                        '-' +
                        dd +
                        ' ' +
                        hour +
                        ':' +
                        min +
                        ':' +
                        sec
                      return date
                    }
                    return ''
                  },
                },
                {
                  header: 'Fecha Expiración hasta',
                  key: 'expiresTo:',
                  sortable: false,
                  Cell: (coupon: CouponNode) => {
                    if (coupon.expiresTo) {
                      const d = new Date(coupon.expiresTo)
                      const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                      const mm =
                        (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                      const yyyy = d.getFullYear()
                      const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                      const min =
                        (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                      const sec =
                        (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()
                      if (yyyy == 0) {
                        return ''
                      }
                      const date =
                        yyyy +
                        '-' +
                        mm +
                        '-' +
                        dd +
                        ' ' +
                        hour +
                        ':' +
                        min +
                        ':' +
                        sec
                      return date
                    }
                    return ''
                  },
                },
                {
                  header: 'Fecha redimido',
                  key: 'dateRedeem',
                  sortable: false,
                  Cell: (coupon: CouponNode) => {
                    if (coupon.redeem && coupon.updatedAt) {
                      const d = new Date(coupon.updatedAt)
                      const dd = (d.getDate() < 10 ? '0' : '') + d.getDate()
                      const mm =
                        (d.getMonth() + 1 < 10 ? '0' : '') + (d.getMonth() + 1) // January is 0!
                      const yyyy = d.getFullYear()
                      const hour = (d.getHours() < 10 ? '0' : '') + d.getHours()
                      const min =
                        (d.getMinutes() < 10 ? '0' : '') + d.getMinutes()
                      const sec =
                        (d.getSeconds() < 10 ? '0' : '') + d.getSeconds()

                      if (yyyy == 0) {
                        return ''
                      }
                      const date =
                        yyyy +
                        '-' +
                        mm +
                        '-' +
                        dd +
                        ' ' +
                        hour +
                        ':' +
                        min +
                        ':' +
                        sec
                      return date
                    }
                    return 'Por redimir'
                  },
                },
                {
                  header: 'Acciones',
                  key: 'actions',
                  width: 99,
                  sortable: false,
                  Cell: (coupon: CouponNode) => (
                    <ActionContainer>
                      <Link to={`coupons/${coupon.code}/edit`}>
                        <Tooltip id={`edit-${coupon.code}`} message="Editar">
                          <EditIcon name="pencil" />
                        </Tooltip>
                      </Link>
                    </ActionContainer>
                  ),
                },
              ]

              return (
                <React.Fragment>
                  <DataTable
                    indexKey="id"
                    columns={columns}
                    data={nodes}
                    loading={loading}
                    sortBy={''}
                    pageSize={pageSize}
                    pageSizeOptions={PAGE_SIZE_OPTIONS}
                    onSort={this.onSortByChange}
                    onPageSizeChange={this.onPageSizeChange}
                    onAfter={this.onAfter}
                    onBefore={this.onBefore}
                    after={pagination.cursors.after}
                    before={pagination.cursors.before}
                    filters={[
                      {
                        key: 'status',
                        label: 'Estados',
                        options: statusOptions,
                      },
                    ]}
                    onFetch={this.onFetch}
                    searchText={s}
                    onSearch={this.onSearch}
                    placeholderSearchBar={'Buscar por código de cupón'}
                    filtersOpened={filtersOpened}
                    toggleFilterSection={this.toggleFilterSection}
                    filterSelected={filterSelected}
                    onFilterSelected={this.onFilterSelected}
                    supplierFromUrl={supplierFromUrl}
                  />
                </React.Fragment>
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(couponTable)
