import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import Modal from '../../../../components/Modals/ModalRoot'
import { STATUS_OPTIONS_SUPPLIERS } from '../../../../config'
import { SaveButton } from '../style'
import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Spacer, ActionWrapper } from '../../../../components/Globals'

import AssetSelector from '../../../../components/AssetSelector'
import { Input, Select, Error } from '../../../../components/FormElements'

import ThemeContext from '../../../../context/ThemeContext'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'

const UPLOAD_BANNER_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

const CREATE_BANNER_MUTATION = gql`
  mutation createBannerMutation($input: CreateBannerInput!) {
    createBanner(input: $input) {
      id
    }
  }
`

interface Props {
  onCreated: () => void
}

interface AssetData {
  url: string
  type: string
  platform_type: string
  file: any
}

interface State {
  name: Field
  order: Field
  status: ValueType<string | number | { [key: string]: any }>
  assetsDesktop: AssetData[]
  assetsMobile: AssetData[]
  createError: boolean
  inputs: InputElements[]
  assetDesktopError: boolean
  assetMobileError: boolean
  button_text: string
  button_url: string
  banner_info: string
  isLoading: boolean
}

type AssetType = {
  url: string
  type: string
  order?: number
  platformType: string
}

const inputIds = [
  'status',
  'order',
  'name',
  'buttonText',
  'buttonURL',
  'bannerInfo',
]

class Banner extends React.Component<Props, State> {
  state: State = {
    name: initialField,
    order: initialField,
    status: { value: 'active', label: 'Activo' },
    assetsDesktop: [],
    assetsMobile: [],
    createError: false,
    inputs: [],
    assetDesktopError: false,
    assetMobileError: false,
    button_text: '',
    button_url: '',
    banner_info: '',
    isLoading: false,
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }
  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleButtonChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const button_text = value
    this.setState({ button_text })
  }

  handleButtonURLChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const button_url = value
    this.setState({ button_url })
  }

  handleInfoChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const banner_info = value
    this.setState({ banner_info })
  }

  handleAssetsChange = (assets: any[]) => {
    let mobileAssets: any = []
    let desktopAssets: any = []

    assets.forEach(asset => {
      if (asset.platform_type === 'desktop') {
        desktopAssets.push(asset)
      } else if (asset.platform_type === 'mobile') {
        mobileAssets.push(asset)
      }
    })

    if (desktopAssets.length > 0) {
      this.setState({ assetsDesktop: desktopAssets, assetDesktopError: false })
    }

    if (mobileAssets.length > 0) {
      this.setState({ assetsMobile: mobileAssets, assetMobileError: false })
    }
  }

  handleOrderChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const order = value

    this.setState({
      order: {
        value: order,
        error: false,
        errorText: '',
      },
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })
    return error
  }

  validateAssets = () => {
    const { assetsDesktop, assetsMobile } = this.state

    let desktopValidation = false
    let mobileValidation = false

    if (assetsDesktop.length === 0) {
      desktopValidation = true
    }
    if (assetsMobile.length === 0) {
      mobileValidation = true
    }

    this.setState({
      assetDesktopError: desktopValidation,
      assetMobileError: mobileValidation,
    })

    return desktopValidation || mobileValidation
  }

  async mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      name,
      order,
      status,
      assetsDesktop,
      assetsMobile,
      assetDesktopError,
      assetMobileError,
      button_text,
      button_url,
      banner_info,
    } = this.state

    const { onCreated } = this.props
    return (
      <ThemeContext.Consumer>
        {notify => (
          <Modal>
            {({ openModal }) => {
              return (
                <React.Fragment>
                  <FormSection
                    title="Información General"
                    openedByDefault={true}
                  >
                    <Paper>
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Input
                            id="name"
                            value={name.value}
                            onChange={this.handleNameChange}
                            type="text"
                            error={name.error}
                            label="Nombre"
                            required
                          />
                          {name.error ? (
                            <Error>{name.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={3}>
                          <Select
                            id="status"
                            label="Estado"
                            placeholder="Seleccione un estado"
                            required
                            options={STATUS_OPTIONS_SUPPLIERS}
                            value={status}
                            onChange={this.handleStatusChange}
                            backspaceRemovesValue={false}
                          />
                        </Grid.Column>
                        <Grid.Column md={3}>
                          <Input
                            id="order"
                            value={order.value}
                            onChange={this.handleOrderChange}
                            type="number"
                            label="Prioridad"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Input
                            id="buttonText"
                            value={button_text ? button_text : ''}
                            onChange={this.handleButtonChange}
                            type="text"
                            label="Texto del botón"
                          />
                        </Grid.Column>
                        <Grid.Column md={6}>
                          <Input
                            id="buttonURL"
                            value={button_url ? button_url : ''}
                            onChange={this.handleButtonURLChange}
                            type="text"
                            label="Link del botón"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <br />
                      <Grid.Row>
                        <Grid.Column md={12}>
                          <Input
                            id="bannerInfo"
                            value={banner_info ? banner_info : ''}
                            onChange={this.handleInfoChange}
                            type="text"
                            label="Texto del banner"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Paper>
                  </FormSection>

                  <FormSection title={'Carga de imagen para versión desktop'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg,image/png"
                            assets={assetsDesktop}
                            onChange={this.handleAssetsChange}
                            platformType="desktop"
                          />
                          {assetDesktopError ? (
                            <Error>Debe seleccionar un archivo.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <FormSection title={'Carga de imagen para versión mobile'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg,image/png"
                            assets={assetsMobile}
                            onChange={this.handleAssetsChange}
                            platformType="mobile"
                          />
                          {assetMobileError ? (
                            <Error>Debe seleccionar un archivo.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <Mutation
                    mutation={CREATE_BANNER_MUTATION}
                    onCompleted={() => {
                      onCreated()
                      this.setState({
                        isLoading: false,
                      })
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Banner creado correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                      this.setState({
                        isLoading: false,
                      })
                    }}
                  >
                    {(createBanner, { loading, error }) => {
                      return (
                        <Mutation mutation={UPLOAD_BANNER_FILE}>
                          {(uploadSupplierFile, {}) => (
                            <ActionWrapper>
                              <SaveButton
                                color="primary"
                                disabled={this.state.isLoading}
                                onClick={async () => {
                                  this.setState({
                                    isLoading: true,
                                  })

                                  let nextError = this.scrollToError()
                                  let assetsError = this.validateAssets()

                                  if (assetsError || !nextError) {
                                    this.setState({
                                      isLoading: false,
                                    })
                                  }

                                  const assets = assetsMobile.concat(
                                    assetsDesktop
                                  )

                                  if (!assetsError) {
                                    if (nextError) {
                                      const uploadedImages: AssetType[] = []
                                      const videos: AssetType[] = []

                                      try {
                                        for (const asset of assets) {
                                          if (asset.type === 'video') {
                                            videos.push({
                                              url: asset.url,
                                              type: 'video',
                                              platformType: '',
                                            })
                                          } else {
                                            const response = await uploadSupplierFile(
                                              {
                                                variables: { file: asset.file },
                                              }
                                            )

                                            if (response) {
                                              const {
                                                url,
                                              } = response.data.uploadSupplierFile

                                              uploadedImages.push({
                                                url,
                                                type: 'image',
                                                platformType:
                                                  asset.platform_type,
                                              })
                                            }
                                          }
                                        }
                                      } catch (error) {
                                        console.log(
                                          'error uploading file',
                                          error
                                        )
                                        this.setState({
                                          isLoading: false,
                                        })
                                        return
                                      }

                                      createBanner({
                                        variables: {
                                          input: {
                                            name: name.value,
                                            order: Number(order.value),
                                            status: status && status['value'],
                                            buttonText: button_text,
                                            buttonURL: button_url,
                                            bannerInfo: banner_info,
                                            assets: [
                                              ...uploadedImages,
                                              ...videos,
                                            ].map(asset => ({
                                              url: asset.url,
                                              type: asset.type,
                                              platform_type: asset.platformType,
                                            })),
                                          },
                                        },
                                      })
                                    }
                                  }
                                }}
                              >
                                {this.state.isLoading
                                  ? 'Creando banner...'
                                  : 'Crear Banner'}
                              </SaveButton>
                            </ActionWrapper>
                          )}
                        </Mutation>
                      )
                    }}
                  </Mutation>
                </React.Fragment>
              )
            }}
          </Modal>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Banner
