import React from 'react'
import PageWrapper from '../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import CouponTable from './components/CouponTable'

import { PageTopSection } from './style'
import { Link } from 'react-router-dom'
import { CreateCouponButton } from './style'

export const CouponList = () => {
  return (
    <PageWrapper title="Lista de Cupones">
      <PageTopSection>
        <Link to="/coupons/create">
          <CreateCouponButton icon="plus" color="success">
            Crear Cupón
          </CreateCouponButton>
        </Link>
      </PageTopSection>
      <Paper>
        <CouponTable />
      </Paper>
      <PageTopSection />
    </PageWrapper>
  )
}
