import styled from 'styled-components'
import { Button } from '../../../components/Buttons'
import sizes from '../../../styles/media'

export const SaveButton = styled(Button)`
  width: 100%;
  @media ${sizes.sm} {
    width: auto;
  }
`
export const Padding_top = styled.div.attrs((/* props */) => ({ tabIndex: 0 }))`
  .something-else & {
    padding-top: 2rem;
  }
`
export const CheckBoxContainer = styled.div`
  margin: 10px 0 20px 10px;
`
