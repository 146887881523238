import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from './../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import BannerTable from './components/BannerTable'

import { PageTopSection, CreateBannerButton } from './style'

const BannerList = () => (
  <PageWrapper title="Banners">
    <PageTopSection>
      <Link to="/banners/create">
        <CreateBannerButton icon="plus" color="success">
          Crear Banner
        </CreateBannerButton>
      </Link>
    </PageTopSection>
    <Paper>
      <BannerTable />
    </Paper>
  </PageWrapper>
)

export default BannerList
