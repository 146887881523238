import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateBannerForm from './components/CreateSpecialityForm'
import EditSpecialtyForm from './components/EditSpecialtyForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_SPECIALTY_QUERY = gql`
  query getSpecialtyQuery($id: ID!) {
    specialty(id: $id) {
      id
      name
      city
      order
      type
      status
      summary
      description
      slug
      icon
      url
      suppliers {
        id
        businessName
      }
      assets {
        id
        url
        type
        platformType
      }
      meta_data {
        cta {
          cta_main_text
          cta_second_text
          cta_url
        }
      }
    }
  }
`
// interface Option {
//   id: string
//   status: string
// }
interface Data {
  specialty: {
    id: string
    name: string
    city: string
    status: string
    order: number
    slug: string
    description: string
    summary: string
    icon: string
    type: string
    url: string
    suppliers: Array<string>
    assets: Array<{
      id: string
      url: string
      platformType: string
      type: string
    }>
    hasCta: boolean
    cta_main_text?: string
    cta_second_text?: string
    cta_url?: string
  }
}

class Specialties extends React.Component<RouteComponentProps<RouteParams>> {
  onBannerSaved = () => {
    this.props.history.push('/specialties')
  }
  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Especialidad`}
      >
        {action === 'create' && (
          <CreateBannerForm onCreated={this.onBannerSaved} />
        )}
        {action === 'edit' && (
          <Query
            variables={{ id }}
            query={GET_SPECIALTY_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditSpecialtyForm
                  specialty={data.specialty}
                  onUpdated={this.onBannerSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(Specialties)
