import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from './../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import SpecialtiesTable from './components/PromotionsTable'

import { PageTopSection, CreateSpecialtyButton } from './style'

const PromotionsList = () => (
  <PageWrapper title="Promociones">
    <PageTopSection>
      <Link to="/promotions/create">
        <CreateSpecialtyButton icon="plus" color="success">
          Crear Promoción
        </CreateSpecialtyButton>
      </Link>
    </PageTopSection>
    <Paper>
      <SpecialtiesTable />
    </Paper>
  </PageWrapper>
)

export default PromotionsList
