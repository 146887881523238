export const API_URL =
  process.env.REACT_APP_API_URL ||
  `${window.location.protocol}//${window.location.host}/api`

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100]

export const STATUS_OPTIONS = [
  { value: 'active', label: 'Activo' },
  { value: 'inactive', label: 'Inactivo' },
  { value: 'discontinued', label: 'Descontinuado' },
]

export const STATUS_OPTIONS_SUPPLIERS = [
  { value: 'active', label: 'Activo' },
  { value: 'inactive', label: 'Inactivo' },
]

export const SUPPLIER_SCHEDULE = [
  { value: '09:00', label: '09:00' },
  { value: '12:00', label: '12:00' },
  { value: '14:00', label: '14:00' },
  { value: '18:00', label: '18:00' },
]

export const IDENTIFICATION_OPTION = [
  { value: 'CI', label: 'Cédula' },
  { value: 'RUC', label: 'RUC' },
  { value: 'PPN', label: 'Pasaporte' },
]

export const CATEGORY_ICONS_OPTIONS = [
  { value: 'camp-01' },
  { value: 'camp-02' },
  { value: 'camp-03' },
  { value: 'camp-04' },
  { value: 'productos_menu' },
  { value: 'bebidas' },
  { value: 'frutas' },
  { value: 'proteinas' },
  { value: 'especialidades' },
  { value: 'snacks' },
  { value: 'wine' },
]
