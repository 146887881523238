import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateBannerForm from './components/CreateBannerForm'
import EditBannerForm from './components/EditBannerForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_BANNER_QUERY = gql`
  query getBannerQuery($id: ID!) {
    banner(id: $id) {
      id
      name
      order
      status
      button_text
      button_url
      banner_info
      assets {
        id
        url
        type
        platformType
      }
    }
  }
`
// interface Option {
//   id: string
//   status: string
// }
interface Data {
  banner: {
    id: string
    name: string
    status: string
    order: number
    button_text: string
    button_url: string
    banner_info: string
    assets: Array<{
      id: string
      url: string
      platformType: string
      type: string
    }>
  }
}

class Banner extends React.Component<RouteComponentProps<RouteParams>> {
  onBannerSaved = () => {
    this.props.history.push('/banners')
  }
  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper title={`${action === 'create' ? 'Crear' : 'Editar'} Banner`}>
        {action === 'create' && (
          <CreateBannerForm onCreated={this.onBannerSaved} />
        )}
        {action === 'edit' && (
          <Query
            variables={{ id }}
            query={GET_BANNER_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }
              return (
                <EditBannerForm
                  banner={data.banner}
                  onUpdated={this.onBannerSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(Banner)
