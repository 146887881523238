import styled from 'styled-components'

import Icon from '../Icon'
import PageButtonComponent from './components/PageButton'
import { Input } from '../FormElements'
import { Button } from '../Buttons'
import CursorPage from './components/CursorPage'

import sizes from '../../styles/media'

export const Container = styled.div`
  border-radius: 20px 20px 0 0;
  position: relative;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  position: relative;

  @media ${sizes.xl} {
    flex-direction: row;
    align-items: flex-start;
  }
`

export const CursorSection = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: flex-end;
`

export const SearchLabel = styled.span`
  display: block;
  font-size: 14px;
  color: #858585;
  padding: 0 0 6px 8px;
`

export const SearchForm = styled.form`
  padding-bottom: 20px;
`

export const SearchBox = styled.div`
  position: relative;
`

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
  button {
    margin: 0px 5px;
  }
`

export const SearchInput = styled(Input)`
  padding-right: 77px;

  @media ${sizes.xl} {
    padding-right: 135px;
  }
`

export const SearchButton = styled(Button)`
  position: absolute;
  right: 1px;
  top: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 12px 5px;
  width: 57px;
  & span {
    display: none;
  }

  @media ${sizes.xl} {
    width: 115px;
    & span {
      display: block;
    }
  }
`

export const Table = styled.table`
  display: block;
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  border-spacing: 0;
  overflow: hidden;

  @media ${sizes.xl} {
    display: table;
    border: 0;
    overflow: auto;
  }
`

export const TableHead = styled.thead`
  display: none;

  @media ${sizes.xl} {
    display: table-header-group;
  }
`

export const TableHeaderRow = styled.tr`
  display: table-row;
  border-bottom: 1px solid #858585;
`

interface TableHeaderProps {
  index: number
  width?: number
}

export const TableHeader = styled.th`
  vertical-align: middle;
  padding: 10px 20px;
  height: 40px;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-align: left;
  background-color: #858585;

  ${(props: TableHeaderProps) => {
    let styles = ''

    if (props.index !== 0) {
      styles += 'border-left: 1px solid #c5c5c5;'
    }

    if (props.width) {
      styles += `${props.width}px;`
    }

    return styles
  }};

  &:first-child {
    padding-left: 25px;
    &:before {
      content: ' ';
      border-top-left-radius: 20px;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      background-color: #858585;
      z-index: 1;
    }
    &:after {
      content: ' ';
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      background: white;
    }
  }
  &:last-child {
    padding-right: 25px;
    &:before {
      content: ' ';
      border-top-right-radius: 20px;
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
      background-color: #858585;
      z-index: 1;
    }
    &:after {
      content: ' ';
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: 0;
      background: white;
    }
  }
`

export const TableBody = styled.tbody`
  display: block;

  @media ${sizes.xl} {
    display: table-header-group;
  }
`

interface TableRowProps {
  index: number
  highlightColumnByStatus: string
  band: boolean
}

export const TableRow = styled.tr<TableRowProps>`
  display: block;
  background-color: ${props => {
    const status = props.highlightColumnByStatus
    let color = 'transparent'
    const band = props.band
    if (status === 'processing') {
      color = '#F3F781'
    } else if (status === 'pending' || status === 'finish_soon') {
      color = 'rgba(253, 106, 0, 0.1)'
    } else if (status === 'rejected' && !band) {
      color = 'rgba(255, 45, 45, 0.2)'
    } else if (status === 'pending_to_settlement' && band) {
      color = 'rgba(255, 45, 45, 0.2)'
    } else if (status === 'rejected' && band) {
      color = '#ffffff'
    } else if (
      (status === 'approved' && !band) ||
      (status === 'active' && band)
    ) {
      color = 'rgba(76, 217, 100, 0.1)'
    } else if (status === 'approved' && band) {
      color = '#ffffff'
    } else if (status === 'finalized' && !band) {
      color = '#ffffff'
    } else if (status === 'finalized' && band) {
      color = '#ffffff'
    } else if (status === 'footer') {
      color = 'transparent'
    } else if (props.index % 2 !== 0) {
      color = '#f0faff'
    }
    return color
  }};
  ${props => props.index !== 0 && 'border-top: 1px solid #c5c5c5;'};
  padding: 20px;

  ${props =>
    props.highlightColumnByStatus === 'total' &&
    'border-bottom-right-radius: 10px;'};

  @media ${sizes.md} {
    display: table-row;

    padding: 0;
    border: 0;
  }
`

interface TableCellProps {
  index: number
  txtFooter?: boolean
}

export const TableCell = styled.div<TableCellProps>`
  position: relative;
  font-size: 14px;
  font-weight: normal;
  color: ${props => {
    const index = props.index
    const footer = props.txtFooter
    let color = '#858585'
    if (footer && index === 0) {
      color = '#3db8fc'
    } else if (footer && index === 1) {
      color = '#fff'
    }

    return color
  }};

  ${props =>
    props.txtFooter === true &&
    props.index === 1 &&
    `background: #858585; border-bottom-right-radius: 20px;`};

  ${props =>
    props.txtFooter === true &&
    props.index === 0 &&
    `background: #f5f5f5; border-bottom-left-radius: 20px;`};

  display: flex;
  align-items: center;
  padding: 5px 0;

  @media ${sizes.xl} {
    display: table-cell;
    vertical-align: middle;

    padding: 10px 20px;
    height: 60px;

    ${props => props.index === 0 && `border-left: 1px solid #c5c5c5;`};
    ${props =>
      props.txtFooter === true && props.index === 0 && `text-align: right;`};

    border-bottom: 1px solid #c5c5c5;
    border-right: 1px solid #c5c5c5;
  }
`

export const MobileHeader = styled.b`
  margin-right: 5px;

  @media ${sizes.xl} {
    display: none;
  }
`

const SortIconContainer = styled.span`
  position: absolute;
  top: 0;
  right: 5px;
`

interface SortIconContainerProps {
  active: boolean
}

export const SortTopIconContainer = styled(SortIconContainer)`
  ${(props: SortIconContainerProps) => {
    if (props.active) {
      return `top: 11px;`
    } else {
      return `top: 6px;`
    }
  }};
`

export const SortBottomIconContainer = styled(SortIconContainer)`
  ${(props: SortIconContainerProps) => {
    if (props.active) {
      return `top: 11px;`
    } else {
      return `top: 14px;`
    }
  }};
`

export const SortIcon = styled(Icon)`
  color: #fff;
  font-size: 14px;
`

export const ResultsPerPage = styled.div`
  display: flex;
  align-items: center;

  & span {
    flex-shrink: 0;
    padding-left: 10px;
  }
`

export const Filters = styled.div`
  display: flex;
  align-items: center;

  & span {
    flex-shrink: 0;
    padding-right: 10px;
  }

  order: -1;
  padding-bottom: 10px;

  @media ${sizes.xl} {
    order: initial;
    padding-bottom: 0;
  }
`

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px 20px 0 0;
  z-index: 1;
`

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

interface PageButton {
  first?: boolean
  last?: boolean
  active?: boolean
  disabled?: boolean
}

export const PageButton = styled.a`
  padding: 13px;
  align-items: center;
  border: 1px solid #e5e5e5;
  text-decoration: none;
  border-radius: 10px;
  background-color: #858585;
  color: white;

  ${(props: PageButton) => {
    let styles = ``

    if (props.active) {
      styles += `
        cursor: auto;
        color: white;
        background-color: #858585;
      `
    }

    if (props.disabled) {
      styles += `
        opacity: 0.8;
        cursor: not-allowed;
      `
    }

    return styles
  }};
`
export const CursorPageButton = styled(CursorPage)`
  border-radius: 10px;
  ${props => props.disabled && `opacity: 0.8;`};
`

export const FirstPage = styled(PageButtonComponent)`
  display: none;
  @media ${sizes.md} {
    display: flex;
    margin-right: 5px;
  }
`

export const PrevPage = styled(PageButtonComponent)`
  margin-right: 5px;
`

export const NextPage = styled(PageButtonComponent)`
  margin-left: 5px;
`

export const LastPage = styled(PageButtonComponent)`
  display: none;
  @media ${sizes.md} {
    display: flex;
    margin-left: 5px;
  }
`

export const CursorPrevPage = styled(CursorPageButton)`
  margin-right: 5px;
`

export const CursorNextPage = styled(CursorPageButton)`
  margin-left: 5px;
`

interface PageLinkProps {
  first?: boolean
  last?: boolean
  active?: boolean
  disabled?: boolean
}

export const PageLink = styled.a`
  padding: 13px;
  align-items: center;
  border: 1px solid #e5e5e5;
  text-decoration: none;
  color: #858585;

  ${(props: PageLinkProps) => {
    let styles = ``

    if (props.first) {
      styles += `
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      `
    }

    if (props.last) {
      styles += `
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      `
    }

    if (props.active) {
      styles += `
        cursor: auto;
        color: white;
        background-color: #858585;
      `
    }

    if (props.disabled) {
      styles += `
        cursor: not-allowed;
      `
    }

    return styles
  }};
`

export const Message = styled.p`
  font-size: 14px;
  color: #858585;
  margin: 0;
  margin-bottom: 20px;
`
export const FilterSection = styled.div`
  border: 2px #e5e5e5 solid;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 20px;
`

export const FilterTitle = styled.div`
  margin-left: 15px;
`

export const Filter = styled.div`
  margin-top: 15px;
`

interface RedSquareProps {
  index: number
  highlightColumnByStatus: string
}
export const RedSquare = styled.div`
  @media ${sizes.lg} {
    width: 10px;
    height: 40px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    left: 5px;
    background: ${(props: RedSquareProps) => {
      if (props.index === 0 && props.highlightColumnByStatus === 'rejected') {
        return 'red'
      } else if (
        props.index === 0 &&
        props.highlightColumnByStatus === 'pending'
      ) {
        return '#fd6a00'
      }
      return 'transparent'
    }};
  }
`
