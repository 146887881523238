import React, { useState, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import StyledInput from '../../../../components/Form/StyledInput'
import StyledSelect from '../../../../components/Form/StyledSelect'
import { SaveButton } from '../../../../components/Globals'
import { Row, Column } from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import ProductsTable from '../../../../components/Tables/ProductsTableSortable'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'

import ThemeContext from '../../../../context/ThemeContext'
import { CancelButton } from '../../../../components/Buttons'

const EDIT_CAMPAIGN_MUTATION = gql`
  mutation editCampaign($input: EditCampaignInput!) {
    editCampaign(input: $input)
  }
`

interface Props {
  onUpdated: () => void
  campaign: any
}

const EditCategoryForm = ({ onUpdated, campaign, ...props }: Props) => {
  const {} = campaign
  const { handleSubmit, errors, control } = useForm({
    defaultValues: campaign,
  })
  const { forceShowNotification } = useContext(ThemeContext)

  const onSubmit = (data: any) => {
    handleSave(data)
  }
  const [productIds, setProductIds] = useState<any>([])
  const client = useApolloClient()

  useEffect(()=>{
    setProductIds(campaign.metadata.products ? campaign.metadata.products : [])
  },[])

  const handleRemovedProduct = (productID: string) => {
    const auxRemovedProducts = productIds

    const index = auxRemovedProducts.indexOf(productID)
    if (index > -1) {
      auxRemovedProducts.splice(index, 1)
      setProductIds(auxRemovedProducts)
    }
  }

  const handleAddProduct = (product: any) => {
    const founded = productIds.find(
      (productItem: any) => productItem === product.id
    )

    if (!founded) {
      const auxProducts = productIds
      auxProducts.push(product.id)
      setProductIds(auxProducts)
    }
  }

  const handleSave = async (data: any) => {    
    const saved = await client.mutate({
      mutation: EDIT_CAMPAIGN_MUTATION,
      variables: {
        input: {
          id: campaign.id,
          name: data.name,
          url: data.url,
          order: data.order,
          metaData: { 'products': productIds },
          campaignType: data.template,
          campaignStatus: data.status,
        },
      },
    })

    if (saved) {
      forceShowNotification &&
        forceShowNotification({
          type: 'ok',
          message: 'Agregada la campana correctamente',
        })
      window.location.href = `/campaigns`
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'fail',
          message: 'Hubo un problema, intentelo de nuevo',
        })
    }
  }

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column md={4}>
            <StyledInput
              isRequired
              name="name"
              control={control}
              label="Nombre "
              error={errors.name}
              rules={{
                required: 'Campo Requerido',
              }}
            />
          </Column>
          <Column md={4}>
            <StyledInput
              isRequired
              name="url"
              control={control}
              label="Url Personalizada"
              error={errors.url}
              rules={{
                required: 'Campo Requerido',
              }}
            />
          </Column>
          {/*<Column md={4}>
            <StyledSelect
              isRequired
              name="template"
              control={control}
              label="Tipo de template"
              error={errors.template}
              rules={{
                required: 'Campo Requerido',
              }}
              options={[
                { label: 'Template 1', value: 1 },
                { label: 'Template 2', value: 2 },
                { label: 'Template 3', value: 3 },
                { label: 'Template 4', value: 4 },
              ]}
            />
          </Column>
          <Column md={4}>
            <StyledInput
              isRequired
              name="order"
              control={control}
              label="Orden de visualizacion"
              error={errors.order}
              rules={{
                required: 'Campo Requerido',
                min: {
                  value: 0,
                  message: 'Valor mínimo no permitido',
                },
              }}
              type="number"
              min={0}
            />
            </Column>*/}
          <Column md={4}>
            <StyledSelect
              isRequired
              name="status"
              control={control}
              label="Estado"
              error={errors.status}
              rules={{
                required: 'Campo Requerido',
              }}
              options={[
                { label: 'Activo', value: 'active' },
                { label: 'Inactivo', value: 'inactive' },
              ]}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <h2>Productos</h2>
          </Column>
          <Column>
            <ProductsTable
              updateParent={handleAddProduct}
              handleRemoved={handleRemovedProduct}
              productIds={productIds}
              setProductIds={setProductIds}
            />
          </Column>
        </Row>
        <Row>
          <Column md={3}>
            <SaveButton color="primary" type="submit">
              {'Guardar Campaña'}
            </SaveButton>
          </Column>
          <Column md={3}>
            <CancelButton to="campaigns" color="secondary" alone={true}>
              Regresar
            </CancelButton>
          </Column>
        </Row>
      </form>
    </Paper>
  )
}

export default EditCategoryForm
