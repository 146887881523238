import React from 'react'
import { Tooltip } from '../../FormElements'
import { useController } from 'react-hook-form'

import { RequiredMark, Label, Input, ErrorMessage, Wrapper } from './style'

type StyledInputProps = {
  control: any
  isRequired?: boolean
  label: string
  name: string
  error?: any
  help?: string
  rules?: object
  type?: string
  min?: number
}

const StyledInput = ({
  control,
  name,
  label,
  error,
  isRequired = false,
  help,
  rules,
  type = 'text',
  min,
}: StyledInputProps) => {
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
    rules: rules,
    defaultValue: '',
  })

  return (
    <Wrapper>
      <Label>
        {help && <Tooltip message={help} />}
        {label && <span>{label}</span>}
        {isRequired && <RequiredMark>*</RequiredMark>}
      </Label>
      <Input
        hasError={error ? true : false}
        required={isRequired}
        inputRef={ref}
        type={type}
        min={min}
        {...inputProps}
      />
      {error && <ErrorMessage>{error.message}</ErrorMessage>}
    </Wrapper>
  )
}

export default StyledInput
