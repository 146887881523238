import ThemeContext from '../../../../context/ThemeContext'
import React from 'react'

import gql from 'graphql-tag'
import { Query, QueryResult, Mutation } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { Link, withRouter } from 'react-router-dom'
import qs from 'qs'
import Modal from '../../../../components/Modals/ModalRoot'
import Loading from '../../../../components/Loader'
import { STATUS_OPTIONS_SUPPLIERS } from '../../../../config'
import { Select } from '../../../../components/FormElements'
import { PAGE_SIZE_OPTIONS } from '../../../../config'
import { ActionsContainer, EditIcon } from '../style'
import DataTable from '../../../../components/DataTable'
import { Tooltip } from '../../../../components/FormElements'
import { parsePageParams } from '../../../../helpers/params'

const GET_BANNERS_QUERY = gql`
  query getBannersQuery($options: BannersOptions!, $filters: BannersFilters!) {
    banners(options: $options, filters: $filters) {
      nodes {
        id
        name
        order
        status
        button_text
        button_url
        banner_info
      }
      pagination {
        total
      }
    }
  }
`

// interface SupplierNode {
//   id: string
//   legalName: string
//   ruc: string
// }

type BannerNode = Record<string, any>

export const EDIT_BANNER_MUTATION = gql`
  mutation editBannerMutation($input: EditBannerInput!) {
    editBanner(input: $input)
  }
`

interface Data {
  banners: {
    nodes: BannerNode[]
    pagination: {
      total: number
    }
  }
}

type FilterOptionType = Record<string, any>

interface bannerTableState {
  filtersOpened: boolean
  filterSelected: FilterOptionType | null
  supplierFromUrl: string
}

class BannerTable extends React.Component<
  RouteComponentProps<{ history: any; location: any }>,
  bannerTableState
> {
  state: bannerTableState = {
    filtersOpened: false,
    filterSelected: null,
    supplierFromUrl: '',
  }

  extractPageParams = () => {
    const { page, page_size, s, sort_by, status } = parsePageParams(
      this.props.location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
      status,
    }
  }

  onPageChange = (page: number) => {
    const params = this.extractPageParams()

    params.page = page

    this.updatePageParams(params)
  }

  onPageSizeChange = (pageSize: number) => {
    const params = this.extractPageParams()

    params.page_size = pageSize

    this.updatePageParams(params)
  }

  onFetch = (formatParams: any) => {
    const params = this.extractPageParams()

    const { filters } = formatParams

    if (filters && filters.status) {
      params.status = filters.status.value
    }

    if (params.s) {
      delete params.s
    }

    this.updatePageParams(params)
  }

  onSortByChange = (sortBy: string | undefined) => {
    const params = this.extractPageParams()

    params.sort_by = sortBy

    this.updatePageParams(params)
  }

  updatePageParams = (params: object) => {
    this.props.history.push({
      pathname: this.props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  toggleFilterSection = () => {
    const { filtersOpened } = this.state
    this.setState({ filtersOpened: !filtersOpened })
  }

  onFilterSelected = (
    index: number,
    selected: FilterOptionType | null | undefined
  ) => {
    this.setState({ filterSelected: { index, selected } })
  }

  render() {
    const { filtersOpened, filterSelected } = this.state
    const { page, page_size, s, status, sort_by } = this.extractPageParams()
    const pageSize = page_size || PAGE_SIZE_OPTIONS[0]
    let flag = false

    return (
      <ThemeContext.Consumer>
        {({ forceShowNotification }) => (
          <Query
            variables={{
              options: {
                limit: pageSize,
                offset: page ? (page - 1) * pageSize : 0,
                sortBy: sort_by,
              },
              filters: {
                s,
                status,
                sort_by: 'order',
              },
            }}
            query={GET_BANNERS_QUERY}
            fetchPolicy="network-only"
          >
            {({ loading, error, data, refetch }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }
              if (!data.banners) {
                return <p>No Suppliers</p>
              }

              const statusOptions: FilterOptionType[] = []
              statusOptions.push(
                {
                  label: 'Todos los estados',
                  value: 'all',
                },

                { label: 'Activo', value: 'active' },
                { label: 'Inactivo', value: 'inactive' }
              )

              const { nodes, pagination } = data.banners
              const columns = [
                // { header: 'Razon Social', key: 'legalName' },
                { header: 'Nombre', key: 'name' },
                { header: 'Prioridad', key: 'order', width: 150 },
                {
                  header: 'Estado',
                  key: 'status',
                  width: 210,
                  Cell: (banner: BannerNode) => {
                    const status = { value: banner.status, label: '' }

                    if (banner.status === 'active') {
                      status.label = 'Activo'
                    } else {
                      status.label = 'Inactivo'
                    }

                    return (
                      <Modal>
                        {({ openModal }) => {
                          return (
                            <Mutation
                              mutation={EDIT_BANNER_MUTATION}
                              onCompleted={() => {
                                refetch().finally(() => {
                                  flag = true
                                  forceShowNotification &&
                                    forceShowNotification({
                                      type: 'ok',
                                      message:
                                        'Banner actualizado correctamente',
                                    })
                                })
                              }}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(editStatusBanner, { loading: loadingEdit }) => (
                                <div>
                                  {loadingEdit || loading || flag ? (
                                    <Loading />
                                  ) : (
                                    <Select
                                      id="status"
                                      options={STATUS_OPTIONS_SUPPLIERS}
                                      value={status}
                                      onChange={s => {
                                        if (s && s['value'] != status.value) {
                                          flag = true

                                          editStatusBanner({
                                            variables: {
                                              input: {
                                                status: s && s['value'],
                                                id: banner.id,
                                              },
                                            },
                                          }).then(() => {
                                            flag = false
                                          })
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                            </Mutation>
                          )
                        }}
                      </Modal>
                    )
                  },
                },
                {
                  header: 'Acciones',
                  key: 'acciones',
                  width: 99,
                  sortable: false,
                  Cell: ({ id }: BannerNode) => (
                    <ActionsContainer>
                      <Link to={`/banners/${id}/edit`}>
                        <Tooltip id={`edit-${id}`} message="Editar">
                          <EditIcon name="pencil" />
                        </Tooltip>
                      </Link>
                    </ActionsContainer>
                  ),
                },
              ]

              return (
                <DataTable
                  indexKey="id"
                  columns={columns}
                  data={nodes}
                  loading={loading}
                  totalItemsCount={pagination.total}
                  sortBy={sort_by}
                  page={page || 1}
                  pageSize={pageSize}
                  pageSizeOptions={PAGE_SIZE_OPTIONS}
                  searchText={s}
                  onSort={this.onSortByChange}
                  onPageChange={this.onPageChange}
                  onFetch={this.onFetch}
                  onPageSizeChange={this.onPageSizeChange}
                  filters={[
                    {
                      key: 'status',
                      label: 'Estados',
                      options: statusOptions,
                    },
                  ]}
                  filtersOpened={filtersOpened}
                  toggleFilterSection={this.toggleFilterSection}
                  filterSelected={filterSelected}
                  onFilterSelected={this.onFilterSelected}
                />
              )
            }}
          </Query>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default withRouter(BannerTable)
