import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../../../config'
import DataTable from '../../../../components/DataTable'
import { parsePageParams } from '../../../../helpers/params'
import { ActionContainer, EyeIcon } from '../style'
import { Tooltip } from '../../../../components/FormElements'
import Modal from '../../../../components/Modals/ModalRoot'
import { ConfirmAction } from '../../../../components/Globals'
import ApolloClient from 'apollo-client'

const GET_MEMBERSHIP_QUERY = gql`
  query listMembership(
    $options: MembershipOptions!
    $filters: MembershipFilters!
  ) {
    membership(options: $options, filters: $filters) {
      nodes {
        id
        customerLastName
        customerFirstName
        invoicingEmail
        validationIdentification
        phone
        createdAt
        metaData
      }
      pagination {
        total
      }
    }
  }
`

type MembershipNode = Record<string, any>

interface Data {
  membership: {
    nodes: MembershipNode[]
    pagination: {
      total: number
    }
  }
}

const MembershipTable = ({ history, location }: RouteComponentProps) => {
  function updatePageParams(params: object) {
    history.push({
      pathname: history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  function extractPageParams() {
    const { page, page_size, s, sort_by } = parsePageParams(
      location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  function onPageChange(page: number) {
    const params = extractPageParams()

    params.page = page

    updatePageParams(params)
  }

  function onPageSizeChange(pageSize: number) {
    const params = extractPageParams()

    params.page_size = pageSize

    updatePageParams(params)
  }

  function onSearch(searchText: string | undefined) {
    const params = extractPageParams()

    params.s = searchText
    params.page = 1
    updatePageParams(params)
  }

  function onSortByChange(sortBy: string | undefined) {
    const params = extractPageParams()

    params.sort_by = sortBy

    updatePageParams(params)
  }

  function modalDetail(detail: any) {
    return (
      <>
        <h2>
          {detail.customerFirstName} {detail.customerLastName}
        </h2>
        <table>
          <tbody>
            <tr>
              <td>Nombre Completo: </td>
              <td>{detail.metaData && detail.metaData['nombre_completo']}</td>
            </tr>
            <tr>
              <td>Identificación: </td>
              <td>{detail.validationIdentification}</td>
            </tr>
            <tr>
              <td>Correo: </td>
              <td>{detail.invoicingEmail}</td>
            </tr>
            <tr>
              <td>Teléfono: </td>
              <td>{detail.phone}</td>
            </tr>
            <tr>
              <td>Dirección: </td>
              <td>{detail.metaData && detail.metaData['direccion']}</td>
            </tr>
            <tr>
              <td>Fecha de Nacimiento: </td>
              <td>{detail.metaData && detail.metaData['fecha_nacimiento']}</td>
            </tr>
            <tr>
              <td>Fecha de Enrolamiento: </td>
              <td>{detail.metaData && detail.metaData['mes'] || detail.createdAt}</td>
            </tr>
            <tr>
              <td>Tipo de Socio: </td>
              <td>{detail.metaData && detail.metaData['tipo_socio']}</td>
            </tr>
            <tr>
              <td>Canal: </td>
              <td>{detail.metaData && detail.metaData['canal']}</td>
            </tr>
            <tr>
              <td>Estado Actual: </td>
              <td>{detail.metaData && detail.metaData['estado_actual']}</td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  async function exportMemberships(client: ApolloClient<Data>) {
    await client
      .query<Data>({
        query: GET_MEMBERSHIP_QUERY,
        variables: {
          options: { limit: 100 },
          filters: {},
        },
      })
      .then(data => {
        if (data && data.data.membership) {
          const items = data.data.membership.nodes
          const header = Object.keys(items[0])
          const csv = [
            header.join(','), // header row first
            ...items.map(row =>
              header.map(fieldName => JSON.stringify(row[fieldName])).join(',')
            ),
          ].join('\r\n')

          var hiddenElement = document.createElement('a')
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
          hiddenElement.target = '_blank'
          hiddenElement.download =
            'Memberships_Report-' +
            new Date().toISOString().slice(0, 10) +
            '.csv'
          hiddenElement.click()
        }
      })
  }

  const { page, page_size, s, sort_by } = extractPageParams()

  const pageSize = page_size || PAGE_SIZE_OPTIONS[0]

  return (
    <Query
      variables={{
        options: {
          limit: pageSize,
          offset: page ? (page - 1) * pageSize : 0,
          sortBy: sort_by,
        },
        filters: {
          s,
        },
      }}
      query={GET_MEMBERSHIP_QUERY}
      fetchPolicy="network-only"
    >
      {({ loading, error, data, client }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }
        if (!data.membership) {
          return <p>No se encontraron socios</p>
        }

        const { nodes, pagination } = data.membership

        const columns = [
          {
            header: 'Número de identificación',
            key: 'validationIdentification',
          },
          {
            header: 'Nombre de socio',
            key: 'name',
            Cell: (Membership: MembershipNode) => {
              return (
                (Membership.metaData && Membership.metaData['nombre_completo']) || (
                Membership.customerFirstName &&
                Membership.customerLastName &&
                Membership.customerFirstName + ' ' + Membership.customerLastName )
              )
            },
          },
          {
            header: 'Email',
            key: 'email',
            Cell: (Membership: MembershipNode) => {
              return Membership.invoicingEmail
            },
          },
          {
            header: 'Teléfono',
            key: 'phone',
            Cell: (Membership: MembershipNode) => {
              return Membership.phone
            },
          },
          {
            header: 'Opciones',
            key: 'actions',
            sortable: false,
            width: 99,
            Cell: (Membership: MembershipNode) => (
              <ActionContainer>
                <Modal>
                  {({ openModal }) => {
                    return (
                      <ConfirmAction
                        onClick={() =>
                          openModal('DETAILS', {
                            header: {},
                            description: modalDetail(Membership),
                            modalSize: 'md',
                            showCloseTop: true,
                          })
                        }
                      >
                        <Tooltip
                          id={`show-${Membership.id}-transactions`}
                          message="Detalle del miembro"
                        >
                          <EyeIcon name="eye" />
                        </Tooltip>
                      </ConfirmAction>
                    )
                  }}
                </Modal>
              </ActionContainer>
            ),
          },
        ]

        return (
          <DataTable
            indexKey="id"
            columns={columns}
            data={nodes}
            loading={loading}
            totalItemsCount={pagination.total}
            sortBy={sort_by}
            page={page || 1}
            pageSize={pageSize}
            pageSizeOptions={PAGE_SIZE_OPTIONS}
            searchText={s}
            onSort={onSortByChange}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSearch={onSearch}
            placeholderSearchBar={'Buscar por cédula o correo'}
            button={{
              onClick: () => {
                exportMemberships(client)
              },
              textButton: 'Exportar datos',
            }}
          />
        )
      }}
    </Query>
  )
}

export default withRouter(MembershipTable)
