import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateCampaignForm from './components/CreateCampaignForm'
import EditCampaignForm from './components/EditCampaignForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_CAMPAIGN_QUERY = gql`
  query campaignQuery($id: ID!) {
    campaign(id: $id) {
      id
      name
      order
      status
      template: type
      url
      metadata: metaData
    }
  }
`

interface Data {
  campaign: any
}

class CampaignForm extends React.Component<RouteComponentProps<RouteParams>> {
  onCampaignSaved = () => {
    this.props.history.push('/campaigns')
  }

  render() {
    const { id, action } = this.props.match.params
    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Campaña`}
      >
        {action === 'create' ? (
          <CreateCampaignForm onCreated={this.onCampaignSaved} />
        ) : (
          <Query
            variables={{ id }}
            query={GET_CAMPAIGN_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditCampaignForm
                  campaign={data.campaign}
                  onUpdated={this.onCampaignSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(CampaignForm)
