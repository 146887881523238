import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import PageWrapper from '../../../components/PageWrapper'
import CreateCouponForm from './components/CreateCouponForm'
import EditCouponForm from './components/EditCouponForm'
//import EditCouponForm from './components/EditCouponForm'

interface RouteParams {
  code?: string
  action: string
  history: string
}

const GET_COUPON_BY_ID = gql`
  query getCouponById($code: String!) {
    coupon(code: $code) {
      id
      code
      firstName
      lastName
      expiresFrom
      expiresTo
    }
  }
`

interface Data {
  coupon: {
    id: string
    code: string
    firstName: string
    lastName: string
    expiresFrom: string
    expiresTo: string
  }
}

class CouponForm extends React.Component<RouteComponentProps<RouteParams>> {
  onCouponSaved = () => {
    this.props.history.push('/coupons')
  }

  render() {
    const { code, action } = this.props.match.params

    return (
      <PageWrapper title={`${action === 'create' ? 'Crear' : 'Editar'} Cupón`}>
        {action === 'create' ? (
          <CreateCouponForm onCreated={this.onCouponSaved} />
        ) : (
          <Query
            variables={{ code }}
            query={GET_COUPON_BY_ID}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return <p>Loading...</p>
              }
              if (error) {
                return <p>Error</p>
              }
              if (!data) {
                return <p>No Data</p>
              }
              if (!data.coupon) {
                return <p>No Items</p>
              }
              return (
                <EditCouponForm
                  coupon={data.coupon}
                  onUpdated={this.onCouponSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(CouponForm)
