import gql from 'graphql-tag'
import React from 'react'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import AssetSelector, { Asset } from '../../../../components/AssetSelector'
import Editor from '../../../../components/Editor'
import {
  CheckBox,
  Error /*CheckBox*/,
  Input,
  Select,
} from '../../../../components/FormElements'
import FormSection from '../../../../components/FormSection'
import { ActionWrapper, Spacer } from '../../../../components/Globals'
import * as Grid from '../../../../components/Grid'
import IconSelector from '../../../../components/IconSelector'
import Modal from '../../../../components/Modals/ModalRoot'
import Paper from '../../../../components/Paper'
import SupplierSelector, {
  SupplierOption,
} from '../../../../components/Selectors/SupplierSelector'
import {
  CATEGORY_ICONS_OPTIONS,
  STATUS_OPTIONS_SUPPLIERS,
} from '../../../../config'
import ThemeContext from '../../../../context/ThemeContext'
import {
  Field,
  getElements,
  initialField,
  InputElements,
  scrollTo,
  validateField,
  validateForm,
} from '../../../../helpers/validations'
import {
  SaveButton /*CheckContainer, SpecialtyTypeContainer, SpecialtyTypeLabel*/,
} from '../style'

const EDIT_SPECIALTY_MUTATION = gql`
  mutation editSpecialtyMutation($input: EditSpecialtyInput!) {
    editSpecialty(input: $input)
  }
`

const UPLOAD_BANNER_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

const DELETE_FILE = gql`
  mutation deleteFile($id: String) {
    deleteFile(id: $id)
  }
`

// interface Option {
//   id: string
//   status: string
// }

interface AssetData {
  id?: string
  url?: string
  type?: string
  platform_type?: string
  file?: string
}
interface MetaData {
  cta: CTA
}

interface CTA {
  cta_main_text: string
  cta_second_text: string
  cta_url: string
}
interface Props {
  specialty: {
    id: string
    name: string
    city: string
    status: string
    order: number
    slug: string
    url: string
    description: string
    summary: string
    icon: string
    type: string
    suppliers?: Array<string>
    assets: Array<{
      id: string
      url: string
      type: string
      platformType: string
    }>
    meta_data?: MetaData
  }
  onUpdated: () => void
}

interface State {
  name: Field
  city: Field
  order: Field
  description: Field
  longDescription: Field
  slug: Field
  url: Field
  status: ValueType<string | number | { [key: string]: any }>
  assetsDesktop: any[]
  assetsMobile: any[]
  oldAssets: Asset[]
  deleteAssets: Asset[]
  createError: boolean
  assetsStatus: boolean
  inputs: InputElements[]
  assetDesktopError: boolean
  assetMobileError: boolean
  specialtyType: string
  isInternal: boolean
  isExternal: boolean
  iconName: string
  iconError: boolean
  supplier: Field
  hasCta: boolean
  ctaMainText: Field
  ctaSecondText: Field
  ctaUrl: Field
}

type EditSpecialtyInput = {
  id: string
  name: string
  city: string
  order: number
  assets?: Array<AssetData>
  status: string
  type: string
  summary: string
  description: string
  slug: string
  icon: string
  url: string
  suppliers: Array<string>
  metadata?: MetaData
}

const inputIdsInternal = [
  'slug',
  'city',
  'order',
  'name',
  'description',
  'longDescription',
]
const inputIdsExternal = ['url', 'city', 'order', 'name', 'description']
const selectsRequiredInternal = ['status', 'supplier', 'longDescription']
const selectsRequiredExternal = ['status']

class Specialty extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const {
      name,
      city,
      order,
      status,
      assets,
      description,
      summary,
      slug,
      suppliers,
      icon,
      type,
      url,
      meta_data,
    } = props.specialty

    let desktop: AssetData[] = []
    let mobile: AssetData[] = []
    let cta: CTA | undefined = meta_data && meta_data.cta
    assets &&
      assets.forEach(asset => {
        if (asset.platformType === 'desktop') {
          const data = {
            id: asset.id,
            type: asset.type,
            url: asset.url,
            platform_type: asset.platformType,
          }
          desktop.push(data)
        } else if (asset.platformType === 'mobile') {
          const data = {
            id: asset.id,
            type: asset.type,
            url: asset.url,
            platform_type: asset.platformType,
          }
          mobile.push(data)
        }
      })

    const results = STATUS_OPTIONS_SUPPLIERS.filter(statusArray => {
      return statusArray.value === status
    })
    const statusSelected =
      results.length > 0
        ? results[0]
        : {
            value: 'inactive',
            label: 'Inactivo',
          }
    this.state = {
      name: {
        ...initialField,
        value: name,
      },
      city: {
        ...initialField,
        value: city,
      },
      order: {
        ...initialField,
        value: order,
      },
      status: { value: statusSelected.value, label: statusSelected.label },
      description: { ...initialField, value: description },
      longDescription: { ...initialField, value: summary },
      slug: { ...initialField, value: slug },
      url: { ...initialField, value: url },
      supplier: { ...initialField, value: suppliers },
      iconName: icon,
      iconError: false,
      specialtyType: type,
      isExternal: type && type === 'external' ? true : false,
      isInternal: type && type === 'internal' ? true : false,
      assetsDesktop: desktop,
      assetsMobile: mobile,
      oldAssets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.type,
          platform_type: asset.platformType,
        }
      }),
      deleteAssets: [],
      createError: false,
      assetsStatus: false,
      inputs: [],
      assetDesktopError: false,
      assetMobileError: false,
      hasCta: cta ? true : false,
      ctaMainText: {
        ...initialField,
        value: cta && cta.cta_main_text,
      },
      ctaSecondText: {
        ...initialField,
        value: cta && cta.cta_second_text,
      },
      ctaUrl: {
        ...initialField,
        value: cta && cta.cta_url,
      },
    }
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }

  handleHasCTAChange = () => {
    const { hasCta } = this.state
    this.setState({ hasCta: !hasCta })
  }

  handleCTAMainTxtChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ctaMainText = value
    const validations = validateField(ctaMainText, [
      {
        name: 'shorterThan',
        value: 40,
      },
    ])
    if (validations.value) {
      this.setState({
        ctaMainText: {
          value: ctaMainText,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ctaMainText: {
        value: ctaMainText,
        error: false,
        errorText: '',
      },
    })
  }

  handleCTASecondTextChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ctaSecondText = value
    const validations = validateField(ctaSecondText, [
      {
        name: 'shorterThan',
        value: 80,
      },
    ])
    if (validations.value) {
      this.setState({
        ctaSecondText: {
          value: ctaSecondText,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ctaSecondText: {
        value: ctaSecondText,
        error: false,
        errorText: '',
      },
    })
  }

  handleCTAUrlChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const ctaURL = value
    const validations = validateField(ctaURL, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])
    if (validations.value) {
      this.setState({
        ctaUrl: {
          value: ctaURL,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      ctaUrl: {
        value: ctaURL,
        error: false,
        errorText: '',
      },
    })
  }

  handleCityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const city = value

    const validations = validateField(city, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        city: {
          value: city,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      city: {
        value: city,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleIconChange = (selectedIcon: string) => {
    const currentIconSelected = this.state.iconName

    if (currentIconSelected === selectedIcon) {
      this.setState({ iconName: '' })
    } else {
      this.setState({ iconName: selectedIcon, iconError: false })
    }
  }

  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const description = value
    this.setState({
      description: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  handleTypeChange = (type: string) => {
    let internalValue = false
    let externalValue = false

    if (type === 'internal') {
      internalValue = true
      externalValue = false
    } else if (type === 'external') {
      internalValue = false
      externalValue = true
    }

    this.setState({
      isExternal: externalValue,
      isInternal: internalValue,
      specialtyType: type,
    })
  }

  handleLongDescriptionChange = (description: string) => {
    this.setState({
      longDescription: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  handleSupplierChange = (supplier: SupplierOption) => {
    this.setState({
      supplier: {
        value: supplier,
        error: false,
        errorText: '',
      },
    })
  }

  handleSlugChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const slug = value

    const validations = validateField(slug, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])

    if (validations.value) {
      this.setState({
        slug: {
          value: slug,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      slug: {
        value: slug,
        error: false,
        errorText: '',
      },
    })
  }

  handleUrlChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const url = value

    const validations = validateField(url, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])

    if (validations.value) {
      this.setState({
        url: {
          value: url,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      url: {
        value: url,
        error: false,
        errorText: '',
      },
    })
  }

  handleAssetsChange = (assets: any[]) => {
    let mobileAssets: any = []
    let desktopAssets: any = []
    let newMobileAssets: any = []
    let newDesktopAssets: any = []
    const { oldAssets } = this.state

    let oldAssetsDesktop: any = []
    let oldAssetsMobile: any = []

    oldAssets.forEach(oa => {
      if (oa.platform_type === 'desktop') {
        oldAssetsDesktop.push(oa)
      } else if (oa.platform_type === 'mobile') {
        oldAssetsMobile.push(oa)
      }
    })

    let deletedAssets: any = []
    assets.forEach(asset => {
      if (
        asset.platform_type === 'desktop' ||
        asset.platformType === 'desktop'
      ) {
        desktopAssets.push(asset)
        newDesktopAssets = [...desktopAssets]

        //if an old asset is deleted
        deletedAssets = oldAssetsDesktop.filter(
          (oldAsset: Asset) =>
            assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
        )
      } else if (
        asset.platform_type === 'mobile' ||
        asset.platformType === 'mobile'
      ) {
        mobileAssets.push(asset)
        newMobileAssets = [...mobileAssets]

        //if an old asset is deleted
        deletedAssets = oldAssetsMobile.filter(
          (oldAsset: Asset) =>
            assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
        )
      }
    })

    if (newDesktopAssets.length > 0) {
      this.setState({
        assetsDesktop: newDesktopAssets,
        assetsStatus: true,
        deleteAssets: deletedAssets,
        assetDesktopError: false,
      })
    }

    if (newMobileAssets.length > 0) {
      this.setState({
        assetsMobile: mobileAssets,
        assetsStatus: true,
        deleteAssets: deletedAssets,
        assetMobileError: false,
      })
    }

    // this.setState({ assets, assetsStatus: true, deleteAssets })
  }

  handleOrderChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const order = value
    this.setState({
      order: {
        value: order,
        error: false,
        errorText: '',
      },
    })
  }

  scrollToError = () => {
    const { specialtyType } = this.state
    const inputIds =
      specialtyType === 'internal' ? inputIdsInternal : inputIdsExternal
    const selectsRequired =
      specialtyType === 'internal'
        ? selectsRequiredInternal
        : selectsRequiredExternal

    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    selectsRequired.forEach(id => {
      if (this.state[id] && !this.state[id].value) {
        error = false
        errorsMapSelects[id] = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    errorsMapSelects['features'] = []

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    return error
  }

  validateAssets = () => {
    const { assetsDesktop, assetsMobile } = this.state

    let desktopValidation = false
    let mobileValidation = false

    if (assetsDesktop.length === 0) {
      desktopValidation = true
    }
    if (assetsMobile.length === 0) {
      mobileValidation = true
    }

    this.setState({
      assetDesktopError: desktopValidation,
      assetMobileError: mobileValidation,
    })

    return desktopValidation || mobileValidation
  }

  validateIcon = () => {
    const { iconName } = this.state

    let iconValidation = false

    if (iconName === '') {
      iconValidation = true
    }

    this.setState({ iconError: iconValidation })

    return iconValidation
  }

  mapElements() {
    const { specialtyType } = this.state
    const inputIds =
      specialtyType === 'internal' ? inputIdsInternal : inputIdsExternal
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      name,
      city,
      order,
      status,
      description,
      longDescription,
      slug,
      url,
      specialtyType,
      assetsMobile,
      assetsDesktop,
      assetMobileError,
      assetDesktopError,
      iconName,
      iconError,
      supplier,
      hasCta,
      ctaMainText,
      ctaSecondText,
      ctaUrl,
    } = this.state

    const { onUpdated, specialty } = this.props
    const iconList = CATEGORY_ICONS_OPTIONS

    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <FormSection title="Información General" openedByDefault={true}>
                <Paper>
                  <Grid.Row>
                    <Grid.Column md={4}>
                      <Input
                        id="name"
                        value={name.value ? name.value : ''}
                        onChange={this.handleNameChange}
                        type="text"
                        error={name.error}
                        label="Nombre"
                        required
                      />
                      {name.error ? (
                        <Error>{name.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={8}>
                      <Input
                        id="description"
                        value={description.value}
                        error={description.error}
                        onChange={this.handleDescriptionChange}
                        type="text"
                        label="Descripción corta"
                        required
                      />
                      {description.error ? (
                        <Error>{description.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column md={4}>
                      <Select
                        id="status"
                        label="Estado"
                        placeholder="Seleccione un estado"
                        required
                        options={STATUS_OPTIONS_SUPPLIERS}
                        value={status}
                        onChange={this.handleStatusChange}
                        backspaceRemovesValue={false}
                      />
                    </Grid.Column>
                    <Grid.Column md={2}>
                      <Input
                        id="order"
                        value={order.value ? order.value : 0}
                        onChange={this.handleOrderChange}
                        type="number"
                        label="Prioridad"
                      />
                    </Grid.Column>
                    <Grid.Column md={4}>
                      <Input
                        id="city"
                        value={city.value ? city.value : ''}
                        onChange={this.handleCityChange}
                        type="text"
                        error={city.error}
                        label="Ciudad"
                        required
                      />
                      {city.error ? (
                        <Error>{city.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column md={4}>
                      <CheckBox
                        id="hasCTA"
                        checked={hasCta}
                        label="CTA"
                        onChange={this.handleHasCTAChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {hasCta && (
                    <>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Input
                            id="cta_main_text"
                            value={ctaMainText ? ctaMainText.value : ''}
                            required
                            type="text"
                            error={ctaMainText ? ctaMainText.error : false}
                            label="Texto Principal"
                            onChange={this.handleCTAMainTxtChange}
                          />
                          {ctaMainText && ctaMainText.error ? (
                            <Error>{ctaMainText.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={6}>
                          <Input
                            id="cta_second_text"
                            value={ctaSecondText ? ctaSecondText.value : ''}
                            required
                            type="text"
                            error={ctaSecondText ? ctaSecondText.error : false}
                            label="Texto Secundiario"
                            onChange={this.handleCTASecondTextChange}
                          />
                          {ctaSecondText && ctaSecondText.error ? (
                            <Error>{ctaSecondText.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={12}>
                          <Input
                            id="cta_url"
                            value={ctaUrl ? ctaUrl.value : ''}
                            required
                            type="text"
                            error={ctaUrl ? ctaUrl.error : false}
                            label="URL Redirección"
                            onChange={this.handleCTAUrlChange}
                          />
                          {ctaUrl && ctaUrl.error ? (
                            <Error>{ctaUrl.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                  {specialtyType === 'internal' ? (
                    <>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={12}>
                          <Editor
                            id={'longDescription'}
                            label="Descripción Larga"
                            value={longDescription.value}
                            onChange={this.handleLongDescriptionChange}
                            required
                          />
                          {longDescription.error ? (
                            <Error>{longDescription.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={12}>
                          <Input
                            id="slug"
                            value={slug.value}
                            error={slug.error}
                            onChange={this.handleSlugChange}
                            type="text"
                            label="Slug"
                            required
                          />
                          {slug.error ? (
                            <Error>{slug.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={12}>
                          <SupplierSelector
                            value={supplier.value}
                            onChange={this.handleSupplierChange}
                            isRequired={true}
                            error={supplier.error}
                            isMulti={true}
                          />
                          {supplier.error ? (
                            <Error>{supplier.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <IconSelector
                            iconSelected={iconName}
                            iconList={iconList}
                            onChangeIcon={this.handleIconChange}
                            hasParent={true}
                          />
                          {iconError ? (
                            <Error>Debe seleccionar un icono.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  ) : specialtyType === 'external' ? (
                    <>
                      <Spacer />
                      <Grid.Row>
                        <Grid.Column md={12}>
                          <Input
                            id="url"
                            value={url.value}
                            error={url.error}
                            onChange={this.handleUrlChange}
                            type="text"
                            label="URL"
                            required
                          />
                          {url.error ? (
                            <Error>{url.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <IconSelector
                            iconSelected={iconName}
                            iconList={iconList}
                            onChangeIcon={this.handleIconChange}
                            hasParent={true}
                          />
                          {iconError ? (
                            <Error>Debe seleccionar un icono.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  ) : (
                    ''
                  )}
                </Paper>
              </FormSection>
              {specialtyType === 'internal' ? (
                <>
                  <FormSection title={'Carga de imagen para versión desktop'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg,image/png"
                            assets={assetsDesktop}
                            onChange={this.handleAssetsChange}
                            platformType="desktop"
                          />
                          {assetDesktopError ? (
                            <Error>Debe seleccionar un archivo.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <FormSection title={'Carga de imagen para versión mobile'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg,image/png"
                            assets={assetsMobile}
                            onChange={this.handleAssetsChange}
                            platformType="mobile"
                          />
                          {assetMobileError ? (
                            <Error>Debe seleccionar un archivo.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>
                </>
              ) : specialtyType === 'external' ? (
                ''
              ) : (
                ''
              )}

              {
                <ThemeContext.Consumer>
                  {notify => (
                    <Mutation
                      mutation={EDIT_SPECIALTY_MUTATION}
                      onCompleted={() => {
                        onUpdated()
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'ok',
                            message: 'Especialidad guardada correctamente',
                          })
                      }}
                      onError={() => {
                        openModal('ALERT', {
                          header: {
                            title: 'ALERTA',
                          },
                          description:
                            'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                          type: 'fail',
                        })
                      }}
                    >
                      {(editSpecialty, { loading, error }) => (
                        <Mutation
                          mutation={DELETE_FILE}
                          onError={() => {
                            openModal('ALERT', {
                              header: {
                                title: 'ALERTA',
                              },
                              description:
                                'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                              type: 'fail',
                            })
                          }}
                        >
                          {(deleteFile, { loading: loadingDelete }) => (
                            <Mutation
                              mutation={UPLOAD_BANNER_FILE}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(
                                uploadSupplierFile,
                                { loading: loadingUpdate }
                              ) => (
                                <ActionWrapper>
                                  <SaveButton
                                    color="primary"
                                    disabled={
                                      loading || loadingDelete || loadingUpdate
                                    }
                                    onClick={async () => {
                                      let nextError = this.scrollToError()
                                      let assetsError = this.validateAssets()
                                      let errorIcon = this.validateIcon()

                                      specialtyType == 'external'
                                        ? (assetsError = false)
                                        : ''
                                      if (!assetsError && !errorIcon) {
                                        if (nextError) {
                                          const assets = assetsMobile.concat(
                                            assetsDesktop
                                          )
                                          let suppliersIDs = []
                                          let summary = longDescription.value

                                          if (specialtyType === 'internal') {
                                            suppliersIDs = supplier.value.map(
                                              (sp: any) => {
                                                return sp.id
                                              }
                                            )
                                          } else {
                                            summary = description.value
                                          }

                                          const specialtyChanges: EditSpecialtyInput = {
                                            id: specialty.id,
                                            name: name.value,
                                            city: city.value,
                                            status: status && status['value'],
                                            order: Number(order.value),
                                            slug: slug.value,
                                            description: description.value,
                                            summary: summary,
                                            suppliers: suppliersIDs,
                                            icon: iconName,
                                            url: url.value,
                                            type: specialtyType,
                                            metadata: hasCta
                                              ? {
                                                  cta: {
                                                    cta_main_text:
                                                      ctaMainText.value,
                                                    cta_second_text:
                                                      ctaSecondText.value,
                                                    cta_url: ctaUrl.value,
                                                  },
                                                }
                                              : undefined,
                                          }
                                          specialtyChanges.assets = assets

                                          const uploadedImages: Asset[] = []
                                          const videos: Asset[] = []
                                          if (this.state.assetsStatus) {
                                            try {
                                              for (const deleteAsset of this
                                                .state.deleteAssets) {
                                                if (
                                                  deleteAsset.type !== 'video'
                                                ) {
                                                  const fileID = deleteAsset.url.substring(
                                                    deleteAsset.url.lastIndexOf(
                                                      '/'
                                                    ) + 1
                                                  )
                                                  await deleteFile({
                                                    variables: {
                                                      id: fileID,
                                                    },
                                                  })
                                                }
                                              }
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error deleting file',
                                                error
                                              )
                                              return
                                            }

                                            try {
                                              for (const asset of assets) {
                                                if (asset.type === 'video') {
                                                  videos.push({
                                                    id: asset.id,
                                                    url: asset.url,
                                                    type: 'video',
                                                  })
                                                } else {
                                                  if (asset.file != null) {
                                                    const response = await uploadSupplierFile(
                                                      {
                                                        variables: {
                                                          file: asset.file,
                                                        },
                                                      }
                                                    )

                                                    if (response) {
                                                      const {
                                                        url,
                                                      } = response.data.uploadSupplierFile

                                                      uploadedImages.push({
                                                        id: '',
                                                        url,
                                                        type: 'image',
                                                        platform_type: asset.platform_type
                                                          ? asset.platform_type
                                                          : asset.platformType,
                                                      })
                                                    }
                                                  } else {
                                                    uploadedImages.push({
                                                      id: asset.id,
                                                      url: asset.url,
                                                      type: asset.type,
                                                      platform_type: asset.platform_type
                                                        ? asset.platform_type
                                                        : asset.platformType,
                                                    })
                                                  }
                                                }
                                              }

                                              // deleteFile
                                              specialtyChanges.assets = [
                                                ...uploadedImages,
                                                ...videos,
                                              ].map((asset: any) => ({
                                                url: asset.url,
                                                type: asset.type,
                                                platform_type: asset.platform_type
                                                  ? asset.platform_type
                                                  : asset.platformType,
                                              }))
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error uploading file',
                                                error
                                              )
                                              return
                                            }
                                          } else {
                                            specialtyChanges.assets = assets
                                          }

                                          editSpecialty({
                                            variables: {
                                              input: specialtyChanges,
                                            },
                                          })
                                        }
                                      }
                                    }}
                                  >
                                    {loading
                                      ? 'Guardando cambios...'
                                      : 'Guardar cambios'}
                                  </SaveButton>
                                </ActionWrapper>
                              )}
                            </Mutation>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </ThemeContext.Consumer>
              }
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default Specialty
