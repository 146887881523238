import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import { createClient } from './client'
import Routes from './routes'
import theme from './styles/theme'

import ThemeContext, { Login } from './context/ThemeContext'
import Notification from './components/Notification'
import { AppContext } from './context/AppContext'

type Notifications = {
  type: string
  message: string
}

const childRef = React.createRef<Notification>()
function App() {
  const client = createClient()
  const [notification, setNotification] = useState<Notifications>({
    type: '',
    message: '',
  })

  const { token } = JSON.parse(
    window.localStorage.getItem('sessionStorage') || '{}'
  )

  const [login, setLogin] = useState<Login>({ token: token })

  const [title, setTitle] = useState('')

  const onSetLogin = (login: Login) => {
    setLogin(login)
  }

  const onSetTitle = (title: string) => {
    setTitle(title)
  }

  const onSetNotification = (notification: Notifications) => {
    setNotification(notification)
  }

  const forceShowNotification = (notification: Notifications) => {
    childRef.current &&
      childRef.current.notify(notification.type, notification.message)
  }

  return (
    <ApolloProvider client={client}>
      <ApolloHooksProvider client={client}>
        <AppContext>
          <ThemeProvider theme={theme}>
            <ThemeContext.Provider
              value={{
                client,
                notification,
                login,
                title,
                onSetTitle,
                onSetLogin,
                onSetNotification,
                forceShowNotification,
              }}
            >
              <Notification ref={childRef}>
                {({ notify }) => {
                  notification &&
                    notification.type != '' &&
                    notify(notification.type, notification.message)
                  return <></>
                }}
              </Notification>
              <Routes />
            </ThemeContext.Provider>
          </ThemeProvider>
        </AppContext>
      </ApolloHooksProvider>
    </ApolloProvider>
  )
}

export default App
