import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreatePromotionForm from './components/CreatePromotionForm'
import EditPromotionForm from './components/EditPromotionForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_PROMOTION_QUERY = gql`
  query getPromotionQuery($id: ID!) {
    promotion(id: $id) {
      id
      name
      city
      order
      status
      summary
      url
      assets {
        id
        url
        type
      }
    }
  }
`
// interface Option {
//   id: string
//   status: string
// }
interface Data {
  promotion: {
    id: string
    name: string
    city: string
    status: string
    order: number
    summary: string
    url: string
    assets: Array<{
      id: string
      url: string
      type: string
    }>
  }
}

class Specialties extends React.Component<RouteComponentProps<RouteParams>> {
  onPromotionSaved = () => {
    this.props.history.push('/promotions')
  }
  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Promoción`}
      >
        {action === 'create' && (
          <CreatePromotionForm onCreated={this.onPromotionSaved} />
        )}
        {action === 'edit' && (
          <Query
            variables={{ id }}
            query={GET_PROMOTION_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditPromotionForm
                  promotion={data.promotion}
                  onUpdated={this.onPromotionSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(Specialties)
