import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import Modal from '../../../../components/Modals/ModalRoot'
import { STATUS_OPTIONS_SUPPLIERS } from '../../../../config'
import { SaveButton } from '../style'
import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import AssetSelector, { Asset } from '../../../../components/AssetSelector'
import { Input, Select, Error } from '../../../../components/FormElements'

import ThemeContext from '../../../../context/ThemeContext'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'

const EDIT_BANNER_MUTATION = gql`
  mutation editBannerMutation($input: EditBannerInput!) {
    editBanner(input: $input)
  }
`

const UPLOAD_BANNER_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

const DELETE_FILE = gql`
  mutation deleteFile($id: String) {
    deleteFile(id: $id)
  }
`

interface AssetData {
  id?: string
  url?: string
  type?: string
  platform_type?: string
  file?: string
}
interface Props {
  banner: {
    id: string
    name: string
    status: string
    order: number
    button_text: string
    button_url: string
    banner_info: string
    assets: Array<{
      id: string
      url: string
      type: string
      platformType: string
    }>
  }
  onUpdated: () => void
}

interface State {
  name: Field
  order: Field
  status: ValueType<string | number | { [key: string]: any }>
  assetsDesktop: any[]
  assetsMobile: any[]
  oldAssets: Asset[]
  deleteAssets: Asset[]
  createError: boolean
  assetsStatus: boolean
  inputs: InputElements[]
  assetDesktopError: boolean
  assetMobileError: boolean
  button_text: string
  button_url: string
  banner_info: string
}

type EditBannerInput = {
  id: string
  name: string
  order: number
  assets?: Array<AssetData>
  status: string
  buttonText?: string
  buttonURL?: string
  bannerInfo?: string
}

const inputIds = ['status', 'order', 'name', 'buttonText', 'buttonURL', 'bannerInfo']

class Banner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { name, order, status, assets, button_text, button_url, banner_info } = props.banner

    let desktop: AssetData[] = []
    let mobile: AssetData[] = []

    assets.forEach(asset => {
      if (asset.platformType === 'desktop') {
        desktop.push({
          id: asset.id,
          type: asset.type,
          url: asset.url,
          platform_type: asset.platformType,
        })
      } else if (asset.platformType === 'mobile') {
        mobile.push({
          id: asset.id,
          type: asset.type,
          url: asset.url,
          platform_type: asset.platformType,
        })
      }
    })

    const results = STATUS_OPTIONS_SUPPLIERS.filter(statusArray => {
      return statusArray.value === status
    })
    const statusSelected =
      results.length > 0
        ? results[0]
        : {
            value: 'inactive',
            label: 'Inactivo',
          }
    this.state = {
      name: {
        ...initialField,
        value: name,
      },
      order: {
        ...initialField,
        value: order,
      },
      status: { value: statusSelected.value, label: statusSelected.label },
      assetsDesktop: desktop,
      assetsMobile: mobile,
      oldAssets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.type,
        }
      }),
      deleteAssets: [],
      createError: false,
      assetsStatus: false,
      inputs: [],
      assetDesktopError: false,
      assetMobileError: false,
      button_text: button_text,
      button_url: button_url,
      banner_info: banner_info,
    }
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }

  handleButtonChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const button_text = value
    this.setState({ button_text })
  }

  handleButtonURLChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const button_url = value
    this.setState({ button_url })
  }

  handleInfoChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const banner_info = value
    this.setState({ banner_info })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleAssetsChange = (assets: any[]) => {
    let mobileAssets: any = []
    let desktopAssets: any = []
    let newMobileAssets: any = []
    let newDesktopAssets: any = []
    const { oldAssets } = this.state

    let oldAssetsDesktop: any = []
    let oldAssetsMobile: any = []

    oldAssets.forEach(oa => {
      if (oa.platform_type === 'desktop') {
        oldAssetsDesktop.push(oa)
      } else if (oa.platform_type === 'mobile') {
        oldAssetsMobile.push(oa)
      }
    })

    let deletedAssets: any = []
    assets.forEach(asset => {
      if (
        asset.platform_type === 'desktop' ||
        asset.platformType === 'desktop'
      ) {
        desktopAssets.push(asset)
        newDesktopAssets = [...desktopAssets]

        //if an old asset is deleted
        deletedAssets = oldAssetsDesktop.filter(
          (oldAsset: Asset) =>
            assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
        )
      } else if (
        asset.platform_type === 'mobile' ||
        asset.platformType === 'mobile'
      ) {
        mobileAssets.push(asset)
        newMobileAssets = [...mobileAssets]

        //if an old asset is deleted
        deletedAssets = oldAssetsMobile.filter(
          (oldAsset: Asset) =>
            assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
        )
      }
    })

    if (newDesktopAssets.length > 0) {
      this.setState({
        assetsDesktop: newDesktopAssets,
        assetsStatus: true,
        deleteAssets: deletedAssets,
        assetDesktopError: false,
      })
    }

    if (newMobileAssets.length > 0) {
      this.setState({
        assetsMobile: mobileAssets,
        assetsStatus: true,
        deleteAssets: deletedAssets,
        assetMobileError: false,
      })
    }

    // this.setState({ assets, assetsStatus: true, deleteAssets })
  }

  handleOrderChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const order = value
    this.setState({
      order: {
        value: order,
        error: false,
        errorText: '',
      },
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    return error
  }

  validateAssets = () => {
    const { assetsDesktop, assetsMobile } = this.state

    let desktopValidation = false
    let mobileValidation = false

    if (assetsDesktop.length === 0) {
      desktopValidation = true
    }
    if (assetsMobile.length === 0) {
      mobileValidation = true
    }

    this.setState({
      assetDesktopError: desktopValidation,
      assetMobileError: mobileValidation,
    })

    return desktopValidation || mobileValidation
  }

  mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      name,
      order,
      status,
      assetsMobile,
      assetsDesktop,
      assetMobileError,
      assetDesktopError,
      button_text,
      button_url,
      banner_info,
    } = this.state

    const { onUpdated, banner } = this.props

    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <FormSection title="Información General" openedByDefault={true}>
                <Paper>
                  <Grid.Row>
                    <Grid.Column md={6}>
                      <Input
                        id="name"
                        value={name.value ? name.value : ''}
                        onChange={this.handleNameChange}
                        type="text"
                        error={name.error}
                        label="Nombre"
                        required
                      />
                      {name.error ? (
                        <Error>{name.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={6}>
                      <Select
                        id="status"
                        label="Estado"
                        placeholder="Seleccione un estado"
                        required
                        options={STATUS_OPTIONS_SUPPLIERS}
                        value={status}
                        onChange={this.handleStatusChange}
                        backspaceRemovesValue={false}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column md={2}>
                      <Input
                        id="order"
                        value={order.value ? order.value : 0}
                        onChange={this.handleOrderChange}
                        type="number"
                        label="Prioridad"
                      />
                    </Grid.Column>
                    <Grid.Column md={4}>
                      <Input
                        id="buttonText"
                        value={button_text ? button_text : ''}
                        onChange={this.handleButtonChange}
                        type="text"
                        label="Texto del botón"
                      />
                    </Grid.Column>
                    <Grid.Column md={6}>
                      <Input
                        id="buttonURL"
                        value={button_url ? button_url : ''}
                        onChange={this.handleButtonURLChange}
                        type="text"
                        label="Link del botón"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <br />
                  <Grid.Row>
                    <Grid.Column md={12}>
                      <Input
                        id="bannerInfo"
                        value={banner_info ? banner_info : ''}
                        onChange={this.handleInfoChange}
                        type="text"
                        label="Texto del banner"
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Paper>
              </FormSection>

              <FormSection title={'Carga de imagen para versión desktop'}>
                <Grid.Row>
                  <Grid.Column>
                    <Paper>
                      <AssetSelector
                        dropZoneSupport
                        colorButton="secondary"
                        textButton="Seleccionar archivos"
                        accept="image/gif,image/jpeg,image/png"
                        assets={assetsDesktop}
                        onChange={this.handleAssetsChange}
                        platformType="desktop"
                      />
                      {assetDesktopError ? (
                        <Error>Debe seleccionar un archivo.</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Paper>
                  </Grid.Column>
                </Grid.Row>
              </FormSection>

              <FormSection title={'Carga de imagen para versión mobile'}>
                <Grid.Row>
                  <Grid.Column>
                    <Paper>
                      <AssetSelector
                        dropZoneSupport
                        colorButton="secondary"
                        textButton="Seleccionar archivos"
                        accept="image/gif,image/jpeg,image/png"
                        assets={assetsMobile}
                        onChange={this.handleAssetsChange}
                        platformType="mobile"
                      />
                      {assetMobileError ? (
                        <Error>Debe seleccionar un archivo.</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Paper>
                  </Grid.Column>
                </Grid.Row>
              </FormSection>
              {
                <ThemeContext.Consumer>
                  {notify => (
                    <Mutation
                      mutation={EDIT_BANNER_MUTATION}
                      onCompleted={() => {
                        onUpdated()
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'ok',
                            message: 'Banner guardado correctamente',
                          })
                      }}
                      onError={() => {
                        openModal('ALERT', {
                          header: {
                            title: 'ALERTA',
                          },
                          description:
                            'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                          type: 'fail',
                        })
                      }}
                    >
                      {(editBanner, { loading, error }) => (
                        <Mutation
                          mutation={DELETE_FILE}
                          onError={() => {
                            openModal('ALERT', {
                              header: {
                                title: 'ALERTA',
                              },
                              description:
                                'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                              type: 'fail',
                            })
                          }}
                        >
                          {(deleteFile, { loading: loadingDelete }) => (
                            <Mutation
                              mutation={UPLOAD_BANNER_FILE}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(
                                uploadSupplierFile,
                                { loading: loadingUpdate }
                              ) => (
                                <ActionWrapper>
                                  <SaveButton
                                    color="primary"
                                    disabled={
                                      loading || loadingDelete || loadingUpdate
                                    }
                                    onClick={async () => {
                                      let nextError = this.scrollToError()
                                      let assetsError = this.validateAssets()

                                      if (!assetsError) {
                                        if (nextError) {
                                          const assets = assetsMobile.concat(
                                            assetsDesktop
                                          )
                                          const bannerChanges: EditBannerInput = {
                                            id: banner.id,
                                            name: name.value,
                                            status: status && status['value'],
                                            order: Number(order.value),
                                            buttonText: button_text,
                                            buttonURL: button_url,
                                            bannerInfo: banner_info,
                                          }
                                          bannerChanges.assets = assets
                                          const uploadedImages: Asset[] = []
                                          const videos: Asset[] = []
                                          if (this.state.assetsStatus) {
                                            try {
                                              for (const deleteAsset of this
                                                .state.deleteAssets) {
                                                if (
                                                  deleteAsset.type !== 'video'
                                                ) {
                                                  const fileID = deleteAsset.url.substring(
                                                    deleteAsset.url.lastIndexOf(
                                                      '/'
                                                    ) + 1
                                                  )
                                                  await deleteFile({
                                                    variables: {
                                                      id: fileID,
                                                    },
                                                  })
                                                }
                                              }
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error deleting file',
                                                error
                                              )
                                              return
                                            }

                                            try {
                                              for (const asset of assets) {
                                                if (asset.type === 'video') {
                                                  videos.push({
                                                    id: asset.id,
                                                    url: asset.url,
                                                    type: 'video',
                                                  })
                                                } else {
                                                  if (asset.file != null) {
                                                    const response = await uploadSupplierFile(
                                                      {
                                                        variables: {
                                                          file: asset.file,
                                                        },
                                                      }
                                                    )

                                                    if (response) {
                                                      const {
                                                        url,
                                                      } = response.data.uploadSupplierFile

                                                      uploadedImages.push({
                                                        id: '',
                                                        url,
                                                        type: 'image',
                                                        platform_type: asset.platform_type
                                                          ? asset.platform_type
                                                          : asset.platformType,
                                                      })
                                                    }
                                                  } else {
                                                    uploadedImages.push({
                                                      id: asset.id,
                                                      url: asset.url,
                                                      type: asset.type,
                                                      platform_type: asset.platform_type
                                                        ? asset.platform_type
                                                        : asset.platformType,
                                                    })
                                                  }
                                                }
                                              }

                                              // deleteFile
                                              bannerChanges.assets = [
                                                ...uploadedImages,
                                                ...videos,
                                              ].map((asset: any) => ({
                                                url: asset.url,
                                                type: asset.type,
                                                platform_type: asset.platform_type
                                                  ? asset.platform_type
                                                  : asset.platformType,
                                              }))
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error uploading file',
                                                error
                                              )
                                              return
                                            }
                                          } else {
                                            bannerChanges.assets = assets
                                          }
                                          editBanner({
                                            variables: {
                                              input: bannerChanges,
                                            },
                                          })
                                        }
                                      }
                                    }}
                                  >
                                    {loading
                                      ? 'Guardando cambios...'
                                      : 'Guardar cambios'}
                                  </SaveButton>
                                </ActionWrapper>
                              )}
                            </Mutation>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </ThemeContext.Consumer>
              }
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default Banner
