import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import Modal from '../../../../components/Modals/ModalRoot'
import { STATUS_OPTIONS } from '../../../../config'
import { AddFeatureText, AddFeatureWrapper, SaveButton } from '../style'
import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import FeatureGroup from '../components/FeatureGroup'
import BrandSelector, { BrandOption } from '../components/BrandSelector'
import SupplierSelector, {
  SupplierOption,
} from '../../../../components/Selectors/SupplierSelector'
import CategorySelector, {
  CategoryOption,
} from '../../../../components/Selectors/CategorySelector'
import AssetSelector, { Asset } from '../../../../components/AssetSelector'
import { Input, Select, Error } from '../../../../components/FormElements'

import Editor from '../../../../components/Editor'

import ThemeContext from '../../../../context/ThemeContext'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'
import { IconButton } from '../../../../components/Buttons'
import MetadataGroup from '../components/MetadataGroup'

const EDIT_PRODUCT_MUTATION = gql`
  mutation editProductMutation($input: EditProductInput!) {
    editProduct(input: $input)
  }
`

const UPLOAD_PRODUCT_FILE = gql`
  mutation uploadProductFile($file: Upload!) {
    uploadProductFile(file: $file) {
      url
      fileName
    }
  }
`

const DELETE_FILE = gql`
  mutation deleteFile($id: String) {
    deleteFile(id: $id)
  }
`

interface Option {
  id: string
  name: string
}

interface Metadata {
  key: Field
  value: Field
}

interface Props {
  product: {
    id: string
    name: string
    summary: string
    description: string
    priority: string
    categories: CategoryOption[]
    brand: BrandOption
    status: string
    supplier: SupplierOption
    features: Array<{
      id: string
      name: string
      options: Option[]
    }>
    assets: Array<{
      id: string
      url: string
      type: string
      order: number
    }>
    metaData: any
  }
  onUpdated: () => void
}

interface State {
  name: Field
  summary: Field
  description: Field
  priority: Field
  brand: Field
  supplier: Field
  status: ValueType<string | number | { [key: string]: any }>
  categories: Field
  features: Array<{
    id: string
    name: Field
    options: Field
  }>
  oldFeatures?: Array<{
    name: string
    options: string[]
  }>
  assets: Asset[]
  oldAssets: Asset[]
  deleteAssets: Asset[]
  createError: boolean
  assetsStatus: boolean
  inputs: InputElements[]
  metaData: any
  metaDataArray: Metadata[]
}

type EditProductInput = {
  id: string
  name: string
  summary: string
  description: string
  brandId: string
  categories: string[]
  priority: number
  features: Array<{
    id: string
    name: string
    options: string[]
  }>
  assets: Array<Asset>
  supplierId: string
  status: string
  metaData: any
}

const inputIds = [
  'description',
  'status',
  'categories',
  'supplier',
  'brand',
  'summary',
  'name',
]
const selectsRequired = ['description', 'categories']

class Product extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const {
      name,
      summary,
      description,
      priority,
      categories,
      brand,
      features,
      supplier,
      status,
      assets,
      metaData,
    } = props.product

    const results = STATUS_OPTIONS.filter(statusArray => {
      return statusArray.value === status
    })
    const statusSelected =
      results.length > 0
        ? results[0]
        : {
            value: 'inactive',
            label: 'Inactivo',
          }

    this.state = {
      name: {
        ...initialField,
        value: name,
      },
      summary: {
        ...initialField,
        value: summary,
      },
      description: {
        ...initialField,
        value: description,
      },
      priority: {
        ...initialField,
        value: priority,
      },
      brand: {
        ...initialField,
        value: brand,
      },
      supplier: {
        ...initialField,
        value: supplier,
      },

      status: { value: statusSelected.value, label: statusSelected.label },
      categories: {
        ...initialField,
        value: categories,
      },
      features: features.map(feature => {
        return {
          id: feature.id,
          name: { value: feature.name, error: false, errorText: '' },
          options: { value: [], error: false, errorText: '' },
        }
      }),
      oldFeatures: features.map(feature => {
        return {
          name: feature.name,
          options: feature.options.map((option: any) => {
            return option.name
          }),
        }
      }),
      assets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.type,
          order: asset.order,
        }
      }),
      oldAssets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.type,
          order: asset.order,
        }
      }),
      deleteAssets: [],
      createError: false,
      assetsStatus: false,
      inputs: [],
      metaData: metaData,
      metaDataArray: metaData
        ? Object.keys(metaData).map(item => {
            return {
              key: { ...initialField, value: item },
              value: { ...initialField, value: metaData[item] },
            }
          })
        : [],
    }
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }

  handleSummaryChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const summary = value

    const validations = validateField(summary, [
      {
        name: 'shorterThan',
        value: 1200,
      },
    ])

    if (validations.value) {
      this.setState({
        summary: {
          value: summary,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      summary: {
        value: summary,
        error: false,
        errorText: '',
      },
    })
  }

  handleBrandChange = (brand: BrandOption) => {
    this.setState({
      brand: {
        value: brand,
        error: false,
        errorText: '',
      },
    })
  }

  handleSupplierChange = (supplier: SupplierOption) => {
    this.setState({
      supplier: {
        value: supplier,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleCategoriesChange = (categories: CategoryOption) => {
    if (categories && categories.length === 0) {
      this.setState({
        categories: {
          value: null,
          error: true,
          errorText: 'Campo requerido',
        },
      })
      return
    }

    this.setState({
      categories: {
        value: categories,
        error: false,
        errorText: '',
      },
    })
  }

  onFeatureAddClick = () => {
    const { features } = this.state

    features.push({
      id: '',
      name: initialField,
      options: initialField,
    })

    this.setState({ features })
  }

  onFeatureRemoveClick = (index: number) => {
    const { features } = this.state

    const result = [...features.slice(0, index), ...features.slice(index + 1)]

    this.setState({ features: result })
  }

  handleFeatureNameChange = (featureIndex: number, featureName: string) => {
    const { features } = this.state

    features[featureIndex].name.value = featureName
    features[featureIndex].name.error = false

    if (featureName.length > 40) {
      features[featureIndex].name.value = featureName
      features[featureIndex].name.error = true
      features[featureIndex].name.errorText =
        'La longitud máxima debe ser de 40 caracteres.'
    }

    this.setState({ features })
  }

  handleOptionsChange = (featureIndex: number, options: string[]) => {
    const { features, oldFeatures } = this.state

    features[featureIndex].options.value = options
    features[featureIndex].options.error = false

    if (
      features[featureIndex].options.value.length == 0 &&
      oldFeatures &&
      oldFeatures[featureIndex]
    ) {
      features[featureIndex].options.error = true
      features[featureIndex].options.errorText = 'Agregue al menos una opción'
    }

    this.setState({ features })
  }

  handleNewOptionCreate = (featureIndex: number, newOption: string) => {
    const { features, oldFeatures } = this.state
    let flagError = false

    features[featureIndex].options.error = false

    oldFeatures &&
      oldFeatures[featureIndex].options.forEach(option => {
        if (option.toLowerCase().trim() === newOption.toLowerCase().trim()) {
          flagError = true
          return
        }
      })

    if (flagError) {
      features[featureIndex].options.error = true
      features[featureIndex].options.errorText =
        'No pueden existir opciones repetidas'
      this.setState({ features })
      return
    }

    features[featureIndex].options.value = [
      ...features[featureIndex].options.value,
      newOption,
    ]

    this.setState({ features })
  }

  handleAssetsChange = (assets: Asset[]) => {
    const { oldAssets } = this.state
    const deleteAssets = oldAssets.filter(
      (oldAsset: Asset) =>
        assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
    )
    this.setState({ assets, assetsStatus: true, deleteAssets })
  }

  handlePriorityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const priority = value
    this.setState({
      priority: {
        value: priority,
        error: false,
        errorText: '',
      },
    })
  }

  handleDescriptionChange = (description: string) => {
    this.setState({
      description: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  validateMetadata = () => {
    this.setState({
      metaDataArray: this.state.metaDataArray.map(item => {
        if (item.key && item.key.value.trim() == '') {
          item.key.error = true
          item.key.errorText = 'Campo Requerido'
        }
        if (item.value && item.value.value.trim() == '') {
          item.value.error = true
          item.value.errorText = 'Campo Requerido'
        }
        return item
      }),
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    selectsRequired.forEach(id => {
      if (this.state[id] && !this.state[id].value) {
        error = false
        errorsMapSelects[id] = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    this.state.metaDataArray.map((item, index) => {
      if ((item.key && item.key.error) || (item.value && item.value.error)) {
        const element = document.getElementById('key-' + index)
        if (element) {
          scrollTo(element)
        }
        error = false
      }
    })

    return error
  }

  mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  addItemToMetaData = () => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux.push({
      key: { ...initialField },
      value: { ...initialField },
    })
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  removeMetadata = (index: number) => {
    const metaDataArrayAux = this.state.metaDataArray
    const result = [
      ...metaDataArrayAux.slice(0, index),
      ...metaDataArrayAux.slice(index + 1),
    ]
    this.generateMetadataJson(result)
    this.setState({ metaDataArray: result })
  }

  handleKeyMetadata = (index: number, key: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].key = {
      value: key,
      error: key.trim() == '' ? true : false,
      errorText: key.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  handleValueMetadata = (index: number, value: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].value = {
      value: value,
      error: value.trim() == '' ? true : false,
      errorText: value.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  generateMetadataJson = (metadataArray: Metadata[]) => {
    let metadataJson = {}
    metadataArray.forEach(item => {
      metadataJson[item.key.value] = item.value.value
    })
    this.setState({ metaData: JSON.parse(JSON.stringify(metadataJson)) })
  }

  render() {
    const {
      name,
      summary,
      description,
      priority,
      brand,
      supplier,
      status,
      categories,
      features,
      assets,
      oldFeatures,
      metaData,
      metaDataArray,
    } = this.state
    const { onUpdated, product } = this.props

    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <FormSection title="Información General" openedByDefault={true}>
                <Paper>
                  <Grid.Row>
                    <Grid.Column md={6}>
                      <Input
                        id="name"
                        value={name.value ? name.value : ''}
                        onChange={this.handleNameChange}
                        type="text"
                        error={name.error}
                        label="Nombre"
                        required
                      />
                      {name.error ? (
                        <Error>{name.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={6}>
                      <Input
                        id="summary"
                        value={summary.value ? summary.value : ''}
                        onChange={this.handleSummaryChange}
                        type="text"
                        error={summary.error}
                        label="Descripción corta"
                      />
                      {summary.error ? (
                        <Error>
                          La longitud máxima debe ser de 500 caracteres.
                        </Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column md={6}>
                      <BrandSelector
                        value={brand.value}
                        onChange={this.handleBrandChange}
                        error={brand.error}
                        isRequired={false}
                        disabled
                      />
                      {brand.error ? (
                        <Error>{brand.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={6}>
                      <SupplierSelector
                        value={supplier.value}
                        error={supplier.error}
                        onChange={this.handleSupplierChange}
                        isRequired={false}
                        disabled
                      />
                      {supplier.error ? (
                        <Error>{supplier.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column md={6}>
                      <CategorySelector
                        value={categories.value}
                        error={categories.error}
                        onChange={this.handleCategoriesChange}
                        disabled={false}
                        isMulti={true}
                        isRequired={true}
                      />
                      {categories.error ? (
                        <Error>{categories.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={3}>
                      <Select
                        id="status"
                        label="Estado"
                        placeholder="Seleccione un estado"
                        required
                        options={STATUS_OPTIONS}
                        value={status}
                        onChange={this.handleStatusChange}
                        backspaceRemovesValue={false}
                      />
                      <Spacer />
                    </Grid.Column>
                    <Grid.Column md={3}>
                      <Input
                        id="priority"
                        value={priority.value ? priority.value : 0}
                        onChange={this.handlePriorityChange}
                        type="number"
                        label="Prioridad"
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column md={12}>
                      <Editor
                        id="description"
                        label="Descripción Larga"
                        value={description.value}
                        onChange={this.handleDescriptionChange}
                        required
                      />
                      {description.error ? (
                        <Error>{description.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Paper>
              </FormSection>
              {features.length > 0 && (
                <FormSection
                  title="Caracteristicas de producto"
                  subtitle="Los productos poseen características propias y únicas. Ejemplo: Color: rojo."
                  noBottomMargin
                >
                  <Grid.Row>
                    {features.map((feature, index) => {
                      return (
                        <Grid.Column md={4} key={index}>
                          <Paper>
                            <FeatureGroup
                              index={index}
                              featureName={feature.name}
                              onFeatureNameChange={this.handleFeatureNameChange}
                              options={feature.options}
                              onOptionsChange={this.handleOptionsChange}
                              onNewOptionCreate={this.handleNewOptionCreate}
                              oldOptions={
                                oldFeatures && oldFeatures[index].options
                              }
                              disabled={true}
                            />
                          </Paper>
                          <Spacer />
                        </Grid.Column>
                      )
                    })}
                  </Grid.Row>
                </FormSection>
              )}

              <FormSection title={'Agregar Meta Data'}>
                <Grid.Row>
                  {metaDataArray.map((data, index) => {
                    return (
                      <Grid.Column md={4} key={index}>
                        <Paper>
                          <MetadataGroup
                            index={index}
                            metadata={data}
                            onKeyChange={this.handleKeyMetadata}
                            onValueChange={this.handleValueMetadata}
                            onCloseClick={this.removeMetadata}
                          />
                        </Paper>
                        <Spacer />
                      </Grid.Column>
                    )
                  })}
                  <Grid.Column md={4}>
                    <Paper>
                      <AddFeatureWrapper>
                        <IconButton
                          color="primary"
                          icon={'plus'}
                          onClick={this.addItemToMetaData}
                        />
                        <AddFeatureText>Agregar nuevo item</AddFeatureText>
                      </AddFeatureWrapper>
                    </Paper>
                    <Spacer />
                  </Grid.Column>
                </Grid.Row>
              </FormSection>

              <FormSection title={'Agregar imágenes y videos'}>
                <Grid.Row>
                  <Grid.Column>
                    <Paper>
                      <AssetSelector
                        youtubeSupport
                        dropZoneSupport
                        colorButton="secondary"
                        textButton="Seleccionar archivos"
                        accept="image/gif,image/jpeg,image/png"
                        assets={assets}
                        onChange={this.handleAssetsChange}
                      />
                    </Paper>
                  </Grid.Column>
                </Grid.Row>
              </FormSection>
              {
                <ThemeContext.Consumer>
                  {notify => (
                    <Mutation
                      mutation={EDIT_PRODUCT_MUTATION}
                      onCompleted={() => {
                        onUpdated()
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'ok',
                            message: 'Producto guardado correctamente',
                          })
                      }}
                      onError={() => {
                        openModal('ALERT', {
                          header: {
                            title: 'ALERTA',
                          },
                          description:
                            'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                          type: 'fail',
                        })
                      }}
                    >
                      {(editProduct, { loading, error }) => (
                        <Mutation
                          mutation={DELETE_FILE}
                          onError={() => {
                            openModal('ALERT', {
                              header: {
                                title: 'ALERTA',
                              },
                              description:
                                'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                              type: 'fail',
                            })
                          }}
                        >
                          {(deleteFile, { loading: loadingDelete }) => (
                            <Mutation
                              mutation={UPLOAD_PRODUCT_FILE}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(
                                uploadProductFile,
                                { loading: loadingUpdate }
                              ) => (
                                <ActionWrapper>
                                  <SaveButton
                                    color="primary"
                                    disabled={
                                      loading || loadingDelete || loadingUpdate
                                    }
                                    onClick={async () => {
                                      let nextError = this.scrollToError()
                                      if (nextError) {
                                        const productChanges: EditProductInput = {
                                          id: product.id,
                                          name: name.value,
                                          summary: summary.value,
                                          description: description.value,
                                          brandId: brand['id'],
                                          features: [],
                                          assets: [],
                                          categories: [],
                                          supplierId: supplier['id'],
                                          status: status && status['value'],
                                          priority: Number(priority.value),
                                          metaData: metaData,
                                        }
                                        const uploadedImages: Asset[] = []
                                        const videos: Asset[] = []
                                        if (this.state.assetsStatus) {
                                          try {
                                            for (const deleteAsset of this.state
                                              .deleteAssets) {
                                              if (
                                                deleteAsset.type !== 'video'
                                              ) {
                                                const fileID = deleteAsset.url.substring(
                                                  deleteAsset.url.lastIndexOf(
                                                    '/'
                                                  ) + 1
                                                )
                                                await deleteFile({
                                                  variables: {
                                                    id: fileID,
                                                  },
                                                })
                                              }
                                            }
                                          } catch (error) {
                                            // TODO: Delete all uploaded files since one of them failed
                                            // tslint:disable-next-line:no-console
                                            console.log(
                                              'error deleting file',
                                              error
                                            )
                                            return
                                          }

                                          try {
                                            let index = 0
                                            for (const asset of assets) {
                                              index++

                                              if (asset.type === 'video') {
                                                videos.push({
                                                  id: asset.id,
                                                  url: asset.url,
                                                  type: 'video',
                                                  order: index,
                                                })
                                              } else {
                                                if (asset.file != null) {
                                                  const response = await uploadProductFile(
                                                    {
                                                      variables: {
                                                        file: asset.file,
                                                      },
                                                    }
                                                  )

                                                  if (response) {
                                                    const {
                                                      url,
                                                    } = response.data.uploadProductFile

                                                    uploadedImages.push({
                                                      id: '',
                                                      url,
                                                      type: 'image',
                                                      order: index,
                                                    })
                                                  }
                                                } else {
                                                  uploadedImages.push({
                                                    id: asset.id,
                                                    url: asset.url,
                                                    type: asset.type,
                                                    order: index,
                                                  })
                                                }
                                              }
                                            }

                                            // deleteFile
                                            productChanges.assets = [
                                              ...uploadedImages,
                                              ...videos,
                                            ].map((asset: Asset) => ({
                                              url: asset.url,
                                              type: asset.type,
                                              order: asset.order,
                                            }))
                                          } catch (error) {
                                            // TODO: Delete all uploaded files since one of them failed
                                            // tslint:disable-next-line:no-console
                                            console.log(
                                              'error uploading file',
                                              error
                                            )
                                            return
                                          }
                                        } else {
                                          productChanges.assets = assets
                                        }

                                        productChanges.categories =
                                          categories &&
                                          categories.value.map(
                                            (category: CategoryOption) =>
                                              category && category['id']
                                          )

                                        productChanges.features = features.map(
                                          feature => {
                                            return {
                                              id: feature.id,
                                              name: feature.name.value,
                                              options: feature.options.value.map(
                                                (option: any) => {
                                                  return option
                                                }
                                              ),
                                            }
                                          }
                                        )

                                        editProduct({
                                          variables: {
                                            input: productChanges,
                                          },
                                        })
                                      }
                                    }}
                                  >
                                    {loading
                                      ? 'Guardando cambios...'
                                      : 'Guardar cambios'}
                                  </SaveButton>
                                </ActionWrapper>
                              )}
                            </Mutation>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </ThemeContext.Consumer>
              }
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default Product
