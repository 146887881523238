import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
// import { ApolloLink } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'
import { API_URL } from './config'
import { ApolloClient } from 'apollo-client'

export const createClient = () => {
  const errorsLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // handle errors differently based on its error code
        switch (err.extensions && err.extensions.code) {
          case 'UNAUTHENTICATED':
            localStorage.clear()
            sessionStorage.clear()
            window.location.pathname = '/logout'
            break
        }
      }
      graphQLErrors.map(({ message, locations, path }) =>
        // tslint:disable-next-line:no-console
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }

    if (networkError) {
      // tslint:disable-next-line:no-console
      console.log(`[Network error]: ${networkError}`)
    }
  })

  const link = errorsLink.concat(
    createUploadLink({
      uri: API_URL,
    })
  )

  const authLink = setContext((_: any, { headers }) => {
    const token = sessionStorage.getItem('token')
    return {
      headers: {
        ...headers,
        authorization: token ? token : '',
      },
    }
  })

  return new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
  })
}
