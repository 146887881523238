import React, { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import StyledInput from '../../../../components/Form/StyledInput'
import StyledSelect from '../../../../components/Form/StyledSelect'
import { SaveButton } from '../../../../components/Globals'
import { Row, Column } from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import ProductsTable from '../../../../components/Tables/ProductsTable'
import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/react-hooks'

import ThemeContext from '../../../../context/ThemeContext'

const CREATE_CAMPAIGN_MUTATION = gql`
  mutation createCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      id
    }
  }
`

interface Props {
  onCreated: () => void
}

const CreateCategoryForm = ({ onCreated, ...props }: Props) => {
  const { handleSubmit, errors, control } = useForm()
  const { forceShowNotification } = useContext(ThemeContext)

  const onSubmit = (data: any) => {
    handleSave(data)
  }
  const [products, setProducts] = useState<any>([])
  const client = useApolloClient()

  const handleRemovedProduct = (product: any) => {
    const auxRemovedProducts = products

    const index = auxRemovedProducts.indexOf(product.id)
    if (index > -1) {
      auxRemovedProducts.splice(index, 1)
      setProducts(auxRemovedProducts)
    }
  }

  const handleAddProduct = (product: any) => {
    const founded = products.find(
      (productItem: any) => productItem === product.id
    )

    if (!founded) {
      const auxProducts = products
      auxProducts.push(product.id)
      setProducts(auxProducts)
    }
  }

  const handleSave = async (data: any) => {
    const saved = await client.mutate({
      mutation: CREATE_CAMPAIGN_MUTATION,
      variables: {
        input: {
          name: data.name,
          url: data.url,
          order: data.order,
          metaData: { products },
          campaignType: data.template,
          campaignStatus: data.status,
        },
      },
    })

    if (saved) {
      forceShowNotification &&
        forceShowNotification({
          type: 'ok',
          message: 'Agregada la campana correctamente',
        })
      window.location.href = `/campaigns`
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'fail',
          message: 'Hubo un problema, intentelo de nuevo',
        })
    }
  }

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Column md={4}>
            <StyledInput
              isRequired
              name="name"
              control={control}
              label="Nombre"
              error={errors.name}
              rules={{
                required: 'Campo Requerido',
              }}
            />
          </Column>
          <Column md={4}>
            <StyledInput
              isRequired
              name="url"
              control={control}
              label="Url Personalizada"
              error={errors.url}
              rules={{
                required: 'Campo Requerido',
              }}
            />
          </Column>
          {/*<Column md={4}>
            <StyledSelect
              isRequired
              name="template"
              control={control}
              label="Tipo de template"
              error={errors.template}
              rules={{
                required: 'Campo Requerido',
              }}
              options={[
                { label: 'Template 1', value: 1 },
                { label: 'Template 2', value: 2 },
                { label: 'Template 3', value: 3 },
                { label: 'Template 4', value: 4 },
              ]}
            />
            </Column>
          <Column md={4}>
            <StyledInput
              isRequired
              name="order"
              control={control}
              label="Orden de visualizacion"
              error={errors.order}
              rules={{
                required: 'Campo Requerido',
                min: {
                  value: 0,
                  message: 'Valor mínimo no permitido',
                },
              }}
              type="number"
              min={0}
            />
          </Column>*/}
          <Column md={4}>
            <StyledSelect
              isRequired
              name="status"
              control={control}
              label="Estado"
              error={errors.status}
              rules={{
                required: 'Campo Requerido',
              }}
              options={[
                { label: 'Activo', value: 'active' },
                { label: 'Inactivo', value: 'inactive' },
              ]}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <h2>Productos</h2>
          </Column>
          <Column>
            <ProductsTable
              updateParent={handleAddProduct}
              handleRemoved={handleRemovedProduct}
            />
          </Column>
        </Row>
        <Row>
          <Column md={3}>
            <SaveButton color="primary" type="submit">
              {'Crear Campaña'}
            </SaveButton>
          </Column>
        </Row>
      </form>
    </Paper>
  )
}

export default CreateCategoryForm
