import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import Modal from '../../../../components/Modals/ModalRoot'
import { STATUS_OPTIONS_SUPPLIERS } from '../../../../config'
import { SaveButton } from '../style'
import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import AssetSelector, { Asset } from '../../../../components/AssetSelector'
import { Input, Select, Error } from '../../../../components/FormElements'

import ThemeContext from '../../../../context/ThemeContext'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'

const EDIT_PROMOTION_MUTATION = gql`
  mutation editPromotionMutation($input: EditPromotionInput!) {
    editPromotion(input: $input)
  }
`

const UPLOAD_PROMOTION_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

const DELETE_FILE = gql`
  mutation deleteFile($id: String) {
    deleteFile(id: $id)
  }
`

// interface Option {
//   id: string
//   status: string
// }

interface AssetData {
  id?: string
  url?: string
  type?: string
  file?: string
}
interface Props {
  promotion: {
    id: string
    name: string
    city: string
    status: string
    order: number
    url: string
    summary: string
    assets: Array<{
      id: string
      url: string
      type: string
    }>
  }
  onUpdated: () => void
}

interface State {
  name: Field
  city: Field
  order: Field
  description: Field
  url: Field
  status: ValueType<string | number | { [key: string]: any }>
  assetsError: boolean
  assets: Asset[]
  oldAssets: Asset[]
  deleteAssets: Asset[]
  createError: boolean
  assetsStatus: boolean
  inputs: InputElements[]
}

type EditPromotionInput = {
  id: string
  name: string
  city: string
  order: number
  assets?: Array<AssetData>
  status: string
  summary: string
  url: string
}

const inputIds = ['name', 'status', 'order', 'description', 'city', 'url']

class Promotion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const { name, city, order, status, assets, summary, url } = props.promotion

    const results = STATUS_OPTIONS_SUPPLIERS.filter(statusArray => {
      return statusArray.value === status
    })
    const statusSelected =
      results.length > 0
        ? results[0]
        : {
            value: 'inactive',
            label: 'Inactivo',
          }
    this.state = {
      name: {
        ...initialField,
        value: name,
      },
      city: {
        ...initialField,
        value: city,
      },
      order: {
        ...initialField,
        value: order,
      },
      status: { value: statusSelected.value, label: statusSelected.label },
      description: { ...initialField, value: summary },
      url: { ...initialField, value: url },
      assetsError: false,
      assets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.type,
        }
      }),
      oldAssets: assets.map(asset => {
        return {
          id: asset.id,
          url: asset.url,
          type: asset.type,
        }
      }),
      deleteAssets: [],
      createError: false,
      assetsStatus: false,
      inputs: [],
    }
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }

  handleCityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const city = value

    const validations = validateField(city, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        city: {
          value: city,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      city: {
        value: city,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const description = value
    this.setState({
      description: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  handleUrlChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const url = value

    const validations = validateField(url, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])

    if (validations.value) {
      this.setState({
        url: {
          value: url,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      url: {
        value: url,
        error: false,
        errorText: '',
      },
    })
  }

  handleAssetsChange = (assets: Asset[]) => {
    const { oldAssets } = this.state
    const deleteAssets = oldAssets.filter(
      (oldAsset: Asset) =>
        assets.map((asset: Asset) => asset.id).indexOf(oldAsset.id) === -1
    )
    this.setState({ assets, assetsStatus: true, deleteAssets })
  }

  handleOrderChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const order = value
    this.setState({
      order: {
        value: order,
        error: false,
        errorText: '',
      },
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    errorsMapSelects['features'] = []

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    return error
  }

  validateAssets = () => {
    const { assets } = this.state

    let assetsValidation = false

    if (assets.length === 0) {
      assetsValidation = true
    }

    this.setState({
      assetsError: assetsValidation,
    })

    return assetsValidation
  }

  mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      name,
      city,
      order,
      status,
      description,
      url,
      assets,
      assetsError,
    } = this.state

    const { onUpdated, promotion } = this.props

    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <FormSection title="Información General" openedByDefault={true}>
                <Paper>
                  <Grid.Row>
                    <Grid.Column md={4}>
                      <Input
                        id="name"
                        value={name.value}
                        onChange={this.handleNameChange}
                        type="text"
                        error={name.error}
                        label="Nombre de promoción"
                        required
                      />
                      {name.error ? (
                        <Error>{name.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={4}>
                      <Select
                        id="status"
                        label="Estado"
                        placeholder="Seleccione un estado"
                        required
                        options={STATUS_OPTIONS_SUPPLIERS}
                        value={status}
                        onChange={this.handleStatusChange}
                        backspaceRemovesValue={false}
                      />
                    </Grid.Column>
                    <Grid.Column md={4}>
                      <Input
                        id="order"
                        value={order.value}
                        error={order.error}
                        onChange={this.handleOrderChange}
                        type="number"
                        label="Prioridad"
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column md={8}>
                      <Input
                        id="description"
                        value={description.value}
                        error={description.error}
                        onChange={this.handleDescriptionChange}
                        type="text"
                        label="Descripción corta"
                        required
                      />
                      {description.error ? (
                        <Error>{description.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                    <Grid.Column md={4}>
                      <Input
                        id="city"
                        value={city.value}
                        error={city.error}
                        onChange={this.handleCityChange}
                        type="text"
                        label="Ciudad"
                        required
                      />
                      {city.error ? (
                        <Error>{city.errorText}</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column md={12}>
                      <Input
                        id="url"
                        value={url.value}
                        error={url.error}
                        onChange={this.handleUrlChange}
                        type="text"
                        label="URL"
                        required
                      />
                      {url.error ? <Error>{url.errorText}</Error> : <Spacer />}
                    </Grid.Column>
                  </Grid.Row>
                </Paper>
              </FormSection>
              <FormSection title={'Carga de imagen'}>
                <Grid.Row>
                  <Grid.Column>
                    <Paper>
                      <AssetSelector
                        dropZoneSupport
                        colorButton="secondary"
                        textButton="Seleccionar archivos"
                        accept="image/gif,image/jpeg,image/png"
                        assets={assets}
                        onChange={this.handleAssetsChange}
                      />
                      {assetsError ? (
                        <Error>Debe seleccionar un archivo.</Error>
                      ) : (
                        <Spacer />
                      )}
                    </Paper>
                  </Grid.Column>
                </Grid.Row>
              </FormSection>

              {
                <ThemeContext.Consumer>
                  {notify => (
                    <Mutation
                      mutation={EDIT_PROMOTION_MUTATION}
                      onCompleted={() => {
                        onUpdated()
                        notify &&
                          notify.onSetNotification &&
                          notify.onSetNotification({
                            type: 'ok',
                            message: 'Promoción guardada correctamente',
                          })
                      }}
                      onError={() => {
                        openModal('ALERT', {
                          header: {
                            title: 'ALERTA',
                          },
                          description:
                            'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                          type: 'fail',
                        })
                      }}
                    >
                      {(editPromotion, { loading, error }) => (
                        <Mutation
                          mutation={DELETE_FILE}
                          onError={() => {
                            openModal('ALERT', {
                              header: {
                                title: 'ALERTA',
                              },
                              description:
                                'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                              type: 'fail',
                            })
                          }}
                        >
                          {(deleteFile, { loading: loadingDelete }) => (
                            <Mutation
                              mutation={UPLOAD_PROMOTION_FILE}
                              onError={() => {
                                openModal('ALERT', {
                                  header: {
                                    title: 'ALERTA',
                                  },
                                  description:
                                    'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                                  type: 'fail',
                                })
                              }}
                            >
                              {(
                                uploadSupplierFile,
                                { loading: loadingUpdate }
                              ) => (
                                <ActionWrapper>
                                  <SaveButton
                                    color="primary"
                                    disabled={
                                      loading || loadingDelete || loadingUpdate
                                    }
                                    onClick={async () => {
                                      let nextError = this.scrollToError()
                                      let assetsError = this.validateAssets()

                                      if (!assetsError) {
                                        if (nextError) {
                                          const promotionChanges: EditPromotionInput = {
                                            id: promotion.id,
                                            name: name.value,
                                            city: city.value,
                                            status: status && status['value'],
                                            order: Number(order.value),
                                            summary: description.value,
                                            url: url.value,
                                          }
                                          promotionChanges.assets = assets

                                          const uploadedImages: Asset[] = []
                                          const videos: Asset[] = []
                                          if (this.state.assetsStatus) {
                                            try {
                                              for (const deleteAsset of this
                                                .state.deleteAssets) {
                                                if (
                                                  deleteAsset.type !== 'video'
                                                ) {
                                                  const fileID = deleteAsset.url.substring(
                                                    deleteAsset.url.lastIndexOf(
                                                      '/'
                                                    ) + 1
                                                  )
                                                  await deleteFile({
                                                    variables: {
                                                      id: fileID,
                                                    },
                                                  })
                                                }
                                              }
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error deleting file',
                                                error
                                              )
                                              return
                                            }

                                            try {
                                              for (const asset of assets) {
                                                if (asset.type === 'video') {
                                                  videos.push({
                                                    id: asset.id,
                                                    url: asset.url,
                                                    type: 'video',
                                                  })
                                                } else {
                                                  if (asset.file != null) {
                                                    const response = await uploadSupplierFile(
                                                      {
                                                        variables: {
                                                          file: asset.file,
                                                        },
                                                      }
                                                    )

                                                    if (response) {
                                                      const {
                                                        url,
                                                      } = response.data.uploadSupplierFile

                                                      uploadedImages.push({
                                                        id: '',
                                                        url,
                                                        type: 'image',
                                                      })
                                                    }
                                                  } else {
                                                    uploadedImages.push({
                                                      id: asset.id,
                                                      url: asset.url,
                                                      type: asset.type,
                                                    })
                                                  }
                                                }
                                              }

                                              // deleteFile
                                              promotionChanges.assets = [
                                                ...uploadedImages,
                                                ...videos,
                                              ].map((asset: any) => ({
                                                url: asset.url,
                                                type: asset.type,
                                              }))
                                            } catch (error) {
                                              // TODO: Delete all uploaded files since one of them failed
                                              // tslint:disable-next-line:no-console
                                              console.log(
                                                'error uploading file',
                                                error
                                              )
                                              return
                                            }
                                          } else {
                                            promotionChanges.assets = assets
                                          }

                                          editPromotion({
                                            variables: {
                                              input: promotionChanges,
                                            },
                                          })
                                        }
                                      }
                                    }}
                                  >
                                    {loading
                                      ? 'Guardando cambios...'
                                      : 'Guardar cambios'}
                                  </SaveButton>
                                </ActionWrapper>
                              )}
                            </Mutation>
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  )}
                </ThemeContext.Consumer>
              }
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}

export default Promotion
