import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateSupplierForm from './components/CreateSupplierForm'
import EditSupplierForm, { Supplier } from './components/EditSupplierForm'

interface RouteParams {
  id?: string
  action: string
}

const GET_SUPPLIER_QUERY = gql`
  query getSupplierQuery($id: ID!) {
    supplier(id: $id) {
      id
      legalName
      businessName
      city
      ruc
      phone1
      phone2
      website
      type
      personType
      address
      courier {
        id
        name
      }
      contacts {
        id
        name
        email
      }
      assets {
        id
        url
        order
      }
      allowCoupons
      deliverProducts
      tagProducts
      metaData
      gpsCoverage
      schemaCode
      slug
      status
    }
  }
`

interface Data {
  supplier: Supplier
}
class SupplierForm extends React.Component<RouteComponentProps<RouteParams>> {
  onSupplierSaved = () => {
    this.props.history.push('/suppliers')
  }

  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={action === 'create' ? 'Crear Proveedor' : 'Editar Proveedor'}
      >
        {action === 'create' ? (
          <CreateSupplierForm onCreated={this.onSupplierSaved} />
        ) : (
          <Query
            variables={{ id }}
            query={GET_SUPPLIER_QUERY}
            fetchPolicy={'network-only'}
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }

              return (
                <EditSupplierForm
                  supplier={data.supplier}
                  onUpdated={this.onSupplierSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(SupplierForm)
