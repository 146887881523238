import styled from 'styled-components'

type ErrorProps = {
  hasError: boolean
  inputRef: any
}

export const RequiredMark = styled.span`
  color: #18b0f7;
  margin-left: 3px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`
export const Label = styled.label`
  color: #858585;
`
export const Input = styled.input<ErrorProps>`
  border-radius: 20px;
  background-color: #f5f5f5;
  border: solid 1px #f5f5f5;
  outline: 0;
  color: #454545;
  padding: 12px 20px;
  margin: 0.5rem 0;

  &:focus {
    border: solid 1px #18b0f7;
  }

  &:disabled {
    background: #c5c5c5;
    border: solid 1px #c5c5c5;
    color: #fff;
  }

  ::placeholder {
    color: #858585;
    opacity: 0.5;
  }

  ${(props: ErrorProps) => {
    if (props.hasError) {
      return `
        background-color: rgba(255, 45, 45, 0.2);
        border-color: #ff2d2d;

        &:focus {
          border-color: #ff2d2d;
        }
      `
    }

    return null
  }};
`
export const ErrorMessage = styled.div`
  font-size: 0.8rem;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  color: #ff2d2d;
`
