import React from 'react'
import Modal from '../../../../components/Modals/ModalRoot'
import Paper from '../../../../components/Paper'
import * as Grid from '../../../../components/Grid'
import { DatePicker } from '../../../../components/FormElements'
import ThemeContext from '../../../../context/ThemeContext'
import { Mutation } from 'react-apollo'
import { gql } from 'apollo-boost'
import { ActionWrapper, Spacer } from '../../../../components/Globals'
import { SaveButton } from '../../../Category/Form/style'
import {
  ColumnDescription,
  ColumnTitle,
  Container,
  TableContainer,
  Title,
} from '../../List/style'

const EDIT_COUPON_MUTATION = gql`
  mutation editCouponMutation($input: EditCouponInput!) {
    editCoupon(input: $input)
  }
`

interface Props {
  onUpdated: () => void
  coupon: {
    id: string
    code: string
    firstName: string
    lastName: string
    expiresFrom: string
    expiresTo: string
  }
}
interface State {
  id: string
  code: string
  firstName: string
  lastName: string
  expiresFrom: Date
  expiresTo: Date
}

class EditCouponForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const {
      id,
      expiresFrom,
      expiresTo,
      code,
      firstName,
      lastName,
    } = props.coupon

    this.state = {
      id,
      code,
      firstName,
      lastName,
      expiresTo: new Date(expiresTo),
      expiresFrom: new Date(expiresFrom),
    }
  }

  render() {
    const { id, expiresFrom, expiresTo, code, firstName, lastName } = this.state
    return (
      <Modal>
        {({ openModal }) => {
          return (
            <React.Fragment>
              <Title>Cupón: {code}</Title>
              <Spacer />
              <TableContainer>
                <Container>
                  <ColumnTitle>Cliente</ColumnTitle>
                  <ColumnDescription>
                    {firstName} {lastName}
                  </ColumnDescription>
                </Container>
              </TableContainer>
              <Paper>
                <Grid.Row>
                  <Grid.Column md={4}>
                    <DatePicker
                      label="Desde"
                      date={expiresFrom}
                      onChange={e => this.setState({ expiresFrom: e })}
                      dateFormat="yyyy-MM-dd h:mm:ss"
                      showTimeSelect
                    />
                  </Grid.Column>
                  <Grid.Column md={4}>
                    <DatePicker
                      label="Hasta"
                      date={expiresTo}
                      onChange={e => this.setState({ expiresTo: e })}
                      dateFormat="yyyy-MM-dd h:mm:ss"
                      showTimeSelect
                    />
                  </Grid.Column>
                </Grid.Row>
              </Paper>
              <ThemeContext.Consumer>
                {notify => (
                  <Mutation
                    mutation={EDIT_COUPON_MUTATION}
                    onCompleted={() => {
                      this.props.onUpdated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Cupón actualizado correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(editCoupon, { loading, error }) => {
                      return (
                        <ActionWrapper>
                          <SaveButton
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              editCoupon({
                                variables: {
                                  input: {
                                    id,
                                    expiresFrom: expiresFrom.toISOString(),
                                    expiresTo: expiresTo.toISOString(),
                                  },
                                },
                              })
                            }}
                          >
                            {loading
                              ? 'Guardando cambios...'
                              : 'Guardar cambios'}
                          </SaveButton>
                        </ActionWrapper>
                      )
                    }}
                  </Mutation>
                )}
              </ThemeContext.Consumer>
            </React.Fragment>
          )
        }}
      </Modal>
    )
  }
}
export default EditCouponForm
