import styled from 'styled-components'
import { Button } from '../../components/Buttons'
import sizes from '../../styles/media'
import Icon from '../../components/Icon'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  color: 'primary' | 'secondary'
  isSelected: boolean
}

interface ContainerProps {
  hasPatent: boolean
}

export const SaveButton = styled(Button)`
  width: 100%;
  @media ${sizes.sm} {
    width: auto;
  }
`
export const FormContainer = styled.div`
  padding: 15px;
`

export const IconContainer = styled.div<ContainerProps>`
  width: 100%;
  height: ${props => (props.hasPatent ? '14.7rem' : '8.7rem')};
  border-radius: 20px;
  background-color: #f5f5f5;
  border: solid 1px #f5f5f5;
  padding: 12px 20px;
  outline: 0;
  box-shadow: none;
  overflow: scroll;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
  grid-gap: 1rem;

  @media (max-width: 1540px) {
    grid-template-columns: auto auto auto auto;
  }

  @media (max-width: 1370px) {
    grid-template-columns: auto auto auto;
  }
`
export const IconArea = styled.div`
  width: 100%;
  outline: 0;
  box-shadow: none;
`

export const IconAreaLabel = styled.p`
  color: #858585;
  font-size: 14px;
  padding-left: 0.5rem;
  margin-bottom: 0.3rem;
`
export const IconForm = styled(Icon)`
  font-size: 30px;
`

export const IconButton = styled.button<Props>`
  color: ${props => (props.isSelected ? '#FFFFFF' : '#454545')};
  width: 3.75rem;
  height: 3.75rem;
  padding: 0.3rem;
  border-radius: 5px
  background-color: ${props =>
    props.isSelected ? props.theme.colors[props.color]['default'] : '#FFFFFF'} 
  border: solid 1px #c5c5c5;
  box-shadow: none;

  &:focus { outline: 0;}
  
  &:hover {
    background-color: ${props =>
      props.color
        ? props.theme.colors[props.color]['hover']
        : props.theme.colors['primary']['hover']};
  }

`

export const SwitchTitle = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: #858585;
  margin: 0;
  padding-left: 1rem;
  padding-top: 0.2rem;
`
