import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'

import PageWrapper from '../../../components/PageWrapper'
import CreateProductForm from './components/CreateProductForm'
import EditProductForm from './components/EditProductForm'
import { CategoryOption } from '../../../components/Selectors/CategorySelector'
import { BrandOption } from './components/BrandSelector'
import { SupplierOption } from '../../../components/Selectors/SupplierSelector'

interface RouteParams {
  id?: string
  action: string
}

const GET_PRODUCT_QUERY = gql`
  query getProductQuery($id: ID!) {
    product(id: $id) {
      id
      name
      summary
      description
      metaData
      categories {
        id
        name
      }
      brand {
        id
        name
      }
      supplier {
        id
        businessName
      }
      status
      priority
      features {
        id
        name
        options {
          id
          name
        }
      }
      assets {
        id
        url
        type
        order
      }
    }
  }
`
interface Option {
  id: string
  name: string
}
interface Data {
  product: {
    id: string
    name: string
    summary: string
    description: string
    priority: string
    categories: CategoryOption[]
    brand: BrandOption
    supplier: SupplierOption
    status: string
    features: Array<{
      id: string
      name: string
      options: Option[]
    }>
    assets: Array<{
      id: string
      url: string
      type: string
      order: number
    }>
    metaData: JSON
  }
}

class Product extends React.Component<RouteComponentProps<RouteParams>> {
  onProductSaved = () => {
    this.props.history.push('/products')
  }
  render() {
    const { id, action } = this.props.match.params

    return (
      <PageWrapper
        title={`${action === 'create' ? 'Crear' : 'Editar'} Producto`}
      >
        {action === 'create' && (
          <CreateProductForm onCreated={this.onProductSaved} />
        )}
        {action === 'edit' && (
          <Query
            variables={{ id }}
            query={GET_PRODUCT_QUERY}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }: QueryResult<Data, any>) => {
              if (loading) {
                return null
              }

              if (error) {
                return null
              }

              if (!data) {
                return null
              }
              return (
                <EditProductForm
                  product={data.product}
                  onUpdated={this.onProductSaved}
                />
              )
            }}
          </Query>
        )}
      </PageWrapper>
    )
  }
}

export default withRouter(Product)
