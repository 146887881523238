import React from 'react'
import { CheckBox, Switch } from '../../../../components/FormElements'
import {
  CloseButton,
  CloseIcon,
  ScheduleData,
  ScheduleLabel,
  ScheduleDates,
  ScheduleDayLabel,
  SwitchDummy,
} from '../style'
import { ValueType } from 'react-select/lib/types'
import { DatePicker } from '../../../../components/FormElements'

interface Props {
  index: number
  error?: string
  data: any
  scheduleType: boolean
  value?: string
  oldOptions?: string[]
  disabled?: boolean
  required?: boolean
  isEdit: boolean

  onCloseClick: (featureIndex: number) => void
  onChecked: (valueCheck: boolean) => void
  onSelectChange: (
    status: ValueType<string | number | { [key: string]: any }>,
    index: any,
    type: string
  ) => void
  onSwitchChange: (index: number, value: boolean) => void
}

const ScheduleGroup = ({
  index,
  data,
  scheduleType,
  isEdit,

  onCloseClick,
  onChecked,
  onSelectChange,
  onSwitchChange,
}: Props) => (
  <React.Fragment>
    <CloseButton
      onClick={() => {
        onCloseClick && onCloseClick(index)
      }}
    >
      <CloseIcon name="cancel-circled" />
    </CloseButton>
    <ScheduleData>
      <ScheduleLabel>Tipo de horarios</ScheduleLabel>
      <CheckBox
        icon={'circle'}
        round={true}
        label="Horarios unificados"
        checked={scheduleType}
        onChange={() => onChecked(!scheduleType)}
      />
      <CheckBox
        icon={'circle'}
        round={true}
        label="Horarios dinámicos"
        checked={!scheduleType}
        onChange={() => onChecked(!scheduleType)}
      />
    </ScheduleData>
    {data.map((v: any, i: any) => {
      const data = isEdit ? v.value.value : v.value.value
      const dateFrom = new Date('2015-03-25T12:00:00Z')
      const dateTo = new Date('2015-03-25T12:00:00Z')

      if (data.from && data.from.value && data.from.value != '') {
        const dateHourFrom = data.from.label.split(':')

        dateFrom.setHours(dateHourFrom[0])
        dateFrom.setMinutes(dateHourFrom[1])
      }

      if (data.to && data.to.value && data.to.value != '') {
        const dateHourTo = data.to.label.split(':')

        dateTo.setHours(dateHourTo[0])
        dateTo.setMinutes(dateHourTo[1])
      }

      return (
        <ScheduleDates key={`schedule-${i}`}>
          <ScheduleDayLabel key={i}> {data.day} </ScheduleDayLabel>
          {!scheduleType ? (
            <Switch
              id={`active-${i}`}
              key={`active-${i}`}
              checked={data.active}
              onChange={() => onSwitchChange(i, !data.active)}
            />
          ) : (
            <>
              <SwitchDummy />
              <SwitchDummy />
            </>
          )}

          <DatePicker
            key={`from-${i}`}
            label="Desde"
            date={dateFrom}
            onChange={(
              option: ValueType<string | number | { [key: string]: any }>
            ) => onSelectChange(option, i, 'from')}
            dateFormat="hh:mm aa"
            timeCaption="Hora"
            showTimeSelect
            showTimeSelectOnly={true}
            disabled={!data.active}
          />

          <DatePicker
            key={`to-${i}`}
            label="Hasta"
            date={dateTo}
            onChange={e => onSelectChange(e, i, 'to')}
            dateFormat="hh:mm aa"
            timeCaption="Hora"
            showTimeSelect
            showTimeSelectOnly={true}
            disabled={!data.active}
          />
        </ScheduleDates>
      )
    })}
  </React.Fragment>
)

export default ScheduleGroup
