import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import Modal from '../../../../components/Modals/ModalRoot'
import { STATUS_OPTIONS } from '../../../../config'
import { SaveButton, AddFeatureWrapper, AddFeatureText } from '../style'
import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import { IconButton } from '../../../../components/Buttons'
import Paper from '../../../../components/Paper'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import FeatureGroup from '../components/FeatureGroup'
import BrandSelector, { BrandOption } from '../components/BrandSelector'
import SupplierSelector, {
  SupplierOption,
} from '../../../../components/Selectors/SupplierSelector'
import CategorySelector, {
  CategoryOption,
} from '../../../../components/Selectors/CategorySelector'
import AssetSelector from '../../../../components/AssetSelector'
import { Input, Select, Error } from '../../../../components/FormElements'

import Editor from '../../../../components/Editor'

import ThemeContext from '../../../../context/ThemeContext'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'
import MetadataGroup from '../components/MetadataGroup'

const UPLOAD_PRODUCT_FILE = gql`
  mutation uploadProductFile($file: Upload!) {
    uploadProductFile(file: $file) {
      url
      fileName
    }
  }
`

const CREATE_PRODUCT_MUTATION = gql`
  mutation createProductMutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`

interface Props {
  onCreated: () => void
}

interface Metadata {
  key: Field
  value: Field
}

interface State {
  name: Field
  summary: Field
  description: Field
  priority: Field
  brand: Field
  supplier: Field
  status: ValueType<string | number | { [key: string]: any }>
  categories: Field
  features: Array<{
    name: Field
    options: Field
  }>
  assets: Array<{
    url: string
    type: string
    file: any
    order: number
  }>
  createError: boolean
  inputs: InputElements[]
  metaData: any
  metaDataArray: Metadata[]
}

type AssetType = {
  url: string
  type: string
  order: number
}

const inputIds = [
  'description',
  'status',
  'categories',
  'supplier',
  'brand',
  'summary',
  'name',
]
const selectsRequired = ['description', 'categories', 'supplier', 'brand']

class Product extends React.Component<Props, State> {
  state: State = {
    name: initialField,
    summary: initialField,
    description: initialField,
    priority: initialField,
    brand: initialField,
    supplier: initialField,
    status: { value: 'active', label: 'Activo' },
    categories: initialField,
    features: [
      {
        name: initialField,
        options: { value: '', error: false, errorText: '' },
      },
    ],
    assets: [],
    createError: false,
    inputs: [],
    metaData: JSON.parse(JSON.stringify({})),
    metaDataArray: [],
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }

  handleSummaryChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const summary = value
    const validations = validateField(summary, [
      {
        name: 'shorterThan',
        value: 1200,
      },
    ])

    if (validations.value) {
      this.setState({
        summary: {
          value: summary,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      summary: {
        value: summary,
        error: false,
        errorText: '',
      },
    })
  }
  handleBrandChange = (brand: BrandOption) => {
    this.setState({
      brand: {
        value: brand,
        error: false,
        errorText: '',
      },
    })
  }

  handleSupplierChange = (supplier: SupplierOption) => {
    this.setState({
      supplier: {
        value: supplier,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleCategoriesChange = (categories: CategoryOption) => {
    if (categories && categories.length === 0) {
      this.setState({
        categories: {
          value: null,
          error: true,
          errorText: 'Campo requerido',
        },
      })
      return
    }

    this.setState({
      categories: {
        value: categories,
        error: false,
        errorText: '',
      },
    })
  }

  onFeatureAddClick = () => {
    const { features } = this.state

    features.push({
      name: initialField,
      options: initialField,
    })

    this.setState({ features })
  }

  onFeatureRemoveClick = (index: number) => {
    const { features } = this.state

    const result = [...features.slice(0, index), ...features.slice(index + 1)]

    this.setState({ features: result })
  }

  handleFeatureNameChange = (featureIndex: number, featureName: string) => {
    if (featureName.length > 40) {
      this.setState(prevState => {
        prevState.features[featureIndex].name = {
          value: featureName,
          error: true,
          errorText: 'La longitud máxima debe ser de 40 caracteres.',
        }
        return prevState
      })
      return
    }
    this.setState(prevState => {
      prevState.features[featureIndex].name = {
        value: featureName,
        error: false,
        errorText: '',
      }
      return prevState
    })
  }

  handleOptionsChange = (featureIndex: number, options: string[]) => {
    if (options.length == 0) {
      this.setState(prevState => {
        prevState.features[featureIndex].options = {
          value: options,
          error: true,
          errorText: 'Agregue al menos una opción',
        }
        return prevState
      })
      return
    }
    this.setState(prevState => {
      prevState.features[featureIndex].options = {
        value: options,
        error: false,
        errorText: '',
      }
      return prevState
    })
  }

  handleNewOptionCreate = (featureIndex: number, newOption: string) => {
    const { features } = this.state

    features[featureIndex].options.value = [
      ...features[featureIndex].options.value,
      newOption,
    ]
    features[featureIndex].options.error = false

    this.setState({ features })
  }

  handleAssetsChange = (assets: any[]) => {
    this.setState({ assets })
  }

  handlePriorityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const priority = value

    this.setState({
      priority: {
        value: priority,
        error: false,
        errorText: '',
      },
    })
  }

  handleDescriptionChange = (description: string) => {
    this.setState({
      description: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  validateMetadata = () => {
    this.setState({
      metaDataArray: this.state.metaDataArray.map(item => {
        if (item.key && item.key.value.trim() == '') {
          item.key.error = true
          item.key.errorText = 'Campo Requerido'
        }
        if (item.value && item.value.value.trim() == '') {
          item.value.error = true
          item.value.errorText = 'Campo Requerido'
        }
        return item
      }),
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    const { features } = this.state
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    selectsRequired.forEach(id => {
      if (this.state[id] && !this.state[id].value) {
        error = false
        errorsMapSelects[id] = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    errorsMapSelects['features'] = []
    features.forEach((feature, index) => {
      errorsMapSelects['features'][index] = feature
      if (!feature.name.value) {
        error = false
        errorsMapSelects['features'][index].name = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
      if (!feature.options.value && feature.options.value.length === 0) {
        error = false
        errorsMapSelects['features'][index].options = {
          value: '',
          error: true,
          errorText: 'Campo requerido',
        }
      }
    })

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    const dynamicIds: string[] = []

    this.state.features.map((feature, index) => {
      dynamicIds.push('feature' + index, 'options' + index)
    })
    const dynamicInputs = getElements(dynamicIds)

    const featuresLength = this.state.features.length

    this.state.features.map((key, index) => {
      const featureIndex = featuresLength - (index + 1)
      if (
        this.state.features[featureIndex] &&
        this.state.features[featureIndex].options.error
      ) {
        scrollTo(dynamicInputs['options' + featureIndex].element)
        error = false
      }
      if (
        this.state.features[featureIndex] &&
        this.state.features[featureIndex].name.error
      ) {
        scrollTo(dynamicInputs['feature' + featureIndex].element)
        error = false
      }
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })

    this.state.metaDataArray.map((item, index) => {
      if ((item.key && item.key.error) || (item.value && item.value.error)) {
        const element = document.getElementById('key-' + index)
        if (element) {
          scrollTo(element)
        }
        error = false
      }
    })

    return error
  }

  async mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  addItemToMetaData = () => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux.push({
      key: { ...initialField },
      value: { ...initialField },
    })
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  removeMetadata = (index: number) => {
    const metaDataArrayAux = this.state.metaDataArray
    const result = [
      ...metaDataArrayAux.slice(0, index),
      ...metaDataArrayAux.slice(index + 1),
    ]
    this.generateMetadataJson(result)
    this.setState({ metaDataArray: result })
  }

  handleKeyMetadata = (index: number, key: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].key = {
      value: key,
      error: key.trim() == '' ? true : false,
      errorText: key.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  handleValueMetadata = (index: number, value: string) => {
    const metaDataArrayAux = this.state.metaDataArray
    metaDataArrayAux[index].value = {
      value: value,
      error: value.trim() == '' ? true : false,
      errorText: value.trim() == '' ? 'Campo Requerido' : '',
    }
    this.generateMetadataJson(metaDataArrayAux)
    this.setState({ metaDataArray: metaDataArrayAux })
  }

  generateMetadataJson = (metadataArray: Metadata[]) => {
    let metadataJson = {}
    metadataArray.forEach(item => {
      metadataJson[item.key.value] = item.value.value
    })
    this.setState({ metaData: JSON.parse(JSON.stringify(metadataJson)) })
  }

  render() {
    const {
      name,
      summary,
      description,
      priority,
      brand,
      supplier,
      status,
      categories,
      features,
      assets,
      metaData,
      metaDataArray,
    } = this.state

    const { onCreated } = this.props
    return (
      <ThemeContext.Consumer>
        {notify => (
          <Modal>
            {({ openModal }) => {
              return (
                <React.Fragment>
                  <FormSection
                    title="Información General"
                    openedByDefault={true}
                  >
                    <Paper>
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Input
                            id="name"
                            value={name.value}
                            onChange={this.handleNameChange}
                            type="text"
                            error={name.error}
                            label="Nombre"
                            required
                          />
                          {name.error ? (
                            <Error>{name.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={6}>
                          <Input
                            id="summary"
                            value={summary.value}
                            onChange={this.handleSummaryChange}
                            type="text"
                            error={summary.error}
                            label="Descripción corta"
                          />
                          {summary.error ? (
                            <Error>{summary.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <BrandSelector
                            value={brand.value}
                            onChange={this.handleBrandChange}
                            isRequired={true}
                            error={brand.error}
                          />
                          {brand.error ? (
                            <Error>{brand.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={6}>
                          <SupplierSelector
                            value={supplier.value}
                            onChange={this.handleSupplierChange}
                            isRequired={true}
                            error={supplier.error}
                          />
                          {supplier.error ? (
                            <Error>{supplier.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <CategorySelector
                            value={categories.value}
                            onChange={this.handleCategoriesChange}
                            disabled={false}
                            isMulti={true}
                            isRequired={true}
                            error={categories.error}
                          />
                          {categories.error ? (
                            <Error>{categories.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={3}>
                          <Select
                            id="status"
                            label="Estado"
                            placeholder="Seleccione un estado"
                            required
                            options={STATUS_OPTIONS}
                            value={status}
                            onChange={this.handleStatusChange}
                            backspaceRemovesValue={false}
                          />
                          <Spacer />
                        </Grid.Column>
                        <Grid.Column md={3}>
                          <Input
                            id="priority"
                            value={priority.value}
                            onChange={this.handlePriorityChange}
                            type="number"
                            label="Prioridad"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={12}>
                          <Editor
                            id={'description'}
                            label="Descripción Larga"
                            value={description.value}
                            onChange={this.handleDescriptionChange}
                            required
                          />
                          {description.error ? (
                            <Error>{description.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Paper>
                  </FormSection>
                  <FormSection
                    title="Caracteristicas de producto"
                    subtitle="Los productos poseen características propias y únicas. Ejemplo: Color: rojo."
                    noBottomMargin
                  >
                    <Grid.Row>
                      {features.map((feature, index) => {
                        return (
                          <Grid.Column md={4} key={index}>
                            <Paper>
                              <FeatureGroup
                                index={index}
                                featureName={feature.name}
                                onFeatureNameChange={
                                  this.handleFeatureNameChange
                                }
                                options={feature.options}
                                onOptionsChange={this.handleOptionsChange}
                                onNewOptionCreate={this.handleNewOptionCreate}
                                onCloseClick={
                                  features.length > 1
                                    ? this.onFeatureRemoveClick
                                    : () => {}
                                }
                                required
                              />
                            </Paper>
                            <Spacer />
                          </Grid.Column>
                        )
                      })}
                      <Grid.Column md={4}>
                        <Paper>
                          <AddFeatureWrapper>
                            <IconButton
                              color="primary"
                              icon={'plus'}
                              onClick={this.onFeatureAddClick}
                            />
                            <AddFeatureText>
                              Agregar una característica
                            </AddFeatureText>
                          </AddFeatureWrapper>
                        </Paper>
                        <Spacer />
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <FormSection title={'Agregar Meta Data'}>
                    <Grid.Row>
                      {metaDataArray.map((data, index) => {
                        return (
                          <Grid.Column md={4} key={index}>
                            <Paper>
                              <MetadataGroup
                                index={index}
                                metadata={data}
                                onKeyChange={this.handleKeyMetadata}
                                onValueChange={this.handleValueMetadata}
                                onCloseClick={this.removeMetadata}
                              />
                            </Paper>
                            <Spacer />
                          </Grid.Column>
                        )
                      })}
                      <Grid.Column md={4}>
                        <Paper>
                          <AddFeatureWrapper>
                            <IconButton
                              color="primary"
                              icon={'plus'}
                              onClick={this.addItemToMetaData}
                            />
                            <AddFeatureText>Agregar nuevo item</AddFeatureText>
                          </AddFeatureWrapper>
                        </Paper>
                        <Spacer />
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <FormSection title={'Agregar imágenes y videos'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            youtubeSupport
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg,image/png"
                            assets={assets}
                            onChange={this.handleAssetsChange}
                          />
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <Mutation
                    mutation={CREATE_PRODUCT_MUTATION}
                    onCompleted={() => {
                      onCreated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Producto creado correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(createProduct, { loading, error }) => (
                      <Mutation mutation={UPLOAD_PRODUCT_FILE}>
                        {(uploadProductFile, {}) => (
                          <ActionWrapper>
                            <SaveButton
                              color="primary"
                              disabled={loading}
                              onClick={async () => {
                                this.validateMetadata()
                                let nextError = this.scrollToError()

                                if (nextError) {
                                  const uploadedImages: AssetType[] = []
                                  const videos: AssetType[] = []

                                  try {
                                    let index = 0
                                    for (const asset of assets) {
                                      index++

                                      if (asset.type === 'video') {
                                        videos.push({
                                          url: asset.url,
                                          type: 'video',
                                          order: index,
                                        })
                                      } else {
                                        const response = await uploadProductFile(
                                          {
                                            variables: { file: asset.file },
                                          }
                                        )

                                        if (response) {
                                          const {
                                            url,
                                          } = response.data.uploadProductFile

                                          uploadedImages.push({
                                            url,
                                            type: 'image',
                                            order: index,
                                          })
                                        }
                                      }
                                    }
                                  } catch (error) {
                                    console.log('error uploading file', error)
                                    return
                                  }

                                  createProduct({
                                    variables: {
                                      input: {
                                        name: name.value,
                                        summary: summary.value,
                                        description: description.value,
                                        brandId: brand.value.id,
                                        priority: Number(priority.value),
                                        supplierId: supplier.value.id,
                                        status: status && status['value'],
                                        features: features.map(feature => ({
                                          name: feature.name.value,
                                          options: feature.options.value,
                                        })),
                                        categories:
                                          categories &&
                                          categories.value.map(
                                            (category: CategoryOption) =>
                                              category && category['id']
                                          ),
                                        assets: [
                                          ...uploadedImages,
                                          ...videos,
                                        ].map(asset => ({
                                          url: asset.url,
                                          type: asset.type,
                                          order: asset.order,
                                        })),
                                        metaData: metaData,
                                      },
                                    },
                                  })
                                }
                              }}
                            >
                              {loading
                                ? 'Creando producto...'
                                : 'Crear Producto'}
                            </SaveButton>
                          </ActionWrapper>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                </React.Fragment>
              )
            }}
          </Modal>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Product
