import React, { useEffect, useState } from 'react'
import gql from 'graphql-tag'
import { withRouter } from 'react-router-dom'
import qs from 'qs'

import { PAGE_SIZE_OPTIONS } from '../../config'
import { ActionsContainer, EditIcon } from './style'
import DataTable from '../DataTableSortable'
import { Tooltip } from '../FormElements'
import { parsePageParams } from '../../helpers/params'
import ProductSelector from '../Selectors/ProductSelector'
import { useApolloClient } from '@apollo/react-hooks'
import { Button } from '../Buttons'

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $options: ProductsOptions!
    $filters: ProductsFilters!
  ) {
    products(options: $options, filters: $filters) {
      nodes {
        id
        slug
        name
        status
        supplier {
          id
          businessName
        }
      }
      pagination {
        total
      }
    }
  }
`

type ProductNode = Record<string, any>

interface Data {
  products: {
    nodes: ProductNode[]
    pagination: {
      total: number
    }
  }
}

const Products = (props: any) => {
  const { productIds, setProductIds } = props
  const { page, s, sort_by } = extractPageParams()
  const pageSize = 300

  const [selectedProduct, setSelectedProduct] = useState<any>()
  const [products, setProducts] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({ total: 0 })
  const client = useApolloClient()

  const handleSupplierChange = (product: any) => {
    setSelectedProduct(product)
  }

  useEffect(
    () => {
      ;(async () => {
        productIds.length > 0 && (await retrieveRecommendedProducts())
      })()
    },
    [productIds]
  )

  const retrieveRecommendedProducts = async () => {
    const productsVariables = {
      options: {
        limit: pageSize,
        offset: page ? (page - 1) * pageSize : 0,
        sortBy: sort_by,
      },
      filters: {
        s,
        ids: productIds,
      },
    }

    const { data }: { data: Data } = await client.query({
      query: GET_PRODUCTS_QUERY,
      variables: productsVariables,
      fetchPolicy: 'network-only',
    })

    const products = data.products.nodes
    const priorizedProducts = productIds.map((idItem: string) => {
      const currentProduct = products.find(
        productItem => productItem.id === idItem
      )
      return currentProduct
    })

    if (data) {
      setProducts(priorizedProducts)
      setPagination(data.products.pagination)
      setLoading(false)
    }
  }

  function extractPageParams() {
    const { page, page_size, s, sort_by } = parsePageParams(
      props.location.search.slice(1)
    )
    return {
      page,
      page_size,
      s,
      sort_by,
    }
  }

  function onPageChange(page: number) {
    const params = extractPageParams()

    params.page = page

    updatePageParams(params)
  }

  function onPageSizeChange(pageSize: number) {
    const params = extractPageParams()

    params.page_size = pageSize

    updatePageParams(params)
  }

  function onSortByChange(sortBy: string | undefined) {
    const params = extractPageParams()

    params.sort_by = sortBy

    updatePageParams(params)
  }

  function updatePageParams(params: object) {
    props.history.push({
      pathname: props.history.location.pathname,
      search: `?${qs.stringify(params)}`,
    })
  }

  const handleAdd = () => {
    const founded = products.find(
      (supplierItem: any) => {
        if (typeof supplierItem !== 'undefined'){
          return supplierItem.id === selectedProduct.id
        }
        return false
      }
    )

    if (!founded) {
      const auxProducts = products
      auxProducts.push(selectedProduct)
      setProducts(auxProducts)
      props.updateParent(selectedProduct)
    }
    setSelectedProduct('')
  }

  const handleRemove = (id: string) => {
    let auxProducts: any = products.filter((product: any) => {
      if (typeof product !== 'undefined'){
        return product.id != id
      }
      return false
    })

    // products.map((product: any) => {
    //   if (product.id != id) {
    //     auxProducts.push(product)
    //   } else {
    props.handleRemoved(id)
    //   }
    // })

    setProducts(auxProducts)
  }

  const columns = [
    { header: 'Nombre Comercial', key: 'name' },
    {
      header: 'Proveedor',
      key: 'businessName',
      Cell: ({ supplier }: any) => {
        return supplier && supplier.businessName
      },
    },
    {
      header: 'Estado',
      key: 'status',
      width: 150,
      Cell: ({ status }: ProductNode) => {
        const statusName = status == 'active' ? 'Activo' : 'Inactivo'
        return statusName
      },
    },
    {
      header: 'Acciones',
      key: 'acciones',
      width: 99,
      sortable: false,
      Cell: ({ id }: ProductNode) => (
        <ActionsContainer>
          <Tooltip
            id={`remove-product-${id}`}
            message="Eliminar de la lista"
            onClick={() => handleRemove(id)}
          >
            <EditIcon name="trash-empty" />
          </Tooltip>
        </ActionsContainer>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          marginBottom: '1rem',
        }}
      >
        <ProductSelector
          value={selectedProduct}
          onChange={handleSupplierChange}
          beginEmpty={true}
        />
        <Button
          color="primary"
          disabled={selectedProduct ? false : true}
          onClick={handleAdd}
        >
          Agregar
        </Button>
      </div>
      <DataTable
        indexKey="id-products"
        columns={columns}
        data={products}
        setData={setProducts}
        setProductIds={setProductIds}
        loading={loading}
        totalItemsCount={pagination.total}
        sortBy={sort_by}
        page={page || 1}
        pageSize={pageSize}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        searchText={s}
        onSort={onSortByChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        placeholderSearchBar={'Buscar por nombre'}
        customTable={true}
      />
    </React.Fragment>
  )
}

export default withRouter(Products)
