import React from 'react'
import {
  IconArea,
  IconAreaLabel,
  IconContainer,
  IconForm,
  IconButton,
} from './style'

interface Props {
  iconSelected: string
  hasParent: boolean
  iconList: Array<{ value: string }>

  onChangeIcon: (selectedIcon: string) => any
}

class IconDisplay extends React.Component<Props> {
  render() {
    const { iconSelected, onChangeIcon, hasParent, iconList } = this.props

    return (
      <IconArea>
        <IconAreaLabel>Icono para la categoría</IconAreaLabel>
        <IconContainer hasPatent={hasParent}>
          <>
            {iconList.map((icon: any) => {
              return (
                <IconButton
                  color="primary"
                  key={icon.value}
                  onClick={() => onChangeIcon(icon.value)}
                  isSelected={iconSelected === icon.value}
                >
                  <IconForm name={icon.value} />
                </IconButton>
              )
            })}
          </>
        </IconContainer>
      </IconArea>
    )
  }
}

export default IconDisplay
