import React from 'react'
import ReactSelect, {
  Creatable as ReactCreatableSelect,
  Async as ReactAsyncSelect,
} from 'react-select'
import { Props as ReactSelectProps } from 'react-select/lib/Select'
import { Props as ReactCreatableSelectProps } from 'react-select/lib/Creatable'
import { Props as ReactAsyncSelectProps } from 'react-select/lib/Async'
import { Field } from '../../helpers/validations'
import { Spacer } from '../Globals'

import 'react-datepicker/dist/react-datepicker.css'

import {
  StyledInput,
  StyledTextArea,
  selectStyles,
  RequiredMark,
  Tip,
  StyledTooltip,
  // HelpIcon,
  InputIcon,
  DropdownIcon,
  StyledLabel,
  StyledError,
  LabelWrapper,
  InputSpan,
  StyledCheckBoxWrapper,
  StyledCheckBox,
  HiddenCheckbox,
  StyledDatePicker,
  DatePickerContainer,
  CheckIcon,
  HiddenRadio,
  StyledRadio,
  StyledToggle,
  RadioIcon,
  SwitchCheckBox,
  SwitchLabel,
  CloseButton,
  CloseIcon,
} from './style'

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  help?: string
  error?: boolean
  icon?: string
  toggle?: boolean
  round?: boolean
  noShowRequired?: boolean
  border?: boolean
  rightAlign?: boolean
}

export const Input = ({
  required,
  noShowRequired,
  help,
  error,
  icon,
  label,
  ref,
  ...props
}: InputProps) => (
  <StyledLabel>
    {(help || label || required) && !noShowRequired && (
      <LabelWrapper>
        {help && <Tooltip message={help} />}
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <StyledInput {...props} error={error} required={required} icon={icon} />
    {icon && (
      <InputSpan>
        <InputIcon name={icon} />
      </InputSpan>
    )}
  </StyledLabel>
)

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  error?: boolean
}

export const TextArea = ({
  label,
  required,
  ref,
  error,
  ...props
}: TextAreaProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <StyledTextArea {...props} required={required} error={error} />
  </StyledLabel>
)

interface SelectProps
  extends ReactSelectProps<{ [key: string]: any } | string | number> {
  error?: boolean
  required?: boolean
  label?: string
  hideIcon?: boolean
  disabled?: boolean
}

export const Select = ({
  label,
  required,
  error,
  hideIcon,
  disabled,
  ...props
}: SelectProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <ReactSelect
      isDisabled={disabled}
      {...props}
      styles={selectStyles(error, disabled)}
      components={{
        DropdownIndicator: () => (
          <DropdownIcon
            disabled={disabled}
            name={hideIcon ? '' : 'sort-down'}
            error={error}
          />
        ),
        IndicatorSeparator: null,
      }}
      noOptionsMessage={() => 'No existen resultados'}
    />
  </StyledLabel>
)

interface CreatableSelectProps
  extends ReactCreatableSelectProps<{ [key: string]: any }> {
  required?: boolean
  disabled?: boolean
  label?: string
  error?: boolean
  hideIcon?: boolean
  noOptionsMessage?: () => string
}

export const CreatableSelect = ({
  label,
  required,
  disabled,
  error,
  noOptionsMessage,
  hideIcon,
  ...props
}: CreatableSelectProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <ReactCreatableSelect
      {...props}
      isDisabled={disabled}
      styles={selectStyles(error)}
      components={{
        DropdownIndicator: () => (
          <DropdownIcon name={hideIcon ? '' : 'sort-down'} error={error} />
        ),
        IndicatorSeparator: null,
      }}
      noOptionsMessage={noOptionsMessage}
    />
  </StyledLabel>
)

interface AsyncSelectProps
  extends ReactAsyncSelectProps<{ [key: string]: any }> {
  required?: boolean
  disabled?: boolean
  label?: string
  error?: boolean
  noResultMessage?: string
  hideIcon?: boolean
  textWidth?: number
}

export const AsyncSelect = ({
  label,
  required,
  disabled,
  error,
  noResultMessage,
  hideIcon,
  textWidth,
  ...props
}: AsyncSelectProps) => (
  <StyledLabel>
    {(label || required) && (
      <LabelWrapper>
        {label && <span>{label}</span>}
        {required && <RequiredMark>*</RequiredMark>}
      </LabelWrapper>
    )}
    <ReactAsyncSelect
      {...props}
      styles={selectStyles(error, disabled, textWidth)}
      isDisabled={disabled}
      components={{
        DropdownIndicator: () => (
          <DropdownIcon name={hideIcon ? '' : 'sort-down'} error={error} />
        ),
        IndicatorSeparator: null,
      }}
      noOptionsMessage={() =>
        noResultMessage ? noResultMessage : 'No existen resultados'
      }
    />
  </StyledLabel>
)

interface TooltipProps {
  id?: string
  message?: string
  children?: React.ReactNode
  onClick?: any
}

export const Tooltip = ({ id, message, children, onClick }: TooltipProps) => (
  <span onClick={onClick}>
    <Tip data-tip data-for={id}>
      {/* TODO: Check how to add help icon  */}
      {children}
    </Tip>

    <StyledTooltip id={id} type="error">
      <span>{message}</span>
    </StyledTooltip>
  </span>
)

export const CheckBox = ({
  id,
  label,
  value,
  onChange,
  checked,
  disabled,
  toggle,
  round,
  border,
  icon,
}: InputProps) => {
  return (
    <StyledLabel>
      <StyledCheckBoxWrapper>
        <HiddenCheckbox
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        {toggle ? (
          <StyledToggle
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            round={true}
          />
        ) : (
          <StyledCheckBox
            onChange={onChange}
            checked={checked}
            disabled={disabled}
            round={round}
            border={border}
          >
            <CheckIcon name={icon ? icon : 'ok'} />
          </StyledCheckBox>
        )}

        <LabelWrapper>{label && <span>{label}</span>}</LabelWrapper>
      </StyledCheckBoxWrapper>
    </StyledLabel>
  )
}

export const Switch = ({ id, onChange, checked, disabled }: InputProps) => {
  return (
    <>
      <SwitchCheckBox
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <SwitchLabel htmlFor={id}>
        <span />
      </SwitchLabel>
    </>
  )
}

export const RadioOption = ({
  id,
  label,
  onChange,
  value,
  checked,
  disabled,
}: InputProps) => {
  return (
    <StyledLabel>
      <StyledCheckBoxWrapper>
        <HiddenRadio
          name={'name'}
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />

        <StyledRadio onChange={onChange} disabled={disabled}>
          {checked ? <RadioIcon /> : ''}
        </StyledRadio>

        {<LabelWrapper>{label && <span>{label}</span>}</LabelWrapper>}
      </StyledCheckBoxWrapper>
    </StyledLabel>
  )
}

interface ErrorProps {
  children: React.ReactNode
}

export const Error = (props: ErrorProps) => (
  <StyledError>{props.children}</StyledError>
)

interface DatePickerProps {
  error?: boolean
  required?: boolean
  disabled?: boolean
  dateFormat?: string
  label: string
  date: Date
  minDate?: Date
  maxDate?: Date
  showTimeSelectOnly?: boolean
  timeCaption?: string
  showTimeSelect?: boolean
  onChange: (date: Date) => void
}

export const DatePicker = ({
  required,
  disabled,
  error,
  label,
  date,
  onChange,
  dateFormat,
  minDate,
  showTimeSelect,
  maxDate,
  showTimeSelectOnly,
  timeCaption,
  ...props
}: DatePickerProps) => (
  <React.Fragment>
    <StyledLabel>
      {(label || required) && (
        <LabelWrapper>
          {label && <span>{label}</span>}
          {required && <RequiredMark>*</RequiredMark>}
        </LabelWrapper>
      )}
    </StyledLabel>
    <DatePickerContainer>
      <StyledDatePicker
        id={'date-picker-' + label.trim()}
        disabled={disabled}
        error={error}
        selected={date}
        maxDate={maxDate}
        minDate={minDate}
        onChange={onChange}
        dateFormat={dateFormat}
        showMonthDropdown
        showYearDropdown
        showTimeSelect={showTimeSelect}
        timeCaption={timeCaption}
        showTimeSelectOnly={showTimeSelectOnly}
      />
      <InputSpan>
        <InputIcon disabled={disabled} error={error} name="calendar" />
      </InputSpan>
    </DatePickerContainer>
  </React.Fragment>
)

type metadata = {
  key: Field
  value: Field
}
interface Props {
  index: number
  error?: string
  metadata: metadata
  value?: string
  oldOptions?: string[]
  disabled?: boolean
  required?: boolean

  onKeyChange: (index: number, key: string) => void
  onValueChange: (index: number, value: string) => void
  onCloseClick: (featureIndex: number) => void
}

export const MetadataGroup = ({
  index,
  metadata,
  onKeyChange,
  onValueChange,
  onCloseClick,
}: Props) => (
  <React.Fragment>
    <CloseButton
      onClick={() => {
        onCloseClick && onCloseClick(index)
      }}
    >
      <CloseIcon name="cancel" />
    </CloseButton>
    <Input
      id={`key-${index}`}
      required
      label="Clave"
      type="text"
      value={metadata.key.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onKeyChange(index, e.target.value)
      }
      error={metadata.key.error}
    />
    {metadata.key.error ? <Error>{metadata.key.errorText}</Error> : <Spacer />}
    <Input
      id={`value-${index}`}
      required
      label="Valor"
      type="text"
      value={metadata.value.value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onValueChange(index, e.target.value)
      }
      error={metadata.value.error}
    />
    {metadata.value.error ? (
      <Error>{metadata.value.errorText}</Error>
    ) : (
      <Spacer />
    )}
  </React.Fragment>
)
