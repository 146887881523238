import React from 'react'
import { Link } from 'react-router-dom'

import PageWrapper from './../../../components/PageWrapper'
import Paper from '../../../components/Paper'
import SpecialtiesTable from './components/SpecialtiesTable'

import { PageTopSection, CreateSpecialtyButton } from './style'

const SpecialtiesList = () => (
  <PageWrapper title="Especialidades">
    <PageTopSection>
      <Link to="/specialties/create">
        <CreateSpecialtyButton icon="plus" color="success">
          Crear Especialidad
        </CreateSpecialtyButton>
      </Link>
    </PageTopSection>
    <Paper>
      <SpecialtiesTable />
    </Paper>
  </PageWrapper>
)

export default SpecialtiesList
