import React from 'react'

import Icon from '../../Icon'
import Page from './Page'

import {
  PaginationContainer,
  FirstPage,
  PrevPage,
  NextPage,
  LastPage,
} from '../style'

interface PaginationProps {
  activePage: number
  itemsCountPerPage?: number
  totalItemsCount: number
  onChange: (pageNumber: number) => void
}

class Pagination extends React.Component<PaginationProps> {
  buildCurrentPages = (totalPages: number) => {
    const { activePage, onChange } = this.props

    const LEFT_RANGE = 2
    const RIGHT_RANGE = 2

    const pages = []

    let from = 1
    let to = totalPages

    if (
      activePage > RIGHT_RANGE + 1 &&
      totalPages > 1 + (LEFT_RANGE + RIGHT_RANGE)
    ) {
      from =
        activePage + RIGHT_RANGE >= totalPages
          ? totalPages - (LEFT_RANGE + RIGHT_RANGE)
          : activePage - LEFT_RANGE
    }

    if (
      activePage < totalPages - LEFT_RANGE &&
      totalPages > 1 + (LEFT_RANGE + RIGHT_RANGE)
    ) {
      to =
        activePage - LEFT_RANGE <= 1
          ? 1 + (LEFT_RANGE + RIGHT_RANGE)
          : activePage + RIGHT_RANGE
    }

    for (let index = from; index <= to; index++) {
      const isActivePage = activePage === index

      pages.push(
        <Page
          key={index}
          first={index === from}
          last={index === to}
          pageNumber={index}
          onClick={onChange}
          active={isActivePage}
          disabled={isActivePage}
        />
      )
    }

    return pages
  }

  render() {
    const {
      activePage,
      totalItemsCount,
      itemsCountPerPage,
      onChange,
    } = this.props

    const itemsPerPage = itemsCountPerPage || 10
    const totalPages = Math.ceil(totalItemsCount / itemsPerPage)

    const pages = this.buildCurrentPages(totalPages)

    return pages && pages.length > 1 ? (
      <PaginationContainer>
        <FirstPage
          pageNumber={1}
          pageContent="Primero"
          onClick={onChange}
          disabled={activePage === 1}
        />
        <PrevPage
          pageNumber={activePage - 1}
          pageContent={<Icon name="left-dir" />}
          onClick={onChange}
          disabled={activePage === 1}
        />
        {pages}
        <NextPage
          pageNumber={activePage + 1}
          pageContent={<Icon name="right-dir" />}
          onClick={onChange}
          disabled={activePage === totalPages}
        />
        <LastPage
          pageNumber={totalPages}
          pageContent="Último"
          onClick={onChange}
          disabled={activePage === totalPages}
        />
      </PaginationContainer>
    ) : (
      <></>
    )
  }
}

export default Pagination
