import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Modal from '../Modals/ModalRoot'
import { Input } from '../FormElements'
import { Button } from '../Buttons'
import Image from './Image'
import Video from './Video'
import File from './File'
import { ImageDropzone, FileSelector, PreviewImg, PreviewIframe } from './style'

const reorder = (list: any, startIndex: any, endIndex: any): any => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export interface Asset {
  id?: string
  type: string
  url: string
  file?: any
  order?: number
  platform_type?: string
}

interface Props {
  assets: Asset[]
  textButton: string
  colorButton: string
  youtubeSupport?: boolean
  dropZoneSupport?: boolean
  disabled?: boolean
  accept: string
  platformType?: string
  onChange: (assets: Asset[]) => void
}

interface State {
  video: string
}

class ImageSelector extends React.Component<Props, State> {
  dropzoneRef: any

  state: State = {
    video: '',
  }

  onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const { assets, onChange } = this.props

    const newAssets = reorder(
      assets,
      result.source.index,
      result.destination.index
    )

    onChange(newAssets)
  }

  handleVideoInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ video: value })
  }

  onAddVideoClick = () => {
    const { assets, onChange } = this.props
    const { video } = this.state

    // Don't add repeated videos
    if (assets.some(asset => asset.url === video)) {
      return
    }

    // TODO: Validate video url
    if (video.trim() === '') {
      return
    }

    const newAssets = [
      ...assets,
      {
        type: 'video',
        url: video,
        file: null,
      },
    ]
    this.setState({ video: '' })

    onChange(newAssets)
  }

  onFileDrop = (files: any) => {
    if (files.length === 0) {
      return
    }

    const { assets, onChange, platformType } = this.props
    const newAssets = [
      ...assets,
      ...files.map((file: any) => {
        let asset: Asset = {
          type: '',
          url: file.preview,
          file,
        }

        platformType && platformType != undefined
          ? (asset.platform_type = platformType)
          : ''
        switch (file.type) {
          case 'image/jpg':
          case 'image/jpeg':
          case 'image/png':
            asset.type = 'image'
            break
          case 'application/pdf':
            asset.type = 'pdf'
            break
          case 'application/msword':
            asset.type = 'word'
            break
          case 'text/csv':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            asset.type = 'excel'
            break
          default:
            asset.type = asset.type
            break
        }
        return asset
      }),
    ]
    onChange(newAssets)
  }

  onAssetRemove = (index: number) => {
    const { assets, onChange } = this.props

    const newAssets = [...assets]

    newAssets.splice(index, 1)

    onChange(newAssets)
  }

  render() {
    const { video } = this.state
    const {
      youtubeSupport,
      dropZoneSupport,
      disabled,
      accept,
      textButton,
      colorButton,
    } = this.props

    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              width: '300px',
              marginRight: '20px',
            }}
            color={
              colorButton === 'primary' ||
              colorButton === 'secondary' ||
              colorButton === 'success' ||
              colorButton === 'tertiary'
                ? colorButton
                : 'primary'
            }
            onClick={() => {
              this.dropzoneRef.open()
            }}
            disabled={disabled}
          >
            {textButton}
          </Button>
          {youtubeSupport && (
            <div
              style={{
                position: 'relative',
                width: '100%',
              }}
            >
              <Input
                style={{ paddingRight: '100px' }}
                placeholder={'Url de youtube'}
                value={video}
                onChange={this.handleVideoInputChange}
              />
              <Button
                style={{
                  position: 'absolute',
                  right: '1px',
                  top: '1px',
                  padding: '12px 5px',
                  width: '94px',
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                }}
                color="secondary"
                onClick={this.onAddVideoClick}
              >
                Agregar
              </Button>
            </div>
          )}
        </div>

        {dropZoneSupport ? (
          <Modal>
            {({ openModal }) => (
              <div
                style={{
                  marginTop: '30px',
                  borderRadius: '10px',
                  border: 'solid 2px #c5c5c5',
                  borderStyle: 'dashed',
                  display: 'flex',
                }}
              >
                <ImageDropzone
                  innerRef={(node: any) => {
                    this.dropzoneRef = node
                  }}
                  accept={accept}
                  onDrop={this.onFileDrop}
                  disableClick
                >
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{
                            display: 'flex',
                            overflow: 'auto',
                            padding: 20,
                          }}
                        >
                          {this.props.assets.map((asset, index) => (
                            <Draggable
                              key={asset.url}
                              draggableId={`${asset.url}`}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    width: 80,
                                    minWidth: 80,
                                    height: 80,
                                    margin: '0 10px',
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  {asset.type === 'image' ? (
                                    <Image
                                      url={asset.url}
                                      onPreviewClick={() =>
                                        openModal('DETAILS', {
                                          header: {},
                                          description: (
                                            <PreviewImg
                                              src={asset.url}
                                              alt="preview"
                                            />
                                          ),
                                          showCloseTop: true,
                                          modalSize: 'lg',
                                        })
                                      }
                                      onRemove={() => this.onAssetRemove(index)}
                                    />
                                  ) : asset.type === 'video' ? (
                                    <Video
                                      url={asset.url}
                                      onPreviewClick={() =>
                                        openModal('DETAILS', {
                                          header: {},
                                          description: (
                                            <PreviewIframe
                                              src={asset.url}
                                              frameBorder="0"
                                              allow="autoplay; encrypted-media"
                                              allowFullScreen
                                            />
                                          ),
                                          showCloseTop: true,
                                          modalSize: 'lg',
                                        })
                                      }
                                      onRemove={() => this.onAssetRemove(index)}
                                    />
                                  ) : (
                                    <File
                                      type={asset.type}
                                      url={asset.url}
                                      onPreviewClick={() => {
                                        if (asset.type == 'pdf') {
                                          openModal('DETAILS', {
                                            header: {},
                                            description: (
                                              <PreviewIframe
                                                src={asset.url}
                                                frameBorder="0"
                                                allow="autoplay; encrypted-media"
                                                allowFullScreen
                                              />
                                            ),
                                            showCloseTop: true,
                                            modalSize: 'lg',
                                          })
                                        }
                                      }}
                                      onRemove={() => this.onAssetRemove(index)}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </ImageDropzone>
              </div>
            )}
          </Modal>
        ) : (
          <FileSelector
            innerRef={(node: any) => {
              this.dropzoneRef = node
            }}
            accept={accept}
            onDrop={this.onFileDrop}
            disableClick
          />
        )}
      </React.Fragment>
    )
  }
}

export default ImageSelector
