import React from 'react'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'

import { AsyncSelect } from '../../FormElements'

import Loading from '../../Loader'
import { Spacer } from '../../Globals'

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $options: ProductsOptions!
    $filters: ProductsFilters!
  ) {
    products(options: $options, filters: $filters) {
      nodes {
        id
        slug
        name
        status
        supplier {
          id
          businessName
        }
      }
      pagination {
        total
      }
    }
  }
`

type Product = { [key: string]: any }
export type ProductOption = ValueType<{ [key: string]: any }>

interface Data {
  products: {
    nodes: Product[]
  }
}

interface Props {
  value: ProductOption | ProductOption[] | null
  onChange: (product: ProductOption) => void
  isMulti?: boolean
  disabled?: boolean
  error?: boolean
  beginEmpty?: boolean
}

const ProductSelector = ({
  value,
  onChange,
  isMulti,
  disabled,
  error,
  beginEmpty = false,
}: Props) => (
  <Query
    query={GET_PRODUCTS_QUERY}
    fetchPolicy="network-only"
    variables={{ options: { limit: !beginEmpty ? 10 : 0 }, filters: {} }}
  >
    {({ loading, error: errorQuery, data, client }: QueryResult<Data, any>) => {
      if (loading) {
        return (
          <div id={'product'}>
            <Spacer />
            <Loading />
          </div>
        )
      }
      if (errorQuery || !data) {
        return (
          <p>
            Error cargando proveedores. <br />
            Por favor recargue la página.
          </p>
        )
      }

      return (
        <AsyncSelect
          id="productSelect"
          label={!beginEmpty ? 'Proveedor' : ''}
          placeholder="Seleccione un producto"
          required={false}
          disabled={disabled}
          inputId={'productID'}
          value={value}
          isMulti={isMulti ? isMulti : false}
          onChange={onChange}
          getOptionLabel={({ name }: Product) => name}
          getOptionValue={({ id }: Product) => id}
          backspaceRemovesValue={false}
          defaultOptions={data.products.nodes}
          cacheOptions
          error={error}
          loadOptions={async (inputValue) => {
            const { data } = await client.query<Data>({
              query: GET_PRODUCTS_QUERY,
              fetchPolicy: 'network-only',
              variables: {
                options: { limit: 10 },
                filters: { s: inputValue },
              },
            })

            if (!data) {
              return []
            }

            return data.products.nodes
          }}
        />
      )
    }}
  </Query>
)

export default ProductSelector
