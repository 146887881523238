import React from 'react'
import Modal from '../../../../components/Modals/ModalRoot'
import Paper from '../../../../components/Paper'
import * as Grid from '../../../../components/Grid'
import {
  CheckBox,
  DatePicker,
  Error,
  Input,
  Select,
} from '../../../../components/FormElements'
import { ActionWrapper, Spacer } from '../../../../components/Globals'
import {
  Field,
  getElements,
  initialField,
  scrollTo,
  validateField,
  validateForm,
  validateIdentificationNumber,
} from '../../../../helpers/validations'
import FormSection from '../../../../components/FormSection'
import { Mutation } from 'react-apollo'
import ThemeContext from '../../../../context/ThemeContext'
import { CancelButton } from '../../../../components/Buttons'
import {
  SaveButton,
  Padding_top,
  CheckBoxContainer,
} from '../../../Coupons/Form/style'
import { gql } from 'apollo-boost'
import { IDENTIFICATION_OPTION } from '../../../../config'

const CREATE_COUPON_MUTATION = gql`
  mutation createCouponMutation($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      id
    }
  }
`

interface Props {
  onCreated: () => void
}

interface State {
  identificationType: Field
  identification: Field
  firstName: Field
  lastName: Field
  description: Field
  redeem: boolean
  value: Field
  expires: boolean
  expiresFrom: Date
  expiresTo: Date
  is_general: boolean
}
const inputIds = [
  'identification',
  'status',
  'firstName',
  'lastName',
  'redeem',
  'value',
  'expires',
  'is_general',
  'description',
]
const selectsRequired = ['identification', 'value', 'firstName', 'lastName']
class CreateCouponForm extends React.Component<Props, State> {
  state: State = {
    identificationType: {
      ...initialField,
      value: { value: 'CI', label: 'Cédula' },
    },
    identification: initialField,
    firstName: initialField,
    lastName: initialField,
    description: initialField,
    redeem: false,
    value: initialField,
    expires: true,
    expiresFrom: new Date(),
    expiresTo: new Date(),
    is_general: false,
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    error = false

    if (!this.state.is_general) {
      this.setState(prevState => {
        for (const key in errorsMap) {
          prevState[key] = errorsMap[key]
        }
        return prevState
      })

      let errorsMapSelects: Field[] = [
        {
          value: '',
          error: false,
          errorText: '',
        },
      ]

      selectsRequired.forEach(id => {
        if (this.state[id] && !this.state[id].value) {
          error = true
          errorsMapSelects[id] = {
            value: '',
            error: true,
            errorText: 'Campo requerido',
          }
        }
      })

      errorsMapSelects['features'] = []

      this.setState(prevState => {
        for (const key in errorsMapSelects) {
          prevState[key] = errorsMapSelects[key]
        }
        return prevState
      })

      inputIds.map(id => {
        if (this.state[id] && this.state[id].error) {
          scrollTo(inputs[id].element)
          error = true
        }
      })
    }

    return error
  }

  handleIdentificationTypeChange = (value: any) => {
    this.setState({
      identificationType: {
        value,
        error: false,
        errorText: '',
      },
    })
  }

  handleIdentificationChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = value
    const { value: identificationType } = this.state.identificationType.value
    const validations = validateIdentificationNumber(identificationType, value)
    if (validations.value) {
      this.setState({
        identification: {
          value: valueInput,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      identification: {
        value: valueInput,
        error: false,
        errorText: '',
      },
    })
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = value

    const validations = validateField(valueInput, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        firstName: {
          value: valueInput,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      firstName: {
        value: valueInput,
        error: false,
        errorText: '',
      },
    })
  }
  handleLastNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = value

    const validations = validateField(valueInput, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        lastName: {
          value: valueInput,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      lastName: {
        value: valueInput,
        error: false,
        errorText: '',
      },
    })
  }
  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = value

    const validations = validateField(valueInput, [
      {
        name: 'shorterThan',
        value: 20,
      },
    ])

    if (validations.value) {
      this.setState({
        description: {
          value: valueInput,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      description: {
        value: valueInput,
        error: false,
        errorText: '',
      },
    })
  }

  handleValueChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const valueInput = value

    this.setState({
      value: {
        value: parseFloat(valueInput),
        error: false,
        errorText: '',
      },
    })
  }
  render() {
    const {
      identificationType,
      identification,
      firstName,
      lastName,
      redeem,
      description,
      value,
      expires,
      expiresFrom,
      expiresTo,
      is_general,
    } = this.state

    //const { onCreated } = this.props
    return (
      <ThemeContext.Consumer>
        {notify => (
          <Modal>
            {({ openModal }) => {
              return (
                <React.Fragment>
                  <FormSection
                    title="Información General"
                    openedByDefault={true}
                  >
                    <Paper>
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Padding_top>
                            <CheckBoxContainer>
                              <CheckBox
                                id="is_general"
                                checked={is_general}
                                onChange={(e: any) => {
                                  this.setState({
                                    is_general: e.target.checked,
                                  })
                                }}
                                label="¿Es un cupón general?"
                              />
                            </CheckBoxContainer>
                          </Padding_top>
                        </Grid.Column>
                      </Grid.Row>
                      {!is_general && (
                        <Grid.Row>
                          <Grid.Column md={6}>
                            <Select
                              id={'identificationType'}
                              label="Tipo de identificación"
                              placeholder="Seleccione una opción"
                              options={IDENTIFICATION_OPTION}
                              error={identificationType.error}
                              value={identificationType.value}
                              onChange={this.handleIdentificationTypeChange}
                            />
                            {identificationType.error ? (
                              <Error>{identificationType.errorText}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </Grid.Column>

                          <Grid.Column md={6}>
                            <Input
                              id="identification"
                              value={identification.value}
                              type="text"
                              error={identification.error}
                              onChange={this.handleIdentificationChange}
                              label="Identificación"
                              required={!is_general}
                            />
                            {identification.error ? (
                              <Error>{identification.errorText}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </Grid.Column>
                          <Grid.Column md={6}>
                            <Input
                              id="firstName"
                              value={firstName.value}
                              type="text"
                              error={firstName.error}
                              onChange={this.handleNameChange}
                              label="Nombre"
                            />
                            {firstName.error ? (
                              <Error>{firstName.errorText}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </Grid.Column>
                          <Grid.Column md={6}>
                            <Input
                              id="lastName"
                              value={lastName.value}
                              type="text"
                              error={lastName.error}
                              onChange={this.handleLastNameChange}
                              label="Apellido"
                            />
                            {lastName.error ? (
                              <Error>{lastName.errorText}</Error>
                            ) : (
                              <Spacer />
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      <Grid.Row>
                        <Grid.Column md={6}>
                          <Input
                            id="value"
                            value={value.value}
                            error={value.error}
                            onChange={this.handleValueChange}
                            type="number"
                            label="Valor"
                            required
                          />
                          {value.error ? (
                            <Error>{value.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={6}>
                          <Input
                            id="description"
                            value={description.value}
                            type="text"
                            error={description.error}
                            onChange={this.handleDescriptionChange}
                            label="Palabra clave cupón"
                            required
                          />
                          {description.error ? (
                            <Error>{description.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <DatePicker
                            label="Desde"
                            date={expiresFrom}
                            onChange={e => this.setState({ expiresFrom: e })}
                            dateFormat="yyyy-MM-dd h:mm:ss"
                            showTimeSelect
                          />
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <DatePicker
                            label="Hasta"
                            date={expiresTo}
                            onChange={e => this.setState({ expiresTo: e })}
                            dateFormat="yyyy-MM-dd h:mm:ss"
                            showTimeSelect
                          />
                        </Grid.Column>
                        <Grid.Column md={4} className="something-else">
                          <Padding_top>
                            <CheckBox
                              id="expires"
                              checked={expires}
                              onChange={(e: any) => {
                                this.setState({ expires: e.target.checked })
                              }}
                              label="¿ Expira ?"
                            />
                          </Padding_top>
                        </Grid.Column>
                      </Grid.Row>
                    </Paper>
                  </FormSection>
                  <Mutation
                    mutation={CREATE_COUPON_MUTATION}
                    onCompleted={() => {
                      this.props.onCreated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Cupón creado correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(createCoupon, { loading, error }) => {
                      return (
                        <ActionWrapper>
                          <CancelButton color="secondary" to="coupons">
                            Cancelar
                          </CancelButton>
                          <SaveButton
                            color="primary"
                            disabled={loading}
                            onClick={() => {
                              let nextError = this.scrollToError()
                              if (nextError) {
                                return
                              }

                              createCoupon({
                                variables: {
                                  input: {
                                    identification: identification.value,
                                    firstName: firstName.value,
                                    lastName: lastName.value,
                                    description: description.value,
                                    redeem,
                                    value: value.value,
                                    expires,
                                    expiresFrom: expiresFrom.toISOString(),
                                    expiresTo: expiresTo.toISOString(),
                                    is_general,
                                  },
                                },
                              })
                            }}
                          >
                            {loading ? 'Creando cupón...' : 'Crear Cupón'}
                          </SaveButton>
                        </ActionWrapper>
                      )
                    }}
                  </Mutation>
                </React.Fragment>
              )
            }}
          </Modal>
        )}
      </ThemeContext.Consumer>
    )
  }
}
export default CreateCouponForm
