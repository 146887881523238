import React from 'react'
import {
  SearchForm,
  SearchLabel,
  SearchBox,
  SearchInput,
  SearchButton,
} from '../style'

interface SearchBarProps {
  defaultSearchText?: string | null
  onSearch: (searchText: string) => void
  placeholder?: string
  pattern?: string
  title?: string
}

interface SearchBarState {
  searchText: string
}

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
  state = {
    searchText: this.props.defaultSearchText || '',
  }

  onSearchTextChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: value })
  }

  onSearchFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    this.props.onSearch(this.state.searchText)
  }

  render() {
    const { searchText } = this.state
    const { placeholder, pattern, title } = this.props

    return (
      <SearchForm onSubmit={this.onSearchFormSubmit}>
        <SearchLabel>Buscador</SearchLabel>
        <SearchBox>
          <SearchInput
            value={searchText}
            onChange={this.onSearchTextChange}
            placeholder={placeholder}
            pattern={pattern}
            title={title}
          />
          <SearchButton color="secondary" icon="search">
            Buscar
          </SearchButton>
        </SearchBox>
      </SearchForm>
    )
  }
}

export default SearchBar
