import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import gql from 'graphql-tag'
import { Query, QueryResult } from 'react-apollo'
import {
  TableContainer,
  Container,
  ColumnDescription,
  ColumnTitle,
  Title,
} from './../style'
import DataTable from '../../../../components/DataTable'
import { Spacer } from '../../../../components/Globals'

const GET_ORDER_BY_ID = gql`
  query getOrderById($id: ID!) {
    order(id: $id) {
      number
      status
      invoiceNumber
      invoiceDate
      createdAt
      customer {
        firstName
        lastName
        businessName
        identificationType
        identificationNumber
        email
        phone
        mobile
        address
      }
      address {
        street1
        street2
        state
        city
        zone
        email
        customerName
        phone
        number
      }
      lineItems {
        id
        variation {
          product {
            name
          }
        }
        price
      }
    }
  }
`

type DataNode = Record<string, any>

interface Data {
  order: {
    number: string
    transactions: DataNode[]
    lineItems: DataNode[]
    address: DataNode
    customer: DataNode
  }
}

interface RouterParams
  extends RouteComponentProps<{
      id?: string
      action?: string
      history?: string
      location?: string
    }> {}

function OrderTransactionTable({
  match: {
    params: { id },
  },
}: RouterParams) {
  return (
    <Query
      variables={{
        id: id,
      }}
      query={GET_ORDER_BY_ID}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }: QueryResult<Data, any>) => {
        if (loading) {
          return <p>Loading...</p>
        }
        if (error) {
          return <p>Error</p>
        }
        if (!data) {
          return <p>No Data</p>
        }
        if (!data.order) {
          return <p>No Items</p>
        }

        const {
          number,
          lineItems,
          // transactions,
          address,
          customer,
        } = data.order

        const lineItemscolumns = [
          {
            header: 'Nombre producto',
            key: 'productName',
            sortable: false,
            Cell: (lineItems: DataNode) => {
              return (
                lineItems.variation &&
                lineItems.variation.product &&
                lineItems.variation.product.name
              )
            },
          },
          {
            header: 'Precio',
            key: 'price',
            sortable: false,
            Cell: (lineItems: DataNode) => {
              return lineItems.price
            },
          },
        ]

        // const columns = [
        //   {
        //     header: 'Código',
        //     key: 'requestCode',
        //     sortable: false,
        //     Cell: (transaction: DataNode) => {
        //       return transaction.requestCode
        //     },
        //   },
        //   {
        //     header: 'Estado',
        //     key: 'status',
        //     sortable: false,
        //     Cell: (transaction: DataNode) => {
        //       return transaction.status
        //     },
        //   },
        //   {
        //     header: 'Mensaje',
        //     key: 'message',
        //     sortable: false,
        //     Cell: (transaction: DataNode) => {
        //       return transaction.message
        //     },
        //   },
        // ]

        return (
          <React.Fragment>
            <Title>ORDEN #{number}</Title>
            <Spacer />
            DATOS DEL CLIENTE
            <Spacer />
            <TableContainer>
              <Container>
                <ColumnTitle>Nombre persona entrega </ColumnTitle>
                <ColumnDescription>{address.customerName}</ColumnDescription>
                <ColumnTitle>Provincia entrega </ColumnTitle>
                <ColumnDescription>{address.state}</ColumnDescription>
                <ColumnTitle>Ciudad entrega </ColumnTitle>
                <ColumnDescription>{address.city}</ColumnDescription>
                <ColumnTitle>Zona entrega </ColumnTitle>
                <ColumnDescription>{address.zone}</ColumnDescription>
                <ColumnTitle>Dirección entrega </ColumnTitle>
                <ColumnDescription>
                  {address.street1 +
                    ' ' +
                    address.street2 +
                    ' ' +
                    address.number}
                </ColumnDescription>
                <ColumnTitle>Teléfono entrega </ColumnTitle>
                <ColumnDescription>{address.phone}</ColumnDescription>
                <ColumnTitle>Correo entrega </ColumnTitle>
                <ColumnDescription>{address.email}</ColumnDescription>
              </Container>
              <Container>
                <ColumnTitle>Tipo identificación facturación </ColumnTitle>
                <ColumnDescription>
                  {customer && customer.identificationType}
                </ColumnDescription>
                <ColumnTitle>Número identificación facturación </ColumnTitle>
                <ColumnDescription>
                  {customer && customer.identificationNumber}
                </ColumnDescription>
                <ColumnTitle>Nombre facturación </ColumnTitle>
                <ColumnDescription>
                  {customer &&
                    customer.businessName +
                      customer.firstName +
                      ' ' +
                      customer.lastName}
                </ColumnDescription>
                <ColumnTitle>Correo facturación </ColumnTitle>
                <ColumnDescription>
                  {customer && customer.email}
                </ColumnDescription>
                <ColumnTitle>Teléfono facturación </ColumnTitle>
                <ColumnDescription>
                  {customer && customer.phone}
                </ColumnDescription>
                <ColumnTitle>Celular facturación </ColumnTitle>
                <ColumnDescription>
                  {customer && customer.mobile}
                </ColumnDescription>
                <ColumnTitle>Dirección facturación </ColumnTitle>
                <ColumnDescription>
                  {customer && customer.address}
                </ColumnDescription>
              </Container>
            </TableContainer>
            <Spacer />
            PRODUCTOS DE LA ORDEN
            <DataTable
              indexKey="id"
              columns={lineItemscolumns}
              data={lineItems}
              loading={loading}
              pageSize={0}
              pageSizeOptions={[]}
              onSort={() => {}}
              onPageSizeChange={() => {}}
            />
            <Spacer />
            {/* TRANSACCIONES */}
            {/* <DataTable
              indexKey="requestCode"
              columns={columns}
              data={transactions}
              loading={loading}
              pageSize={0}
              pageSizeOptions={[]}
              onSort={() => { }}
              onPageSizeChange={() => { }}
            /> */}
          </React.Fragment>
        )
      }}
    </Query>
  )
}

export default withRouter(OrderTransactionTable)
