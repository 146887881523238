import React from 'react'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import { ValueType } from 'react-select/lib/types'
import Modal from '../../../../components/Modals/ModalRoot'
import { STATUS_OPTIONS_SUPPLIERS } from '../../../../config'
import { SaveButton } from '../style'
import FormSection from '../../../../components/FormSection'
import * as Grid from '../../../../components/Grid'
import Paper from '../../../../components/Paper'
import { Spacer, ActionWrapper } from '../../../../components/Globals'
import { CancelButton } from '../../../../components/Buttons'

import AssetSelector from '../../../../components/AssetSelector'
import { Input, Select, Error } from '../../../../components/FormElements'

import ThemeContext from '../../../../context/ThemeContext'
import {
  validateField,
  InputElements,
  getElements,
  validateForm,
  Field,
  initialField,
  scrollTo,
} from '../../../../helpers/validations'

const UPLOAD_PROMOTION_FILE = gql`
  mutation uploadSupplierFile($file: Upload!) {
    uploadSupplierFile(file: $file) {
      url
      fileName
    }
  }
`

const CREATE_PROMOTION_MUTATION = gql`
  mutation createPromotionMutation($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      id
    }
  }
`

interface Props {
  onCreated: () => void
}

// interface AssetData {
//   url: string
//   type: string
//   file: any
// }

interface State {
  name: Field
  city: Field
  order: Field
  description: Field
  url: Field
  status: ValueType<string | number | { [key: string]: any }>
  createError: boolean
  inputs: InputElements[]
  assetsError: boolean
  assets: Array<{
    url: string
    type: string
    file: any
    order: number
  }>
}

type AssetType = {
  url: string
  type: string
  order?: number
}

const inputIds = ['name', 'city', 'status', 'order', 'description', 'url']

class Promotion extends React.Component<Props, State> {
  state: State = {
    name: initialField,
    city: initialField,
    order: initialField,
    description: initialField,
    url: initialField,
    status: { value: 'active', label: 'Activo' },
    createError: false,
    inputs: [],
    assets: [],
    assetsError: false,
  }

  handleNameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const name = value

    const validations = validateField(name, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        name: {
          value: name,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      name: {
        value: name,
        error: false,
        errorText: '',
      },
    })
  }

  handleCityChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const city = value

    const validations = validateField(city, [
      {
        name: 'shorterThan',
        value: 150,
      },
    ])

    if (validations.value) {
      this.setState({
        city: {
          value: city,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      city: {
        value: city,
        error: false,
        errorText: '',
      },
    })
  }

  handleUrlChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const url = value

    const validations = validateField(url, [
      {
        name: 'shorterThan',
        value: 500,
      },
    ])

    if (validations.value) {
      this.setState({
        url: {
          value: url,
          error: validations.value,
          errorText: validations.text,
        },
      })
      return
    }

    this.setState({
      url: {
        value: url,
        error: false,
        errorText: '',
      },
    })
  }

  handleStatusChange = (
    status: ValueType<string | number | { [key: string]: any }>
  ) => {
    this.setState({ status })
  }

  handleAssetsChange = (assets: any[]) => {
    this.setState({ assets })
  }

  handleOrderChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const order = value

    this.setState({
      order: {
        value: order,
        error: false,
        errorText: '',
      },
    })
  }

  handleDescriptionChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const description = value
    this.setState({
      description: {
        value: description,
        error: description == '',
        errorText: 'Campo Obligatorio',
      },
    })
  }

  scrollToError = () => {
    const inputs = getElements(inputIds)
    let { error, errorsMap } = validateForm(inputs, inputIds)
    this.setState(prevState => {
      for (const key in errorsMap) {
        prevState[key] = errorsMap[key]
      }
      return prevState
    })

    let errorsMapSelects: Field[] = [
      {
        value: '',
        error: false,
        errorText: '',
      },
    ]

    errorsMapSelects['features'] = []

    this.setState(prevState => {
      for (const key in errorsMapSelects) {
        prevState[key] = errorsMapSelects[key]
      }
      return prevState
    })

    inputIds.map(id => {
      if (this.state[id] && this.state[id].error) {
        scrollTo(inputs[id].element)
        error = false
      }
    })
    return error
  }

  validateAssets = () => {
    const { assets } = this.state

    let assetsValidation = false

    if (assets.length === 0) {
      assetsValidation = true
    }

    this.setState({
      assetsError: assetsValidation,
    })

    return assetsValidation
  }

  async mapElements() {
    const update = getElements(inputIds)
    this.setState({ inputs: update })
  }

  componentDidMount() {
    this.mapElements()
  }

  render() {
    const {
      name,
      city,
      order,
      status,
      description,
      url,
      assets,
      assetsError,
    } = this.state

    const { onCreated } = this.props
    return (
      <ThemeContext.Consumer>
        {notify => (
          <Modal>
            {({ openModal }) => {
              return (
                <React.Fragment>
                  <FormSection
                    title="Información General"
                    openedByDefault={true}
                  >
                    <Paper>
                      <Grid.Row>
                        <Grid.Column md={4}>
                          <Input
                            id="name"
                            value={name.value}
                            onChange={this.handleNameChange}
                            type="text"
                            error={name.error}
                            label="Nombre de promoción"
                            required
                          />
                          {name.error ? (
                            <Error>{name.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Select
                            id="status"
                            label="Estado"
                            placeholder="Seleccione un estado"
                            required
                            options={STATUS_OPTIONS_SUPPLIERS}
                            value={status}
                            onChange={this.handleStatusChange}
                            backspaceRemovesValue={false}
                          />
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="order"
                            value={order.value}
                            error={order.error}
                            onChange={this.handleOrderChange}
                            type="number"
                            label="Prioridad"
                            required
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={8}>
                          <Input
                            id="description"
                            value={description.value}
                            error={description.error}
                            onChange={this.handleDescriptionChange}
                            type="text"
                            label="Descripción corta"
                            required
                          />
                          {description.error ? (
                            <Error>{description.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                        <Grid.Column md={4}>
                          <Input
                            id="city"
                            value={city.value}
                            error={city.error}
                            onChange={this.handleCityChange}
                            type="text"
                            label="Ciudad"
                            required
                          />
                          {city.error ? (
                            <Error>{city.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column md={12}>
                          <Input
                            id="url"
                            value={url.value}
                            error={url.error}
                            onChange={this.handleUrlChange}
                            type="text"
                            label="URL"
                            required
                          />
                          {url.error ? (
                            <Error>{url.errorText}</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Paper>
                  </FormSection>
                  <FormSection title={'Carga de imagen'}>
                    <Grid.Row>
                      <Grid.Column>
                        <Paper>
                          <AssetSelector
                            dropZoneSupport
                            colorButton="secondary"
                            textButton="Seleccionar archivos"
                            accept="image/gif,image/jpeg,image/png"
                            assets={assets}
                            onChange={this.handleAssetsChange}
                          />
                          {assetsError ? (
                            <Error>Debe seleccionar un archivo.</Error>
                          ) : (
                            <Spacer />
                          )}
                        </Paper>
                      </Grid.Column>
                    </Grid.Row>
                  </FormSection>

                  <Mutation
                    mutation={CREATE_PROMOTION_MUTATION}
                    onCompleted={() => {
                      onCreated()
                      notify &&
                        notify.onSetNotification &&
                        notify.onSetNotification({
                          type: 'ok',
                          message: 'Promoción creada correctamente',
                        })
                    }}
                    onError={() => {
                      openModal('ALERT', {
                        header: {
                          title: 'ALERTA',
                        },
                        description:
                          'UPS! algo salió mal vuelva a intentarlo mas tarde.',
                        type: 'fail',
                      })
                    }}
                  >
                    {(createPromotion, { loading, error }) => (
                      <Mutation mutation={UPLOAD_PROMOTION_FILE}>
                        {(uploadSupplierFile, {}) => (
                          <ActionWrapper>
                            <CancelButton color="secondary" to="promotions">
                              Cancelar
                            </CancelButton>
                            <SaveButton
                              color="primary"
                              disabled={loading}
                              onClick={async () => {
                                let nextError = this.scrollToError()
                                let assetsError = this.validateAssets()

                                if (!assetsError) {
                                  if (nextError) {
                                    const uploadedImages: AssetType[] = []
                                    const videos: AssetType[] = []

                                    try {
                                      for (const asset of assets) {
                                        if (asset.type === 'video') {
                                          videos.push({
                                            url: asset.url,
                                            type: 'video',
                                          })
                                        } else {
                                          const response = await uploadSupplierFile(
                                            {
                                              variables: { file: asset.file },
                                            }
                                          )

                                          if (response) {
                                            const {
                                              url,
                                            } = response.data.uploadSupplierFile

                                            uploadedImages.push({
                                              url,
                                              type: 'image',
                                            })
                                          }
                                        }
                                      }
                                    } catch (error) {
                                      console.log('error uploading file', error)
                                      return
                                    }

                                    createPromotion({
                                      variables: {
                                        input: {
                                          name: name.value,
                                          city: city.value,
                                          order: Number(order.value),
                                          status: status && status['value'],
                                          summary: description.value,
                                          url: url.value,
                                          assets: [
                                            ...uploadedImages,
                                            ...videos,
                                          ].map(asset => ({
                                            url: asset.url,
                                            type: asset.type,
                                          })),
                                        },
                                      },
                                    })
                                  }
                                }
                              }}
                            >
                              {loading
                                ? 'Creando promoción...'
                                : 'Crear promoción'}
                            </SaveButton>
                          </ActionWrapper>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                </React.Fragment>
              )
            }}
          </Modal>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Promotion
